import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep7Svg () {
    return <div> 
       <svg width="200px" height="145px" viewBox="0 0 1622 1189" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="nav-step7">
                <g id="brown_robot" >
                    <g id="Group">
                        <path d="M108.704358,560.768234 C126.786679,564.260469 147.876698,570.216184 153.991995,587.590209 C157.06346,596.315481 155.139809,606.61364 149.12654,613.642745 C131.964595,596.194326 108.4918,585.088469 84.1177581,582.886427 L108.704358,560.768234" id="Fill-185" fill="#0A0A08"></path>
                        <path d="M165.259702,990.089874 C170.375977,1003.4169 184.219888,1011.70644 198.221093,1014.42711 C212.224423,1017.12653 226.644367,1015.23481 240.841126,1013.87448 C270.930856,1011.02628 301.256526,1010.66494 331.407898,1012.79046 C338.47333,1013.27933 346.380493,1013.66192 351.730581,1009.02828 C355.16127,1006.0313 356.810721,1001.54644 358.228484,997.231632 C367.406744,969.308636 367.632056,959.818176 339.076995,946.807849 C323.160642,939.555565 311.761149,937.253623 294.552442,940.631079 C281.956247,943.103063 166.532926,993.40569 165.259702,990.089874" id="Fill-186" fill="#0A0A08"></path>
                        <path d="M710.924079,990.089874 C705.805679,1003.4169 691.963893,1011.70644 677.962688,1014.42711 C663.959358,1017.12653 649.539414,1015.23481 635.34053,1013.87448 C605.252926,1011.02628 574.92513,1010.66494 544.773758,1012.79046 C537.710451,1013.27933 529.803288,1013.66192 524.4532,1009.02828 C521.022512,1006.0313 519.370935,1001.54644 517.955298,997.231632 C508.777037,969.308636 508.551726,959.818176 537.106786,946.807849 C553.02314,939.555565 564.422633,937.253623 581.63134,940.631079 C594.227535,943.103063 709.64873,993.40569 710.924079,990.089874" id="Fill-187" fill="#0A0A08"></path>
                        <path d="M44.720107,582.488954 C24.3294047,593.429021 8.63623721,612.811665 2.18297209,635.034008 C0.516516279,640.768669 -0.561153488,646.803029 0.308209302,652.709858 C2.6165907,668.364335 17.340493,678.677372 30.750786,687.073188 C30.3129163,656.125573 41.3595628,625.14395 61.2741349,601.446494 C53.3287116,629.590544 50.0723209,659.052418 51.681386,688.252854 C58.3195767,686.131582 65.5019163,688.021172 72.1911209,689.976653 C78.8782,691.934259 85.981893,693.968385 92.7008558,692.11918 C99.4198186,690.2721 105.097247,682.834895 102.402009,676.409439 C89.1723907,675.642126 79.4563581,662.368234 77.9960837,649.198494 C76.5358093,636.030879 81.2460977,623.043933 85.8777395,610.630879 L44.720107,582.488954" id="Fill-181" fill="#0A0A08"></path>
                        <g transform="translate(13.753488, 0.000000)">
                            <path d="M314.929414,549.590109 C304.443921,580.040351 293.956302,610.488469 283.468684,640.936586 C282.11894,644.858176 280.74794,648.983816 281.438753,653.073322 C283.243372,663.747699 296.915112,667.43123 303.196205,676.2479 C360.270191,640.139515 467.811856,613.247397 518.560112,673.863063 C523.249144,679.46169 527.408907,685.672469 533.313772,689.976653 C548.864526,701.309941 565.361163,683.804134 581.064958,681.306644 C582.540112,644.71364 571.017335,608.505356 559.60934,574.171782 L314.929414,549.590109" id="Fill-188" fill="#0A0A08"></path>
                            <path d="M156.637367,208.513808 C126.451986,225.190661 97.5780884,244.233222 70.3642698,265.409808 C82.8180512,291.781172 95.2718326,318.154661 107.725614,344.526025 C138.848377,317.410728 175.327605,296.470075 214.434051,283.262075 L156.637367,208.513808" id="Fill-189" fill="#0A0A08"></path>
                            <path d="M725.842558,208.513808 C756.025814,225.190661 784.899712,244.233222 812.11353,265.409808 C799.659749,291.781172 787.205967,318.154661 774.752186,344.526025 C743.629423,317.410728 707.152321,296.470075 668.043749,283.262075 L725.842558,208.513808" id="Fill-190" fill="#0A0A08"></path>
                            <path d="M222.364595,225.896335 C198.152098,169.973824 184.941609,109.313523 183.710898,48.3875314 C217.356726,64.190795 255.485405,66.7222929 292.587428,69.0008536 C264.198163,127.909724 260.782353,198.370812 283.343274,259.74954 L222.364595,225.896335" id="Fill-178" fill="#0A0A08"></path>
                            <path d="M668.060753,225.896335 C692.273251,169.973824 705.48374,109.313523 706.714451,48.3875314 C673.068623,64.190795 634.939944,66.7222929 597.837921,69.0008536 C626.227186,127.909724 629.64087,198.370812 607.082074,259.74954 L668.060753,225.896335" id="Fill-179" fill="#0A0A08"></path>
                            <path d="M632.701707,536.175933 C651.94247,515.360686 666.34966,490.453808 678.797065,464.883766 C719.000312,382.283816 740.145595,290.248669 740.077577,198.16251 C740.075451,195.614008 739.956419,192.793439 738.215567,190.954862 C736.610753,189.262946 734.106819,188.910109 731.804814,188.655046 C541.580158,167.503967 349.306442,146.357138 159.366614,169.988703 C156.1166,170.390427 152.709293,170.870795 150.077823,172.84328 C144.740488,176.843515 144.889279,184.829105 145.56734,191.518126 C155.013423,284.715933 164.922884,379.737439 202.641326,465.287615 C213.109814,489.033958 225.8548,512.123515 243.686302,530.838745 C262.984456,551.097105 287.545549,565.486895 312.965377,576.881824 C383.985302,608.709406 469.331647,607.835816 544.135107,588.986678 C580.44854,579.836301 607.116084,563.856619 632.701707,536.175933" id="Fill-191" fill="#FF9F00"></path>
                            <path d="M597.34266,187.673054 C596.054558,191.953858 592.625995,195.218661 589.235693,198.156134 C546.724065,234.989322 489.050665,253.778946 432.973577,249.06241 C376.913493,244.330996 323.1958,216.146561 287.449898,172.692368 C293.488674,156.897607 301.104633,139.542711 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C379.925442,28.0250209 404.718223,10.1451213 432.956572,3.26905439 C459.959958,-3.29456067 483.283963,0.83958159 503.21554,11.866795 C528.949953,26.0844184 549.0452,51.7458577 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075 C594.294577,163.082879 595.763353,169.682628 596.996191,175.959297 C597.776279,179.821372 598.467093,183.883247 597.34266,187.673054" id="Fill-192" fill="#965E00"></path>
                            <path d="M140.176865,436.520787 C136.880088,505.190059 110.333702,572.537255 65.8877953,624.984536 C22.7958837,604.789941 6.25035814,552.100351 2.06508837,504.699063 C-4.65174884,428.618092 5.41713023,316.313958 54.7391209,253.47287 C84.9181256,215.022159 105.46187,273.493172 117.628698,303.828636 C134.450549,345.775833 142.344958,391.357674 140.176865,436.520787" id="Fill-193" fill="#FF9F00"></path>
                            <path d="M592.553726,156.262075 C575.759507,166.039481 555.936335,171.659364 536.712577,175.540569 C490.119833,184.899247 441.208079,187.037523 395.559093,173.855029 C363.239628,164.515481 332.716279,146.797121 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C374.211879,66.8710795 397.971628,72.7290209 420.188205,76.4125523 C451.000633,81.5265607 482.195665,84.4449038 513.426833,85.1888368 C530.329456,85.5884351 548.065307,85.0251715 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075" id="Fill-195" fill="#0A0A08"></path>
                            <path d="M390.8318,310.351866 C349.895228,326.42082 318.506767,365.940669 314.500047,409.737071 C310.4912,453.529222 335.396637,499.166326 375.40433,517.426695 C394.910791,526.328385 416.672493,528.834377 438.068595,530.230845 C460.9356,531.722962 484.391391,532.007782 506.1786,524.902159 C536.655186,514.963213 561.756177,490.322025 573.149293,460.358527 C584.540284,430.397155 582.293544,395.732 567.982005,367.048067 C540.559879,312.086293 444.923595,289.120017 390.8318,310.351866" id="Fill-196" fill="#965E00"></path>
                            <path d="M465.084735,418.30718 C464.162233,416.696033 464.38967,415.182661 464.700005,415.087013 C464.787153,416.128519 464.889181,417.119013 465.08686,417.899079 C465.08686,418.035113 465.084735,418.171146 465.084735,418.30718 L465.084735,418.30718 Z M463.698856,426.090845 C463.458665,425.54246 463.029298,424.947314 462.591428,424.377674 C462.846498,424.543464 463.031423,424.664619 463.071809,424.694377 C462.810363,424.505205 462.597805,424.318159 462.408628,424.13749 C462.187567,423.848418 461.97926,423.569975 461.828344,423.321289 C461.722065,423.14487 461.617912,422.970577 461.511633,422.796285 C461.390474,422.052351 462.570172,422.471079 463.717986,426.033456 C463.711609,426.052586 463.705233,426.071715 463.698856,426.090845 L463.698856,426.090845 Z M546.398851,381.875715 C526.452395,339.248351 461.490377,314.687933 417.760791,324.18477 C363.143977,336.047314 325.42766,397.264502 349.618902,449.898828 C354.588512,460.711364 362.559442,469.957389 371.724949,477.473238 C384.36153,487.837289 397.856847,498.783732 413.985758,503.134678 C436.91653,509.322075 466.76607,506.711933 489.435395,500.38 C537.726479,486.895682 567.525005,432.080569 547.506279,384.373205 C547.155558,383.535749 546.785707,382.702544 546.398851,381.875715 L546.398851,381.875715 Z" id="Fill-197" fill="#0A0A08"></path>
                            <path d="M324.505158,662.774209 C341.535316,697.269322 342.058209,737.363063 340.699963,775.809523 C338.414963,840.450929 331.798028,904.935046 320.904423,968.692234 C291.265316,969.204485 271.193451,939.695849 256.888288,913.73046 C226.855949,859.215046 196.526028,803.966326 181.045419,743.675866 C168.476856,694.733573 173.37207,639.512485 224.400902,614.690628 C263.815558,595.514159 306.588633,626.483029 324.505158,662.774209" id="Fill-198" fill="#965E00"></path>
                            <path d="M514.882856,662.774209 C497.852698,697.269322 497.329805,737.363063 498.688051,775.809523 C500.973051,840.450929 507.589986,904.935046 518.483591,968.692234 C548.122698,969.204485 568.194563,939.695849 582.499726,913.73046 C612.532065,859.215046 642.861986,803.966326 658.342595,743.675866 C670.909033,694.733573 666.015944,639.512485 614.989237,614.690628 C575.57033,595.514159 532.799381,626.483029 514.882856,662.774209" id="Fill-199" fill="#965E00"></path>
                            <path d="M627.625819,716.290628 C626.180423,712.50082 622.194958,710.392301 618.383791,712.532703 C608.431819,718.124954 604.350702,732.168285 600.035772,742.111481 C594.592158,754.654192 589.673563,767.315933 584.85487,780.113707 C575.381153,805.271397 566.379316,830.597004 557.311586,855.903481 C555.40494,861.22154 558.49766,868.969071 563.641567,871.460184 C564.689479,871.968184 565.80966,872.531448 566.931967,872.850276 C574.50754,874.984301 581.356163,870.204 583.877102,863.225908 C592.808795,838.518828 601.823386,813.845757 610.357595,788.998393 C614.623637,776.57046 618.832288,764.197791 622.475535,751.572184 C625.468353,741.210259 631.675051,726.918243 627.625819,716.290628" id="Fill-200" fill="#FF9F00"></path>
                            <path d="M285.893972,866.624619 C281.166679,843.445791 271.652577,820.392368 262.567842,798.629138 C253.580884,777.106092 244.194316,753.927264 228.794479,736.136636 C222.03513,728.32959 210.125498,736.740285 212.310595,745.733372 C217.605419,767.502979 228.696702,788.275715 236.616619,809.231247 C244.70233,830.626762 250.498791,852.791715 258.350688,874.216987 C264.650912,891.412469 289.59886,884.791464 285.893972,866.624619" id="Fill-201" fill="#FF9F00"></path>
                            <path d="M293.246358,753.62969 C272.239237,753.62969 272.205228,786.273473 293.246358,786.273473 C314.251353,786.273473 314.285363,753.62969 293.246358,753.62969" id="Fill-202" fill="#FF9F00"></path>
                            <path d="M551.672419,729.913105 C528.150735,729.913105 528.112474,766.467849 551.672419,766.467849 C575.196228,766.467849 575.234488,729.913105 551.672419,729.913105" id="Fill-203" fill="#FF9F00"></path>
                            <path d="M48.0881767,337.917774 C40.3128,365.868402 36.8055907,394.747883 33.7234977,423.542343 C32.2270884,437.515531 30.8539628,451.53123 30.0823767,465.566059 C29.4149442,477.745305 25.4932465,496.806996 34.6077395,506.301707 C37.6600744,509.48149 42.8146093,509.22005 45.9094558,506.301707 C54.6689767,498.041925 52.3457163,480.26405 52.9727628,469.071046 C53.7911116,454.472954 54.4755488,439.872736 55.5893535,425.291649 C57.7595721,396.811766 61.7216558,368.506176 63.4497535,339.996536 C63.9939023,331.058711 50.4114372,329.564469 48.0881767,337.917774" id="Fill-206" fill="#383937"></path>
                            <path d="M60.8969302,546.729155 C60.4845674,545.264669 59.8894047,543.90646 59.1326977,542.641774 C58.4525116,540.871213 57.4173535,539.308954 55.9847116,537.978377 C55.9273209,537.927364 55.876307,537.8721 55.8274186,537.814711 C54.7433721,536.977255 53.6593256,536.139799 52.5774047,535.302343 C50.7324,534.126929 48.7407302,533.493523 46.6023953,533.395749 C45.1782558,533.013155 43.7541163,533.013155 42.3299767,533.395749 C40.1916419,533.493523 38.2020977,534.126929 36.357093,535.302343 C36.2805721,535.338477 36.2061767,535.372485 36.133907,535.417121 C34.2251349,536.445874 32.6925907,537.850845 31.5384,539.634159 C30.5223721,540.658661 29.8188047,541.861707 29.4298233,543.249674 C28.3521535,545.806678 28.6476093,544.777925 28.2713814,547.575113 C28.0800791,548.999213 27.948293,549.194762 27.9971814,550.140619 C28.1651023,553.437305 28.8644186,555.868904 30.3331953,558.808502 C30.4054651,558.950912 30.4777349,559.086946 30.5606326,559.222979 C31.1260372,560.160335 32.5714326,561.992536 33.3302651,562.776854 C34.8245488,564.383749 36.5951581,565.520904 38.6378419,566.186192 C40.6040047,567.219197 42.7210837,567.691063 44.9933302,567.606042 C47.2634512,567.691063 49.3826558,567.219197 51.346693,566.186192 C53.3915023,565.520904 55.159986,564.383749 56.6542698,562.776854 C59.5386837,559.631079 61.6812698,555.507565 61.4857163,551.114109 C61.2880372,549.651749 61.0924837,548.191515 60.8969302,546.729155" id="Fill-207" fill="#383937"></path>
                            <path d="M556.2658,121.841356 C552.40787,110.514444 547.176814,101.143013 539.788293,91.8332218 C537.178079,88.5471632 532.223349,92.1563013 533.126721,95.7123013 C535.86447,106.486577 539.023084,116.971782 542.22846,127.635531 C545.174516,137.432067 546.352088,148.361506 551.889228,157.09528 C554.108335,160.593891 559.919674,160.204921 560.995219,155.904987 C563.803112,144.669473 559.896293,132.505105 556.2658,121.841356" id="Fill-208" fill="#515251"></path>
                            <path d="M498.906986,362.129607 C493.96926,358.027347 484.703851,363.540954 489.203707,369.615699 C498.018493,381.514377 500.558563,399.366644 497.538112,413.762812 C496.07146,420.760033 493.216805,427.383163 489.337619,433.379264 C484.859019,440.302092 477.026251,444.440485 474.813521,452.67051 C472.847358,459.988686 478.335609,465.776485 485.28626,466.418393 C496.456191,467.447146 505.445274,455.911933 511.014298,447.543749 C517.080707,438.427381 520.058647,427.765757 521.208586,416.9681 C523.531847,395.155983 515.682074,376.066661 498.906986,362.129607" id="Fill-209" fill="#515251"></path>
                            <path d="M441.473777,350.330828 C441.76073,351.032251 442.049809,351.731548 442.336763,352.432971 C446.086288,361.540837 457.220084,361.557841 460.975986,352.432971 C461.265065,351.731548 461.552019,351.032251 461.841098,350.330828 C468.781121,333.466929 434.48274,333.3479 441.473777,350.330828" id="Fill-210" fill="#515251"></path>
                            <g id="face" transform="translate(358.223256, 71.142259)">
                                <g id="Group-+-Group" transform="translate(91.400000, 58.514644) rotate(-5.000000) translate(-91.400000, -58.514644) translate(4.251163, 7.502092)">
                                    <g id="Group" transform="translate(-0.000000, 0.000000)">
                                        <ellipse id="Oval-1" fill="#FFFFFF" cx="44.4208711" cy="43.3125444" rx="44.4208711" ry="43.3125444"></ellipse>
                                        <ellipse id="Oval-2" fill="#120AF8" cx="44.4208711" cy="43.3125444" rx="27.9686966" ry="29.4525302"></ellipse>
                                        <ellipse id="Oval-3" fill="#000000" cx="42.7756536" cy="41.5800426" rx="13.1617396" ry="13.8600142"></ellipse>
                                        <ellipse id="Oval-4" fill="#FFFFFF" cx="65.2627839" cy="29.7180576" rx="10.313076" ry="10.8602195"></ellipse>
                                        <ellipse id="Oval-5" fill="#FFFFFF" cx="42.7756536" cy="41.5800426" rx="3.29043489" ry="3.46500355"></ellipse>
                                    </g>
                                    <g id="Group" transform="translate(89.085478, 19.250020)">
                                        <ellipse id="Oval-1" fill="#FFFFFF" cx="42.2820884" cy="41.2271256" rx="42.2820884" ry="41.2271256"></ellipse>
                                        <ellipse id="Oval-2" fill="#120AF8" cx="42.2820884" cy="41.2271256" rx="26.6220556" ry="28.0344454"></ellipse>
                                        <ellipse id="Oval-3" fill="#000000" cx="40.7160851" cy="39.5780406" rx="12.5280262" ry="13.1926802"></ellipse>
                                        <ellipse id="Oval-4" fill="#FFFFFF" cx="57.9421211" cy="28.0344454" rx="7.83001637" ry="8.24542512"></ellipse>
                                        <ellipse id="Oval-5" fill="#FFFFFF" cx="40.7160851" cy="39.5780406" rx="3.13200655" ry="3.29817005"></ellipse>
                                    </g>
                                </g>
                                <path d="M144.169337,115.596775 C147.981869,109.702118 138.315165,104.432758 131.866167,110.828879 C119.234682,123.357758 97.2306173,133.291199 78.0384831,137.09659 C68.7106023,138.947378 59.4625247,139.482698 50.7201212,138.893199 C40.6265727,138.2127 33.1200468,133.528963 22.0094275,135.301261 C12.1302162,136.878364 6.32890283,143.968652 7.49712636,150.016227 C9.37808436,159.73365 26.6326134,162.133969 38.8778883,163.085441 C52.2178395,164.121542 66.6429777,161.918067 80.7191329,158.13847 C109.154158,150.503631 131.216495,135.623018 144.169337,115.596775" id="Fill-209" fill="#000000" transform="translate(76.190713, 135.625681) scale(-1, 1) translate(-76.190713, -135.625681) "></path>
                            </g>
                        </g>
                        <g transform="translate(952.430587, 339.100367) rotate(-74.000000) translate(-952.430587, -339.100367) translate(866.430587, 112.600367)">
                            <path d="M127.095944,342.488954 C147.488772,353.429021 163.179814,372.811665 169.635205,395.034008 C171.299535,400.768669 172.37933,406.803029 171.507842,412.709858 C169.19946,428.364335 154.477684,438.677372 141.067391,447.073188 C141.503135,416.125573 130.458614,385.14395 110.541916,361.446494 C118.48734,389.590544 121.74373,419.052418 120.134665,448.252854 C113.496474,446.131582 106.314135,448.021172 99.6249302,449.976653 C92.9378512,451.934259 85.8341581,453.968385 79.1173209,452.11918 C72.3962326,450.2721 66.7209302,442.834895 69.4161674,436.409439 C82.6436605,435.642126 92.359693,422.368234 93.8199674,409.198494 C95.2802419,396.030879 90.5720791,383.043933 85.9404372,370.630879 L127.095944,342.488954" id="Fill-183" fill="#0A0A08"></path>
                            <path d="M47.116693,320.768234 C29.0322465,324.260469 7.94435349,330.216184 1.82693023,347.590209 C-1.24453488,356.315481 0.679116279,366.61364 6.69451163,373.642745 C23.8543302,356.194326 47.3271256,345.088469 71.7011674,342.886427 L47.116693,320.768234" id="Fill-184" fill="#0A0A08"></path>
                            <path d="M89.343493,384.984536 C132.435405,364.789941 148.98093,312.100351 153.1662,264.699063 C159.885163,188.618092 149.816284,76.3139582 100.492167,13.4728703 C70.3110372,-24.977841 49.7694186,33.4931715 37.6047163,63.828636 C20.7828651,105.775833 12.8863302,151.357674 15.0544233,196.520787 C18.3512,265.190059 44.897586,332.537255 89.343493,384.984536 Z" id="Fill-194" fill="#FF9F00"></path>
                            <path d="M127.748498,233.57928 C126.898265,220.968552 125.986391,208.364201 125.044758,195.759849 C123.144488,170.344971 121.089051,145.197908 114.878102,120.450444 C113.207395,113.797556 104.345847,115.940084 103.689042,121.963816 C100.942791,147.187397 102.045967,172.234561 102.976972,197.562293 C103.419093,209.565121 103.814451,221.572201 104.188553,233.57928 C104.543526,244.914695 102.8048,260.881623 109.993516,270.37846 C113.900335,275.541356 121.203833,276.329925 124.968237,270.37846 C131.491647,260.067548 128.530712,245.165506 127.748498,233.57928" id="Fill-204" fill="#383937"></path>
                            <path d="M123.248642,310.54872 C123.244391,309.337172 122.940433,308.206393 122.332516,307.15851 C121.790493,305.494226 120.865865,304.053121 119.554381,302.837322 C117.839037,301.26231 115.843116,300.099649 113.62826,299.383347 C110.250712,298.288703 106.312009,299.143163 103.276679,300.737305 C100.273233,302.314444 97.452586,304.486728 96.0943395,307.700519 C95.6373395,308.78241 95.1824651,309.864301 94.7233395,310.946192 C94.1579349,312.752887 94.0771628,314.561707 94.4831488,316.366276 C94.4852744,317.592703 94.7956093,318.736234 95.4099023,319.79687 C95.9583023,321.480285 96.8935581,322.940519 98.2199209,324.171197 C99.9565209,325.765339 101.975823,326.940753 104.218312,327.667682 C106.025056,328.233071 107.8318,328.313841 109.638544,327.909992 C111.447414,327.827096 113.130874,327.289339 114.691051,326.29672 C117.868795,324.951264 120.087902,322.096686 121.656581,319.112452 C122.107205,318.043314 122.557828,316.974176 123.008451,315.905038 C123.569605,314.119598 123.650377,312.334159 123.248642,310.54872" id="Fill-205" fill="#383937"></path>
                        </g>
                    </g>
                </g>
                <g id="Group" transform="translate(597.000000, 367.000000)">
                    <g id="Group-+-Group-+-Group">
                        <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)">
                            <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                        </g>
                        <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)">
                            <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                        </g>
                        <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                            <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    </div>
}
