import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep1Svg () {
    return <div>
        <svg width="200px" height="142px" viewBox="0 0 1595 1144" version="1.1" xmlns="http://www.w3.org/2000/svg">
            
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="2">
                    <g id="1flower" transform="translate(600.000000, 340.000000)" stroke="#090909" strokeWidth="4">
                        <g id="Group" transform="translate(392.864200, 496.947767) rotate(-302.000000) translate(-392.864200, -496.947767) translate(30.864200, 260.447767)" fill="#807C7C">
                            <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                            <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                            <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                            <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                            <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                            <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                            <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                            <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                            <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                        </g>
                        <g id="Group" transform="translate(792.232769, 494.379537) scale(-1, 1) rotate(-303.000000) translate(-792.232769, -494.379537) translate(422.732769, 265.879537)" fill="#807C7C">
                            <path d="M112.480405,155.612439 C116.887244,152.880065 124.019088,152.87197 128.438982,155.612439 L237.009448,222.929426 C241.416288,225.6618 241.429343,230.08376 237.009448,232.824228 L128.438982,300.141215 C124.032142,302.87359 116.900299,302.881684 112.480405,300.141215 L3.909938,232.824228 C-0.496901376,230.091854 -0.509956175,225.669895 3.909938,222.929426 L112.480405,155.612439 L112.480405,155.612439 Z" id="Rectangle-1"></path>
                            <path d="M237.713891,233.261004 C242.12073,230.52863 249.252574,230.520536 253.672468,233.261004 L362.242935,300.577991 C366.649774,303.310365 366.662829,307.732325 362.242935,310.472794 L253.672468,377.789781 C249.265629,380.522155 242.133785,380.530249 237.713891,377.789781 L129.143424,310.472794 C124.736585,307.740419 124.72353,303.31846 129.143424,300.577991 L237.713891,233.261004 L237.713891,233.261004 Z" id="Rectangle-1"></path>
                            <path d="M361.695797,310.133552 C366.102637,307.401178 373.23448,307.393083 377.654375,310.133552 L486.224841,377.450539 C490.631681,380.182913 490.644735,384.604873 486.224841,387.345341 L377.654375,454.662328 C373.247535,457.394703 366.115692,457.402797 361.695797,454.662328 L253.125331,387.345341 C248.718491,384.612967 248.705437,380.191008 253.125331,377.450539 L361.695797,310.133552 L361.695797,310.133552 Z" id="Rectangle-1"></path>
                            <path d="M237.556586,78.3031153 C241.963425,75.5707411 249.095269,75.5626467 253.515163,78.3031153 L362.085629,145.620102 C366.492469,148.352477 366.505524,152.774436 362.085629,155.514905 L253.515163,222.831892 C249.108323,225.564266 241.97648,225.57236 237.556586,222.831892 L128.986119,155.514905 C124.57928,152.782531 124.566225,148.360571 128.986119,145.620102 L237.556586,78.3031153 L237.556586,78.3031153 Z" id="Rectangle-1"></path>
                            <path d="M362.947377,156.049215 C367.354217,153.316841 374.48606,153.308746 378.905954,156.049215 L487.476421,223.366202 C491.88326,226.098576 491.896315,230.520536 487.476421,233.261004 L378.905954,300.577991 C374.499115,303.310365 367.367272,303.31846 362.947377,300.577991 L254.376911,233.261004 C249.970071,230.52863 249.957017,226.10667 254.376911,223.366202 L362.947377,156.049215 L362.947377,156.049215 Z" id="Rectangle-1"></path>
                            <path d="M486.929284,232.921763 C491.336123,230.189388 498.467967,230.181294 502.887861,232.921763 L611.458328,300.238749 C615.865167,302.971124 615.878222,307.393083 611.458328,310.133552 L502.887861,377.450539 C498.481022,380.182913 491.349178,380.191008 486.929284,377.450539 L378.358817,310.133552 C373.951978,307.401178 373.938923,302.979218 378.358817,300.238749 L486.929284,232.921763 L486.929284,232.921763 Z" id="Rectangle-1"></path>
                            <path d="M363.265057,2.05231721 C367.671896,-0.680057055 374.80374,-0.688151427 379.223634,2.05231721 L487.7941,69.3693042 C492.20094,72.1016784 492.213995,76.5236381 487.7941,79.2641067 L379.223634,146.581094 C374.816794,149.313468 367.684951,149.321562 363.265057,146.581094 L254.69459,79.2641067 C250.287751,76.5317324 250.274696,72.1097728 254.69459,69.3693042 L363.265057,2.05231721 L363.265057,2.05231721 Z" id="Rectangle-1"></path>
                            <path d="M486.638627,77.8055662 C491.045467,75.0731919 498.17731,75.0650976 502.597204,77.8055662 L611.167671,145.122553 C615.57451,147.854927 615.587565,152.276887 611.167671,155.017356 L502.597204,222.334343 C498.190365,225.066717 491.058521,225.074811 486.638627,222.334343 L378.068161,155.017356 C373.661321,152.284981 373.648266,147.863022 378.068161,145.122553 L486.638627,77.8055662 L486.638627,77.8055662 Z" id="Rectangle-1"></path>
                            <path d="M610.620534,154.678114 C615.027373,151.94574 622.159217,151.937645 626.579111,154.678114 L735.149577,221.995101 C739.556417,224.727475 739.569472,229.149435 735.149577,231.889903 L626.579111,299.20689 C622.172271,301.939265 615.040428,301.947359 610.620534,299.20689 L502.050067,231.889903 C497.643228,229.157529 497.630173,224.73557 502.050067,221.995101 L610.620534,154.678114 L610.620534,154.678114 Z" id="Rectangle-1"></path>
                        </g>
                        <g id="Group" transform="translate(201.007864, 0.000000)">
                            <path d="M120.373634,126.037472 C125.08972,123.824399 132.722036,123.817843 137.452093,126.037472 L253.641412,180.560509 C258.357498,182.773581 258.371469,186.355124 253.641412,188.574752 L137.452093,243.097789 C132.736007,245.310861 125.103691,245.317417 120.373634,243.097789 L4.18431503,188.574752 C-0.531771064,186.36168 -0.545741974,182.780137 4.18431503,180.560509 L120.373634,126.037472 L120.373634,126.037472 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M271.473747,188.928516 L387.663066,243.451554 C392.379152,245.664626 392.393123,249.246168 387.663066,251.465797 L271.473747,305.988834 C266.757661,308.201906 259.125345,308.208462 254.395288,305.988834 L138.205969,251.465797 C133.489883,249.252724 133.475912,245.671182 138.205969,243.451554 L254.395288,188.928516 C259.111374,186.715444 266.74369,186.708888 271.473747,188.928516 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                            <path d="M387.077534,251.19103 C391.79362,248.977957 399.425935,248.971401 404.155992,251.19103 L520.345312,305.714067 C525.061398,307.927139 525.075369,311.508682 520.345312,313.72831 L404.155992,368.251347 C399.439906,370.464419 391.807591,370.470975 387.077534,368.251347 L270.888215,313.72831 C266.172129,311.515238 266.158158,307.933695 270.888215,305.714067 L387.077534,251.19103 L387.077534,251.19103 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M254.226944,63.421194 C258.94303,61.2081219 266.575346,61.2015659 271.305403,63.421194 L387.494722,117.944231 C392.210808,120.157303 392.224779,123.738846 387.494722,125.958474 L271.305403,180.481512 C266.589317,182.694584 258.957001,182.70114 254.226944,180.481512 L138.037625,125.958474 C133.321539,123.745402 133.307568,120.163859 138.037625,117.944231 L254.226944,63.421194 L254.226944,63.421194 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                            <path d="M388.416942,126.391236 C393.133029,124.178164 400.765344,124.171608 405.495401,126.391236 L521.68472,180.914273 C526.400806,183.127345 526.414777,186.708888 521.68472,188.928516 L405.495401,243.451554 C400.779315,245.664626 393.146999,245.671182 388.416942,243.451554 L272.227623,188.928516 C267.511537,186.715444 267.497566,183.133901 272.227623,180.914273 L388.416942,126.391236 L388.416942,126.391236 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M538.177646,188.653749 L654.366966,243.176787 C659.083052,245.389859 659.097023,248.971401 654.366966,251.19103 L538.177646,305.714067 C533.46156,307.927139 525.829245,307.933695 521.099188,305.714067 L404.909869,251.19103 C400.193783,248.977957 400.179812,245.396415 404.909869,243.176787 L521.099188,188.653749 C525.815274,186.440677 533.447589,186.434121 538.177646,188.653749 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                            <path d="M388.756915,1.66226347 C393.473001,-0.550808613 401.105316,-0.557364607 405.835373,1.66226347 L522.024692,56.1853009 C526.740779,58.398373 526.754749,61.9799158 522.024692,64.1995438 L405.835373,118.722581 C401.119287,120.935653 393.486972,120.942209 388.756915,118.722581 L272.567595,64.1995438 C267.851509,61.9864718 267.837538,58.404929 272.567595,56.1853009 L388.756915,1.66226347 L388.756915,1.66226347 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M520.788135,63.0182067 C525.504221,60.8051346 533.136536,60.7985786 537.866593,63.0182067 L654.055912,117.541244 C658.771999,119.754316 658.785969,123.335859 654.055912,125.555487 L537.866593,180.078524 C533.150507,182.291597 525.518192,182.298153 520.788135,180.078524 L404.598815,125.555487 C399.882729,123.342415 399.868758,119.760872 404.598815,117.541244 L520.788135,63.0182067 L520.788135,63.0182067 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                            <path d="M653.47038,125.28072 C658.186466,123.067648 665.818782,123.061092 670.548839,125.28072 L786.738158,179.803757 C791.454244,182.016829 791.468215,185.598372 786.738158,187.818 L670.548839,242.341038 C665.832753,244.55411 658.200437,244.560666 653.47038,242.341038 L537.281061,187.818 C532.564975,185.604928 532.551004,182.023385 537.281061,179.803757 L653.47038,125.28072 L653.47038,125.28072 Z" id="Rectangle-1" fill="#807C7C"></path>
                        </g>
                    </g>
                    <g id="brown_robot">
                        <g id="Group">
                            <path d="M108.704358,560.768234 C126.786679,564.260469 147.876698,570.216184 153.991995,587.590209 C157.06346,596.315481 155.139809,606.61364 149.12654,613.642745 C131.964595,596.194326 108.4918,585.088469 84.1177581,582.886427 L108.704358,560.768234" id="Fill-185" fill="#0A0A08"></path>
                            <path d="M165.259702,990.089874 C170.375977,1003.4169 184.219888,1011.70644 198.221093,1014.42711 C212.224423,1017.12653 226.644367,1015.23481 240.841126,1013.87448 C270.930856,1011.02628 301.256526,1010.66494 331.407898,1012.79046 C338.47333,1013.27933 346.380493,1013.66192 351.730581,1009.02828 C355.16127,1006.0313 356.810721,1001.54644 358.228484,997.231632 C367.406744,969.308636 367.632056,959.818176 339.076995,946.807849 C323.160642,939.555565 311.761149,937.253623 294.552442,940.631079 C281.956247,943.103063 166.532926,993.40569 165.259702,990.089874" id="Fill-186" fill="#0A0A08"></path>
                            <path d="M710.924079,990.089874 C705.805679,1003.4169 691.963893,1011.70644 677.962688,1014.42711 C663.959358,1017.12653 649.539414,1015.23481 635.34053,1013.87448 C605.252926,1011.02628 574.92513,1010.66494 544.773758,1012.79046 C537.710451,1013.27933 529.803288,1013.66192 524.4532,1009.02828 C521.022512,1006.0313 519.370935,1001.54644 517.955298,997.231632 C508.777037,969.308636 508.551726,959.818176 537.106786,946.807849 C553.02314,939.555565 564.422633,937.253623 581.63134,940.631079 C594.227535,943.103063 709.64873,993.40569 710.924079,990.089874" id="Fill-187" fill="#0A0A08"></path>
                            <path d="M44.720107,582.488954 C24.3294047,593.429021 8.63623721,612.811665 2.18297209,635.034008 C0.516516279,640.768669 -0.561153488,646.803029 0.308209302,652.709858 C2.6165907,668.364335 17.340493,678.677372 30.750786,687.073188 C30.3129163,656.125573 41.3595628,625.14395 61.2741349,601.446494 C53.3287116,629.590544 50.0723209,659.052418 51.681386,688.252854 C58.3195767,686.131582 65.5019163,688.021172 72.1911209,689.976653 C78.8782,691.934259 85.981893,693.968385 92.7008558,692.11918 C99.4198186,690.2721 105.097247,682.834895 102.402009,676.409439 C89.1723907,675.642126 79.4563581,662.368234 77.9960837,649.198494 C76.5358093,636.030879 81.2460977,623.043933 85.8777395,610.630879 L44.720107,582.488954" id="Fill-181" fill="#0A0A08"></path>
                            <g transform="translate(13.753488, 0.000000)">
                                <path d="M314.929414,549.590109 C304.443921,580.040351 293.956302,610.488469 283.468684,640.936586 C282.11894,644.858176 280.74794,648.983816 281.438753,653.073322 C283.243372,663.747699 296.915112,667.43123 303.196205,676.2479 C360.270191,640.139515 467.811856,613.247397 518.560112,673.863063 C523.249144,679.46169 527.408907,685.672469 533.313772,689.976653 C548.864526,701.309941 565.361163,683.804134 581.064958,681.306644 C582.540112,644.71364 571.017335,608.505356 559.60934,574.171782 L314.929414,549.590109" id="Fill-188" fill="#0A0A08"></path>
                                <path d="M156.637367,208.513808 C126.451986,225.190661 97.5780884,244.233222 70.3642698,265.409808 C82.8180512,291.781172 95.2718326,318.154661 107.725614,344.526025 C138.848377,317.410728 175.327605,296.470075 214.434051,283.262075 L156.637367,208.513808" id="Fill-189" fill="#0A0A08"></path>
                                <path d="M725.842558,208.513808 C756.025814,225.190661 784.899712,244.233222 812.11353,265.409808 C799.659749,291.781172 787.205967,318.154661 774.752186,344.526025 C743.629423,317.410728 707.152321,296.470075 668.043749,283.262075 L725.842558,208.513808" id="Fill-190" fill="#0A0A08"></path>
                                <path d="M222.364595,225.896335 C198.152098,169.973824 184.941609,109.313523 183.710898,48.3875314 C217.356726,64.190795 255.485405,66.7222929 292.587428,69.0008536 C264.198163,127.909724 260.782353,198.370812 283.343274,259.74954 L222.364595,225.896335" id="Fill-178" fill="#0A0A08"></path>
                                <path d="M668.060753,225.896335 C692.273251,169.973824 705.48374,109.313523 706.714451,48.3875314 C673.068623,64.190795 634.939944,66.7222929 597.837921,69.0008536 C626.227186,127.909724 629.64087,198.370812 607.082074,259.74954 L668.060753,225.896335" id="Fill-179" fill="#0A0A08"></path>
                                <path d="M632.701707,536.175933 C651.94247,515.360686 666.34966,490.453808 678.797065,464.883766 C719.000312,382.283816 740.145595,290.248669 740.077577,198.16251 C740.075451,195.614008 739.956419,192.793439 738.215567,190.954862 C736.610753,189.262946 734.106819,188.910109 731.804814,188.655046 C541.580158,167.503967 349.306442,146.357138 159.366614,169.988703 C156.1166,170.390427 152.709293,170.870795 150.077823,172.84328 C144.740488,176.843515 144.889279,184.829105 145.56734,191.518126 C155.013423,284.715933 164.922884,379.737439 202.641326,465.287615 C213.109814,489.033958 225.8548,512.123515 243.686302,530.838745 C262.984456,551.097105 287.545549,565.486895 312.965377,576.881824 C383.985302,608.709406 469.331647,607.835816 544.135107,588.986678 C580.44854,579.836301 607.116084,563.856619 632.701707,536.175933" id="Fill-191" fill="#FF9F00"></path>
                                <path d="M597.34266,187.673054 C596.054558,191.953858 592.625995,195.218661 589.235693,198.156134 C546.724065,234.989322 489.050665,253.778946 432.973577,249.06241 C376.913493,244.330996 323.1958,216.146561 287.449898,172.692368 C293.488674,156.897607 301.104633,139.542711 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C379.925442,28.0250209 404.718223,10.1451213 432.956572,3.26905439 C459.959958,-3.29456067 483.283963,0.83958159 503.21554,11.866795 C528.949953,26.0844184 549.0452,51.7458577 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075 C594.294577,163.082879 595.763353,169.682628 596.996191,175.959297 C597.776279,179.821372 598.467093,183.883247 597.34266,187.673054" id="Fill-192" fill="#965E00"></path>
                                <path d="M140.176865,436.520787 C136.880088,505.190059 110.333702,572.537255 65.8877953,624.984536 C22.7958837,604.789941 6.25035814,552.100351 2.06508837,504.699063 C-4.65174884,428.618092 5.41713023,316.313958 54.7391209,253.47287 C84.9181256,215.022159 105.46187,273.493172 117.628698,303.828636 C134.450549,345.775833 142.344958,391.357674 140.176865,436.520787" id="Fill-193" fill="#FF9F00"></path>
                                <path d="M592.553726,156.262075 C575.759507,166.039481 555.936335,171.659364 536.712577,175.540569 C490.119833,184.899247 441.208079,187.037523 395.559093,173.855029 C363.239628,164.515481 332.716279,146.797121 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C374.211879,66.8710795 397.971628,72.7290209 420.188205,76.4125523 C451.000633,81.5265607 482.195665,84.4449038 513.426833,85.1888368 C530.329456,85.5884351 548.065307,85.0251715 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075" id="Fill-195" fill="#0A0A08"></path>
                                <path d="M390.8318,310.351866 C349.895228,326.42082 318.506767,365.940669 314.500047,409.737071 C310.4912,453.529222 335.396637,499.166326 375.40433,517.426695 C394.910791,526.328385 416.672493,528.834377 438.068595,530.230845 C460.9356,531.722962 484.391391,532.007782 506.1786,524.902159 C536.655186,514.963213 561.756177,490.322025 573.149293,460.358527 C584.540284,430.397155 582.293544,395.732 567.982005,367.048067 C540.559879,312.086293 444.923595,289.120017 390.8318,310.351866" id="Fill-196" fill="#965E00"></path>
                                <path d="M465.084735,418.30718 C464.162233,416.696033 464.38967,415.182661 464.700005,415.087013 C464.787153,416.128519 464.889181,417.119013 465.08686,417.899079 C465.08686,418.035113 465.084735,418.171146 465.084735,418.30718 L465.084735,418.30718 Z M463.698856,426.090845 C463.458665,425.54246 463.029298,424.947314 462.591428,424.377674 C462.846498,424.543464 463.031423,424.664619 463.071809,424.694377 C462.810363,424.505205 462.597805,424.318159 462.408628,424.13749 C462.187567,423.848418 461.97926,423.569975 461.828344,423.321289 C461.722065,423.14487 461.617912,422.970577 461.511633,422.796285 C461.390474,422.052351 462.570172,422.471079 463.717986,426.033456 C463.711609,426.052586 463.705233,426.071715 463.698856,426.090845 L463.698856,426.090845 Z M546.398851,381.875715 C526.452395,339.248351 461.490377,314.687933 417.760791,324.18477 C363.143977,336.047314 325.42766,397.264502 349.618902,449.898828 C354.588512,460.711364 362.559442,469.957389 371.724949,477.473238 C384.36153,487.837289 397.856847,498.783732 413.985758,503.134678 C436.91653,509.322075 466.76607,506.711933 489.435395,500.38 C537.726479,486.895682 567.525005,432.080569 547.506279,384.373205 C547.155558,383.535749 546.785707,382.702544 546.398851,381.875715 L546.398851,381.875715 Z" id="Fill-197" fill="#0A0A08"></path>
                                <path d="M324.505158,662.774209 C341.535316,697.269322 342.058209,737.363063 340.699963,775.809523 C338.414963,840.450929 331.798028,904.935046 320.904423,968.692234 C291.265316,969.204485 271.193451,939.695849 256.888288,913.73046 C226.855949,859.215046 196.526028,803.966326 181.045419,743.675866 C168.476856,694.733573 173.37207,639.512485 224.400902,614.690628 C263.815558,595.514159 306.588633,626.483029 324.505158,662.774209" id="Fill-198" fill="#965E00"></path>
                                <path d="M514.882856,662.774209 C497.852698,697.269322 497.329805,737.363063 498.688051,775.809523 C500.973051,840.450929 507.589986,904.935046 518.483591,968.692234 C548.122698,969.204485 568.194563,939.695849 582.499726,913.73046 C612.532065,859.215046 642.861986,803.966326 658.342595,743.675866 C670.909033,694.733573 666.015944,639.512485 614.989237,614.690628 C575.57033,595.514159 532.799381,626.483029 514.882856,662.774209" id="Fill-199" fill="#965E00"></path>
                                <path d="M627.625819,716.290628 C626.180423,712.50082 622.194958,710.392301 618.383791,712.532703 C608.431819,718.124954 604.350702,732.168285 600.035772,742.111481 C594.592158,754.654192 589.673563,767.315933 584.85487,780.113707 C575.381153,805.271397 566.379316,830.597004 557.311586,855.903481 C555.40494,861.22154 558.49766,868.969071 563.641567,871.460184 C564.689479,871.968184 565.80966,872.531448 566.931967,872.850276 C574.50754,874.984301 581.356163,870.204 583.877102,863.225908 C592.808795,838.518828 601.823386,813.845757 610.357595,788.998393 C614.623637,776.57046 618.832288,764.197791 622.475535,751.572184 C625.468353,741.210259 631.675051,726.918243 627.625819,716.290628" id="Fill-200" fill="#FF9F00"></path>
                                <path d="M285.893972,866.624619 C281.166679,843.445791 271.652577,820.392368 262.567842,798.629138 C253.580884,777.106092 244.194316,753.927264 228.794479,736.136636 C222.03513,728.32959 210.125498,736.740285 212.310595,745.733372 C217.605419,767.502979 228.696702,788.275715 236.616619,809.231247 C244.70233,830.626762 250.498791,852.791715 258.350688,874.216987 C264.650912,891.412469 289.59886,884.791464 285.893972,866.624619" id="Fill-201" fill="#FF9F00"></path>
                                <path d="M293.246358,753.62969 C272.239237,753.62969 272.205228,786.273473 293.246358,786.273473 C314.251353,786.273473 314.285363,753.62969 293.246358,753.62969" id="Fill-202" fill="#FF9F00"></path>
                                <path d="M551.672419,729.913105 C528.150735,729.913105 528.112474,766.467849 551.672419,766.467849 C575.196228,766.467849 575.234488,729.913105 551.672419,729.913105" id="Fill-203" fill="#FF9F00"></path>
                                <path d="M48.0881767,337.917774 C40.3128,365.868402 36.8055907,394.747883 33.7234977,423.542343 C32.2270884,437.515531 30.8539628,451.53123 30.0823767,465.566059 C29.4149442,477.745305 25.4932465,496.806996 34.6077395,506.301707 C37.6600744,509.48149 42.8146093,509.22005 45.9094558,506.301707 C54.6689767,498.041925 52.3457163,480.26405 52.9727628,469.071046 C53.7911116,454.472954 54.4755488,439.872736 55.5893535,425.291649 C57.7595721,396.811766 61.7216558,368.506176 63.4497535,339.996536 C63.9939023,331.058711 50.4114372,329.564469 48.0881767,337.917774" id="Fill-206" fill="#383937"></path>
                                <path d="M60.8969302,546.729155 C60.4845674,545.264669 59.8894047,543.90646 59.1326977,542.641774 C58.4525116,540.871213 57.4173535,539.308954 55.9847116,537.978377 C55.9273209,537.927364 55.876307,537.8721 55.8274186,537.814711 C54.7433721,536.977255 53.6593256,536.139799 52.5774047,535.302343 C50.7324,534.126929 48.7407302,533.493523 46.6023953,533.395749 C45.1782558,533.013155 43.7541163,533.013155 42.3299767,533.395749 C40.1916419,533.493523 38.2020977,534.126929 36.357093,535.302343 C36.2805721,535.338477 36.2061767,535.372485 36.133907,535.417121 C34.2251349,536.445874 32.6925907,537.850845 31.5384,539.634159 C30.5223721,540.658661 29.8188047,541.861707 29.4298233,543.249674 C28.3521535,545.806678 28.6476093,544.777925 28.2713814,547.575113 C28.0800791,548.999213 27.948293,549.194762 27.9971814,550.140619 C28.1651023,553.437305 28.8644186,555.868904 30.3331953,558.808502 C30.4054651,558.950912 30.4777349,559.086946 30.5606326,559.222979 C31.1260372,560.160335 32.5714326,561.992536 33.3302651,562.776854 C34.8245488,564.383749 36.5951581,565.520904 38.6378419,566.186192 C40.6040047,567.219197 42.7210837,567.691063 44.9933302,567.606042 C47.2634512,567.691063 49.3826558,567.219197 51.346693,566.186192 C53.3915023,565.520904 55.159986,564.383749 56.6542698,562.776854 C59.5386837,559.631079 61.6812698,555.507565 61.4857163,551.114109 C61.2880372,549.651749 61.0924837,548.191515 60.8969302,546.729155" id="Fill-207" fill="#383937"></path>
                                <path d="M556.2658,121.841356 C552.40787,110.514444 547.176814,101.143013 539.788293,91.8332218 C537.178079,88.5471632 532.223349,92.1563013 533.126721,95.7123013 C535.86447,106.486577 539.023084,116.971782 542.22846,127.635531 C545.174516,137.432067 546.352088,148.361506 551.889228,157.09528 C554.108335,160.593891 559.919674,160.204921 560.995219,155.904987 C563.803112,144.669473 559.896293,132.505105 556.2658,121.841356" id="Fill-208" fill="#515251"></path>
                                <path d="M498.906986,362.129607 C493.96926,358.027347 484.703851,363.540954 489.203707,369.615699 C498.018493,381.514377 500.558563,399.366644 497.538112,413.762812 C496.07146,420.760033 493.216805,427.383163 489.337619,433.379264 C484.859019,440.302092 477.026251,444.440485 474.813521,452.67051 C472.847358,459.988686 478.335609,465.776485 485.28626,466.418393 C496.456191,467.447146 505.445274,455.911933 511.014298,447.543749 C517.080707,438.427381 520.058647,427.765757 521.208586,416.9681 C523.531847,395.155983 515.682074,376.066661 498.906986,362.129607" id="Fill-209" fill="#515251"></path>
                                <path d="M441.473777,350.330828 C441.76073,351.032251 442.049809,351.731548 442.336763,352.432971 C446.086288,361.540837 457.220084,361.557841 460.975986,352.432971 C461.265065,351.731548 461.552019,351.032251 461.841098,350.330828 C468.781121,333.466929 434.48274,333.3479 441.473777,350.330828" id="Fill-210" fill="#515251"></path>
                                <g id="face" transform="translate(358.223256, 71.142259)">
                                    <g id="GroupGroup" transform="translate(91.400000, 58.514644) rotate(-5.000000) translate(-91.400000, -58.514644) translate(4.251163, 7.502092)">
                                        <g id="Group" transform="translate(-0.000000, 0.000000)">
                                            <ellipse id="Oval-1" fill="#FFFFFF" cx="44.4208711" cy="43.3125444" rx="44.4208711" ry="43.3125444"></ellipse>
                                            <ellipse id="Oval-2" fill="#120AF8" cx="44.4208711" cy="43.3125444" rx="27.9686966" ry="29.4525302"></ellipse>
                                            <ellipse id="Oval-3" fill="#000000" cx="42.7756536" cy="41.5800426" rx="13.1617396" ry="13.8600142"></ellipse>
                                            <ellipse id="Oval-4" fill="#FFFFFF" cx="65.2627839" cy="29.7180576" rx="10.313076" ry="10.8602195"></ellipse>
                                            <ellipse id="Oval-5" fill="#FFFFFF" cx="42.7756536" cy="41.5800426" rx="3.29043489" ry="3.46500355"></ellipse>
                                        </g>
                                        <g id="Group" transform="translate(89.085478, 19.250020)">
                                            <ellipse id="Oval-1" fill="#FFFFFF" cx="42.2820884" cy="41.2271256" rx="42.2820884" ry="41.2271256"></ellipse>
                                            <ellipse id="Oval-2" fill="#120AF8" cx="42.2820884" cy="41.2271256" rx="26.6220556" ry="28.0344454"></ellipse>
                                            <ellipse id="Oval-3" fill="#000000" cx="40.7160851" cy="39.5780406" rx="12.5280262" ry="13.1926802"></ellipse>
                                            <ellipse id="Oval-4" fill="#FFFFFF" cx="57.9421211" cy="28.0344454" rx="7.83001637" ry="8.24542512"></ellipse>
                                            <ellipse id="Oval-5" fill="#FFFFFF" cx="40.7160851" cy="39.5780406" rx="3.13200655" ry="3.29817005"></ellipse>
                                        </g>
                                    </g>
                                    <path d="M144.169337,115.596775 C147.981869,109.702118 138.315165,104.432758 131.866167,110.828879 C119.234682,123.357758 97.2306173,133.291199 78.0384831,137.09659 C68.7106023,138.947378 59.4625247,139.482698 50.7201212,138.893199 C40.6265727,138.2127 33.1200468,133.528963 22.0094275,135.301261 C12.1302162,136.878364 6.32890283,143.968652 7.49712636,150.016227 C9.37808436,159.73365 26.6326134,162.133969 38.8778883,163.085441 C52.2178395,164.121542 66.6429777,161.918067 80.7191329,158.13847 C109.154158,150.503631 131.216495,135.623018 144.169337,115.596775" id="Fill-209" fill="#000000" transform="translate(76.190713, 135.625681) scale(-1, 1) translate(-76.190713, -135.625681) "></path>
                                </g>
                            </g>
                            <g transform="translate(952.430587, 339.100367) rotate(-74.000000) translate(-952.430587, -339.100367) translate(866.430587, 112.600367)">
                                <path d="M127.095944,342.488954 C147.488772,353.429021 163.179814,372.811665 169.635205,395.034008 C171.299535,400.768669 172.37933,406.803029 171.507842,412.709858 C169.19946,428.364335 154.477684,438.677372 141.067391,447.073188 C141.503135,416.125573 130.458614,385.14395 110.541916,361.446494 C118.48734,389.590544 121.74373,419.052418 120.134665,448.252854 C113.496474,446.131582 106.314135,448.021172 99.6249302,449.976653 C92.9378512,451.934259 85.8341581,453.968385 79.1173209,452.11918 C72.3962326,450.2721 66.7209302,442.834895 69.4161674,436.409439 C82.6436605,435.642126 92.359693,422.368234 93.8199674,409.198494 C95.2802419,396.030879 90.5720791,383.043933 85.9404372,370.630879 L127.095944,342.488954" id="Fill-183" fill="#0A0A08"></path>
                                <path d="M47.116693,320.768234 C29.0322465,324.260469 7.94435349,330.216184 1.82693023,347.590209 C-1.24453488,356.315481 0.679116279,366.61364 6.69451163,373.642745 C23.8543302,356.194326 47.3271256,345.088469 71.7011674,342.886427 L47.116693,320.768234" id="Fill-184" fill="#0A0A08"></path>
                                <path d="M89.343493,384.984536 C132.435405,364.789941 148.98093,312.100351 153.1662,264.699063 C159.885163,188.618092 149.816284,76.3139582 100.492167,13.4728703 C70.3110372,-24.977841 49.7694186,33.4931715 37.6047163,63.828636 C20.7828651,105.775833 12.8863302,151.357674 15.0544233,196.520787 C18.3512,265.190059 44.897586,332.537255 89.343493,384.984536 Z" id="Fill-194" fill="#FF9F00"></path>
                                <path d="M127.748498,233.57928 C126.898265,220.968552 125.986391,208.364201 125.044758,195.759849 C123.144488,170.344971 121.089051,145.197908 114.878102,120.450444 C113.207395,113.797556 104.345847,115.940084 103.689042,121.963816 C100.942791,147.187397 102.045967,172.234561 102.976972,197.562293 C103.419093,209.565121 103.814451,221.572201 104.188553,233.57928 C104.543526,244.914695 102.8048,260.881623 109.993516,270.37846 C113.900335,275.541356 121.203833,276.329925 124.968237,270.37846 C131.491647,260.067548 128.530712,245.165506 127.748498,233.57928" id="Fill-204" fill="#383937"></path>
                                <path d="M123.248642,310.54872 C123.244391,309.337172 122.940433,308.206393 122.332516,307.15851 C121.790493,305.494226 120.865865,304.053121 119.554381,302.837322 C117.839037,301.26231 115.843116,300.099649 113.62826,299.383347 C110.250712,298.288703 106.312009,299.143163 103.276679,300.737305 C100.273233,302.314444 97.452586,304.486728 96.0943395,307.700519 C95.6373395,308.78241 95.1824651,309.864301 94.7233395,310.946192 C94.1579349,312.752887 94.0771628,314.561707 94.4831488,316.366276 C94.4852744,317.592703 94.7956093,318.736234 95.4099023,319.79687 C95.9583023,321.480285 96.8935581,322.940519 98.2199209,324.171197 C99.9565209,325.765339 101.975823,326.940753 104.218312,327.667682 C106.025056,328.233071 107.8318,328.313841 109.638544,327.909992 C111.447414,327.827096 113.130874,327.289339 114.691051,326.29672 C117.868795,324.951264 120.087902,322.096686 121.656581,319.112452 C122.107205,318.043314 122.557828,316.974176 123.008451,315.905038 C123.569605,314.119598 123.650377,312.334159 123.248642,310.54872" id="Fill-205" fill="#383937"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>


        {/* <svg width="200px" height="87.5px" viewBox="0 0 2518 1059" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1flower-+-1flower-+-brown_robot">
                    <g id="1flower-+-1flower" transform="translate(553.000000, 251.000000)">
                        <g>
                            <g>
                                <g id="1flower" transform="translate(968.000000, 0.000000)" stroke="#090909" strokeWidth="2">
                                    <g id="Group" transform="translate(205.007864, 0.000000)">
                                        <path d="M118.851844,126.037472 C123.508308,123.824399 131.044134,123.817843 135.714392,126.037472 L250.43482,180.560509 C255.091284,182.773581 255.105079,186.355124 250.43482,188.574752 L135.714392,243.097789 C131.057928,245.310861 123.522102,245.317417 118.851844,243.097789 L4.13141597,188.574752 C-0.525048295,186.36168 -0.538842581,182.780137 4.13141597,180.560509 L118.851844,126.037472 L118.851844,126.037472 Z" id="Rectangle-1" fill="#D8D8D8"></path>
                                        <path d="M268.041715,188.928516 L382.762142,243.451554 C387.418607,245.664626 387.432401,249.246168 382.762142,251.465797 L268.041715,305.988834 C263.38525,308.201906 255.849424,308.208462 251.179166,305.988834 L136.458738,251.465797 C131.802274,249.252724 131.78848,245.671182 136.458738,243.451554 L251.179166,188.928516 C255.83563,186.715444 263.371456,186.708888 268.041715,188.928516 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M382.184013,251.19103 C386.840477,248.977957 394.376303,248.971401 399.046561,251.19103 L513.766989,305.714067 C518.423453,307.927139 518.437248,311.508682 513.766989,313.72831 L399.046561,368.251347 C394.390097,370.464419 386.854271,370.470975 382.184013,368.251347 L267.463585,313.72831 C262.807121,311.515238 262.793326,307.933695 267.463585,305.714067 L382.184013,251.19103 L382.184013,251.19103 Z" id="Rectangle-1" fill="#D8D8D8"></path>
                                        <path d="M251.01295,63.421194 C255.669414,61.2081219 263.20524,61.2015659 267.875499,63.421194 L382.595926,117.944231 C387.252391,120.157303 387.266185,123.738846 382.595926,125.958474 L267.875499,180.481512 C263.219035,182.694584 255.683209,182.70114 251.01295,180.481512 L136.292522,125.958474 C131.636058,123.745402 131.622264,120.163859 136.292522,117.944231 L251.01295,63.421194 L251.01295,63.421194 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M383.506488,126.391236 C388.162952,124.178164 395.698778,124.171608 400.369037,126.391236 L515.089465,180.914273 C519.745929,183.127345 519.759723,186.708888 515.089465,188.928516 L400.369037,243.451554 C395.712573,245.664626 388.176747,245.671182 383.506488,243.451554 L268.78606,188.928516 C264.129596,186.715444 264.115802,183.133901 268.78606,180.914273 L383.506488,126.391236 L383.506488,126.391236 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                        <path d="M531.373884,188.653749 L646.094311,243.176787 C650.750776,245.389859 650.76457,248.971401 646.094311,251.19103 L531.373884,305.714067 C526.717419,307.927139 519.181593,307.933695 514.511335,305.714067 L399.790907,251.19103 C395.134443,248.977957 395.120649,245.396415 399.790907,243.176787 L514.511335,188.653749 C519.167799,186.440677 526.703625,186.434121 531.373884,188.653749 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M383.842162,1.66226347 C388.498627,-0.550808613 396.034453,-0.557364607 400.704711,1.66226347 L515.425139,56.1853009 C520.081603,58.398373 520.095397,61.9799158 515.425139,64.1995438 L400.704711,118.722581 C396.048247,120.935653 388.512421,120.942209 383.842162,118.722581 L269.121735,64.1995438 C264.46527,61.9864718 264.451476,58.404929 269.121735,56.1853009 L383.842162,1.66226347 L383.842162,1.66226347 Z" id="Rectangle-1" fill="#D8D8D8"></path>
                                        <path d="M514.204214,63.0182067 C518.860678,60.8051346 526.396504,60.7985786 531.066763,63.0182067 L645.78719,117.541244 C650.443655,119.754316 650.457449,123.335859 645.78719,125.555487 L531.066763,180.078524 C526.410298,182.291597 518.874472,182.298153 514.204214,180.078524 L399.483786,125.555487 C394.827322,123.342415 394.813528,119.760872 399.483786,117.541244 L514.204214,63.0182067 L514.204214,63.0182067 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M645.209061,125.28072 C649.865525,123.067648 657.401351,123.061092 662.071609,125.28072 L776.792037,179.803757 C781.448501,182.016829 781.462296,185.598372 776.792037,187.818 L662.071609,242.341038 C657.415145,244.55411 649.879319,244.560666 645.209061,242.341038 L530.488633,187.818 C525.832169,185.604928 525.818374,182.023385 530.488633,179.803757 L645.209061,125.28072 L645.209061,125.28072 Z" id="Rectangle-1" fill="#D8D8D8"></path>
                                    </g>
                                    <g id="Group" transform="translate(392.873289, 501.087360) rotate(-302.000000) translate(-392.873289, -501.087360) translate(30.750091, 264.521095)" fill="#D8D8D8">
                                        <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                                        <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                                        <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                                        <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                                        <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                                        <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                                        <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                                        <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                                        <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(794.322535, 498.277652) scale(-1, 1) rotate(-303.000000) translate(-794.322535, -498.277652) translate(424.956873, 269.757859)" fill="#D8D8D8">
                                        <path d="M112.480405,155.612439 C116.887244,152.880065 124.019088,152.87197 128.438982,155.612439 L237.009448,222.929426 C241.416288,225.6618 241.429343,230.08376 237.009448,232.824228 L128.438982,300.141215 C124.032142,302.87359 116.900299,302.881684 112.480405,300.141215 L3.909938,232.824228 C-0.496901376,230.091854 -0.509956175,225.669895 3.909938,222.929426 L112.480405,155.612439 L112.480405,155.612439 Z" id="Rectangle-1"></path>
                                        <path d="M237.713891,233.261004 C242.12073,230.52863 249.252574,230.520536 253.672468,233.261004 L362.242935,300.577991 C366.649774,303.310365 366.662829,307.732325 362.242935,310.472794 L253.672468,377.789781 C249.265629,380.522155 242.133785,380.530249 237.713891,377.789781 L129.143424,310.472794 C124.736585,307.740419 124.72353,303.31846 129.143424,300.577991 L237.713891,233.261004 L237.713891,233.261004 Z" id="Rectangle-1"></path>
                                        <path d="M361.695797,310.133552 C366.102637,307.401178 373.23448,307.393083 377.654375,310.133552 L486.224841,377.450539 C490.631681,380.182913 490.644735,384.604873 486.224841,387.345341 L377.654375,454.662328 C373.247535,457.394703 366.115692,457.402797 361.695797,454.662328 L253.125331,387.345341 C248.718491,384.612967 248.705437,380.191008 253.125331,377.450539 L361.695797,310.133552 L361.695797,310.133552 Z" id="Rectangle-1"></path>
                                        <path d="M237.556586,78.3031153 C241.963425,75.5707411 249.095269,75.5626467 253.515163,78.3031153 L362.085629,145.620102 C366.492469,148.352477 366.505524,152.774436 362.085629,155.514905 L253.515163,222.831892 C249.108323,225.564266 241.97648,225.57236 237.556586,222.831892 L128.986119,155.514905 C124.57928,152.782531 124.566225,148.360571 128.986119,145.620102 L237.556586,78.3031153 L237.556586,78.3031153 Z" id="Rectangle-1"></path>
                                        <path d="M362.947377,156.049215 C367.354217,153.316841 374.48606,153.308746 378.905954,156.049215 L487.476421,223.366202 C491.88326,226.098576 491.896315,230.520536 487.476421,233.261004 L378.905954,300.577991 C374.499115,303.310365 367.367272,303.31846 362.947377,300.577991 L254.376911,233.261004 C249.970071,230.52863 249.957017,226.10667 254.376911,223.366202 L362.947377,156.049215 L362.947377,156.049215 Z" id="Rectangle-1"></path>
                                        <path d="M486.929284,232.921763 C491.336123,230.189388 498.467967,230.181294 502.887861,232.921763 L611.458328,300.238749 C615.865167,302.971124 615.878222,307.393083 611.458328,310.133552 L502.887861,377.450539 C498.481022,380.182913 491.349178,380.191008 486.929284,377.450539 L378.358817,310.133552 C373.951978,307.401178 373.938923,302.979218 378.358817,300.238749 L486.929284,232.921763 L486.929284,232.921763 Z" id="Rectangle-1"></path>
                                        <path d="M363.265057,2.05231721 C367.671896,-0.680057055 374.80374,-0.688151427 379.223634,2.05231721 L487.7941,69.3693042 C492.20094,72.1016784 492.213995,76.5236381 487.7941,79.2641067 L379.223634,146.581094 C374.816794,149.313468 367.684951,149.321562 363.265057,146.581094 L254.69459,79.2641067 C250.287751,76.5317324 250.274696,72.1097728 254.69459,69.3693042 L363.265057,2.05231721 L363.265057,2.05231721 Z" id="Rectangle-1"></path>
                                        <path d="M486.638627,77.8055662 C491.045467,75.0731919 498.17731,75.0650976 502.597204,77.8055662 L611.167671,145.122553 C615.57451,147.854927 615.587565,152.276887 611.167671,155.017356 L502.597204,222.334343 C498.190365,225.066717 491.058521,225.074811 486.638627,222.334343 L378.068161,155.017356 C373.661321,152.284981 373.648266,147.863022 378.068161,145.122553 L486.638627,77.8055662 L486.638627,77.8055662 Z" id="Rectangle-1"></path>
                                        <path d="M610.620534,154.678114 C615.027373,151.94574 622.159217,151.937645 626.579111,154.678114 L735.149577,221.995101 C739.556417,224.727475 739.569472,229.149435 735.149577,231.889903 L626.579111,299.20689 C622.172271,301.939265 615.040428,301.947359 610.620534,299.20689 L502.050067,231.889903 C497.643228,229.157529 497.630173,224.73557 502.050067,221.995101 L610.620534,154.678114 L610.620534,154.678114 Z" id="Rectangle-1"></path>
                                    </g>
                                </g>
                                <g id="1flower" stroke="#090909" strokeWidth="2" fill="#D8D8D8" >
                                    <g id="Group" transform="translate(205.007864, 0.000000)">
                                        <path d="M118.851844,126.037472 C123.508308,123.824399 131.044134,123.817843 135.714392,126.037472 L250.43482,180.560509 C255.091284,182.773581 255.105079,186.355124 250.43482,188.574752 L135.714392,243.097789 C131.057928,245.310861 123.522102,245.317417 118.851844,243.097789 L4.13141597,188.574752 C-0.525048295,186.36168 -0.538842581,182.780137 4.13141597,180.560509 L118.851844,126.037472 L118.851844,126.037472 Z" id="Rectangle-1"></path>
                                        <path d="M268.041715,188.928516 L382.762142,243.451554 C387.418607,245.664626 387.432401,249.246168 382.762142,251.465797 L268.041715,305.988834 C263.38525,308.201906 255.849424,308.208462 251.179166,305.988834 L136.458738,251.465797 C131.802274,249.252724 131.78848,245.671182 136.458738,243.451554 L251.179166,188.928516 C255.83563,186.715444 263.371456,186.708888 268.041715,188.928516 Z" id="Rectangle-1"></path>
                                        <path d="M382.184013,251.19103 C386.840477,248.977957 394.376303,248.971401 399.046561,251.19103 L513.766989,305.714067 C518.423453,307.927139 518.437248,311.508682 513.766989,313.72831 L399.046561,368.251347 C394.390097,370.464419 386.854271,370.470975 382.184013,368.251347 L267.463585,313.72831 C262.807121,311.515238 262.793326,307.933695 267.463585,305.714067 L382.184013,251.19103 L382.184013,251.19103 Z" id="Rectangle-1"></path>
                                        <path d="M251.01295,63.421194 C255.669414,61.2081219 263.20524,61.2015659 267.875499,63.421194 L382.595926,117.944231 C387.252391,120.157303 387.266185,123.738846 382.595926,125.958474 L267.875499,180.481512 C263.219035,182.694584 255.683209,182.70114 251.01295,180.481512 L136.292522,125.958474 C131.636058,123.745402 131.622264,120.163859 136.292522,117.944231 L251.01295,63.421194 L251.01295,63.421194 Z" id="Rectangle-1"></path>
                                        <path d="M383.506488,126.391236 C388.162952,124.178164 395.698778,124.171608 400.369037,126.391236 L515.089465,180.914273 C519.745929,183.127345 519.759723,186.708888 515.089465,188.928516 L400.369037,243.451554 C395.712573,245.664626 388.176747,245.671182 383.506488,243.451554 L268.78606,188.928516 C264.129596,186.715444 264.115802,183.133901 268.78606,180.914273 L383.506488,126.391236 L383.506488,126.391236 Z" id="Rectangle-1"></path>
                                        <path d="M531.373884,188.653749 L646.094311,243.176787 C650.750776,245.389859 650.76457,248.971401 646.094311,251.19103 L531.373884,305.714067 C526.717419,307.927139 519.181593,307.933695 514.511335,305.714067 L399.790907,251.19103 C395.134443,248.977957 395.120649,245.396415 399.790907,243.176787 L514.511335,188.653749 C519.167799,186.440677 526.703625,186.434121 531.373884,188.653749 Z" id="Rectangle-1"></path>
                                        <path d="M383.842162,1.66226347 C388.498627,-0.550808613 396.034453,-0.557364607 400.704711,1.66226347 L515.425139,56.1853009 C520.081603,58.398373 520.095397,61.9799158 515.425139,64.1995438 L400.704711,118.722581 C396.048247,120.935653 388.512421,120.942209 383.842162,118.722581 L269.121735,64.1995438 C264.46527,61.9864718 264.451476,58.404929 269.121735,56.1853009 L383.842162,1.66226347 L383.842162,1.66226347 Z" id="Rectangle-1"></path>
                                        <path d="M514.204214,63.0182067 C518.860678,60.8051346 526.396504,60.7985786 531.066763,63.0182067 L645.78719,117.541244 C650.443655,119.754316 650.457449,123.335859 645.78719,125.555487 L531.066763,180.078524 C526.410298,182.291597 518.874472,182.298153 514.204214,180.078524 L399.483786,125.555487 C394.827322,123.342415 394.813528,119.760872 399.483786,117.541244 L514.204214,63.0182067 L514.204214,63.0182067 Z" id="Rectangle-1"></path>
                                        <path d="M645.209061,125.28072 C649.865525,123.067648 657.401351,123.061092 662.071609,125.28072 L776.792037,179.803757 C781.448501,182.016829 781.462296,185.598372 776.792037,187.818 L662.071609,242.341038 C657.415145,244.55411 649.879319,244.560666 645.209061,242.341038 L530.488633,187.818 C525.832169,185.604928 525.818374,182.023385 530.488633,179.803757 L645.209061,125.28072 L645.209061,125.28072 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(392.873289, 501.087360) rotate(-302.000000) translate(-392.873289, -501.087360) translate(30.750091, 264.521095)">
                                        <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                                        <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                                        <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                                        <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                                        <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                                        <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                                        <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                                        <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                                        <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(794.322535, 498.277652) scale(-1, 1) rotate(-303.000000) translate(-794.322535, -498.277652) translate(424.956873, 269.757859)">
                                        <path d="M112.480405,155.612439 C116.887244,152.880065 124.019088,152.87197 128.438982,155.612439 L237.009448,222.929426 C241.416288,225.6618 241.429343,230.08376 237.009448,232.824228 L128.438982,300.141215 C124.032142,302.87359 116.900299,302.881684 112.480405,300.141215 L3.909938,232.824228 C-0.496901376,230.091854 -0.509956175,225.669895 3.909938,222.929426 L112.480405,155.612439 L112.480405,155.612439 Z" id="Rectangle-1"></path>
                                        <path d="M237.713891,233.261004 C242.12073,230.52863 249.252574,230.520536 253.672468,233.261004 L362.242935,300.577991 C366.649774,303.310365 366.662829,307.732325 362.242935,310.472794 L253.672468,377.789781 C249.265629,380.522155 242.133785,380.530249 237.713891,377.789781 L129.143424,310.472794 C124.736585,307.740419 124.72353,303.31846 129.143424,300.577991 L237.713891,233.261004 L237.713891,233.261004 Z" id="Rectangle-1"></path>
                                        <path d="M361.695797,310.133552 C366.102637,307.401178 373.23448,307.393083 377.654375,310.133552 L486.224841,377.450539 C490.631681,380.182913 490.644735,384.604873 486.224841,387.345341 L377.654375,454.662328 C373.247535,457.394703 366.115692,457.402797 361.695797,454.662328 L253.125331,387.345341 C248.718491,384.612967 248.705437,380.191008 253.125331,377.450539 L361.695797,310.133552 L361.695797,310.133552 Z" id="Rectangle-1"></path>
                                        <path d="M237.556586,78.3031153 C241.963425,75.5707411 249.095269,75.5626467 253.515163,78.3031153 L362.085629,145.620102 C366.492469,148.352477 366.505524,152.774436 362.085629,155.514905 L253.515163,222.831892 C249.108323,225.564266 241.97648,225.57236 237.556586,222.831892 L128.986119,155.514905 C124.57928,152.782531 124.566225,148.360571 128.986119,145.620102 L237.556586,78.3031153 L237.556586,78.3031153 Z" id="Rectangle-1"></path>
                                        <path d="M362.947377,156.049215 C367.354217,153.316841 374.48606,153.308746 378.905954,156.049215 L487.476421,223.366202 C491.88326,226.098576 491.896315,230.520536 487.476421,233.261004 L378.905954,300.577991 C374.499115,303.310365 367.367272,303.31846 362.947377,300.577991 L254.376911,233.261004 C249.970071,230.52863 249.957017,226.10667 254.376911,223.366202 L362.947377,156.049215 L362.947377,156.049215 Z" id="Rectangle-1"></path>
                                        <path d="M486.929284,232.921763 C491.336123,230.189388 498.467967,230.181294 502.887861,232.921763 L611.458328,300.238749 C615.865167,302.971124 615.878222,307.393083 611.458328,310.133552 L502.887861,377.450539 C498.481022,380.182913 491.349178,380.191008 486.929284,377.450539 L378.358817,310.133552 C373.951978,307.401178 373.938923,302.979218 378.358817,300.238749 L486.929284,232.921763 L486.929284,232.921763 Z" id="Rectangle-1"></path>
                                        <path d="M363.265057,2.05231721 C367.671896,-0.680057055 374.80374,-0.688151427 379.223634,2.05231721 L487.7941,69.3693042 C492.20094,72.1016784 492.213995,76.5236381 487.7941,79.2641067 L379.223634,146.581094 C374.816794,149.313468 367.684951,149.321562 363.265057,146.581094 L254.69459,79.2641067 C250.287751,76.5317324 250.274696,72.1097728 254.69459,69.3693042 L363.265057,2.05231721 L363.265057,2.05231721 Z" id="Rectangle-1"></path>
                                        <path d="M486.638627,77.8055662 C491.045467,75.0731919 498.17731,75.0650976 502.597204,77.8055662 L611.167671,145.122553 C615.57451,147.854927 615.587565,152.276887 611.167671,155.017356 L502.597204,222.334343 C498.190365,225.066717 491.058521,225.074811 486.638627,222.334343 L378.068161,155.017356 C373.661321,152.284981 373.648266,147.863022 378.068161,145.122553 L486.638627,77.8055662 L486.638627,77.8055662 Z" id="Rectangle-1"></path>
                                        <path d="M610.620534,154.678114 C615.027373,151.94574 622.159217,151.937645 626.579111,154.678114 L735.149577,221.995101 C739.556417,224.727475 739.569472,229.149435 735.149577,231.889903 L626.579111,299.20689 C622.172271,301.939265 615.040428,301.947359 610.620534,299.20689 L502.050067,231.889903 C497.643228,229.157529 497.630173,224.73557 502.050067,221.995101 L610.620534,154.678114 L610.620534,154.678114 Z" id="Rectangle-1"></path>
                                    </g>
                                </g>
                                <g id="Line-+-Triangle-43" transform="translate(1007.000000, 385.000000)">
                                    <path d="M0.5,31.0007106 L124,31.0007106" id="Line" stroke="#000000" strokeWidth="14" strokeLinecap="square"></path>
                                    <path id="Line-decoration-1" d="M124,31.0007106 L113.2,28.0007106 L113.2,34.0007106 L124,31.0007106 Z" stroke="#000000" strokeWidth="14" strokeLinecap="square"></path>
                                    <polygon id="Triangle-43" stroke="#979797" fill="#000000" transform="translate(124.000000, 30.000000) rotate(-270.000000) translate(-124.000000, -30.000000) " points="124 0 154 60 94 60 "></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="brown_robot">
                        <g id="Group">
                            <path d="M108.704358,560.768234 C126.786679,564.260469 147.876698,570.216184 153.991995,587.590209 C157.06346,596.315481 155.139809,606.61364 149.12654,613.642745 C131.964595,596.194326 108.4918,585.088469 84.1177581,582.886427 L108.704358,560.768234" id="Fill-185" fill="#0A0A08"></path>
                            <path d="M165.259702,990.089874 C170.375977,1003.4169 184.219888,1011.70644 198.221093,1014.42711 C212.224423,1017.12653 226.644367,1015.23481 240.841126,1013.87448 C270.930856,1011.02628 301.256526,1010.66494 331.407898,1012.79046 C338.47333,1013.27933 346.380493,1013.66192 351.730581,1009.02828 C355.16127,1006.0313 356.810721,1001.54644 358.228484,997.231632 C367.406744,969.308636 367.632056,959.818176 339.076995,946.807849 C323.160642,939.555565 311.761149,937.253623 294.552442,940.631079 C281.956247,943.103063 166.532926,993.40569 165.259702,990.089874" id="Fill-186" fill="#0A0A08"></path>
                            <path d="M710.924079,990.089874 C705.805679,1003.4169 691.963893,1011.70644 677.962688,1014.42711 C663.959358,1017.12653 649.539414,1015.23481 635.34053,1013.87448 C605.252926,1011.02628 574.92513,1010.66494 544.773758,1012.79046 C537.710451,1013.27933 529.803288,1013.66192 524.4532,1009.02828 C521.022512,1006.0313 519.370935,1001.54644 517.955298,997.231632 C508.777037,969.308636 508.551726,959.818176 537.106786,946.807849 C553.02314,939.555565 564.422633,937.253623 581.63134,940.631079 C594.227535,943.103063 709.64873,993.40569 710.924079,990.089874" id="Fill-187" fill="#0A0A08"></path>
                            <path d="M44.720107,582.488954 C24.3294047,593.429021 8.63623721,612.811665 2.18297209,635.034008 C0.516516279,640.768669 -0.561153488,646.803029 0.308209302,652.709858 C2.6165907,668.364335 17.340493,678.677372 30.750786,687.073188 C30.3129163,656.125573 41.3595628,625.14395 61.2741349,601.446494 C53.3287116,629.590544 50.0723209,659.052418 51.681386,688.252854 C58.3195767,686.131582 65.5019163,688.021172 72.1911209,689.976653 C78.8782,691.934259 85.981893,693.968385 92.7008558,692.11918 C99.4198186,690.2721 105.097247,682.834895 102.402009,676.409439 C89.1723907,675.642126 79.4563581,662.368234 77.9960837,649.198494 C76.5358093,636.030879 81.2460977,623.043933 85.8777395,610.630879 L44.720107,582.488954" id="Fill-181" fill="#0A0A08"></path>
                            <g transform="translate(13.753488, 0.000000)">
                                <path d="M314.929414,549.590109 C304.443921,580.040351 293.956302,610.488469 283.468684,640.936586 C282.11894,644.858176 280.74794,648.983816 281.438753,653.073322 C283.243372,663.747699 296.915112,667.43123 303.196205,676.2479 C360.270191,640.139515 467.811856,613.247397 518.560112,673.863063 C523.249144,679.46169 527.408907,685.672469 533.313772,689.976653 C548.864526,701.309941 565.361163,683.804134 581.064958,681.306644 C582.540112,644.71364 571.017335,608.505356 559.60934,574.171782 L314.929414,549.590109" id="Fill-188" fill="#0A0A08"></path>
                                <path d="M156.637367,208.513808 C126.451986,225.190661 97.5780884,244.233222 70.3642698,265.409808 C82.8180512,291.781172 95.2718326,318.154661 107.725614,344.526025 C138.848377,317.410728 175.327605,296.470075 214.434051,283.262075 L156.637367,208.513808" id="Fill-189" fill="#0A0A08"></path>
                                <path d="M725.842558,208.513808 C756.025814,225.190661 784.899712,244.233222 812.11353,265.409808 C799.659749,291.781172 787.205967,318.154661 774.752186,344.526025 C743.629423,317.410728 707.152321,296.470075 668.043749,283.262075 L725.842558,208.513808" id="Fill-190" fill="#0A0A08"></path>
                                <path d="M222.364595,225.896335 C198.152098,169.973824 184.941609,109.313523 183.710898,48.3875314 C217.356726,64.190795 255.485405,66.7222929 292.587428,69.0008536 C264.198163,127.909724 260.782353,198.370812 283.343274,259.74954 L222.364595,225.896335" id="Fill-178" fill="#0A0A08"></path>
                                <path d="M668.060753,225.896335 C692.273251,169.973824 705.48374,109.313523 706.714451,48.3875314 C673.068623,64.190795 634.939944,66.7222929 597.837921,69.0008536 C626.227186,127.909724 629.64087,198.370812 607.082074,259.74954 L668.060753,225.896335" id="Fill-179" fill="#0A0A08"></path>
                                <path d="M632.701707,536.175933 C651.94247,515.360686 666.34966,490.453808 678.797065,464.883766 C719.000312,382.283816 740.145595,290.248669 740.077577,198.16251 C740.075451,195.614008 739.956419,192.793439 738.215567,190.954862 C736.610753,189.262946 734.106819,188.910109 731.804814,188.655046 C541.580158,167.503967 349.306442,146.357138 159.366614,169.988703 C156.1166,170.390427 152.709293,170.870795 150.077823,172.84328 C144.740488,176.843515 144.889279,184.829105 145.56734,191.518126 C155.013423,284.715933 164.922884,379.737439 202.641326,465.287615 C213.109814,489.033958 225.8548,512.123515 243.686302,530.838745 C262.984456,551.097105 287.545549,565.486895 312.965377,576.881824 C383.985302,608.709406 469.331647,607.835816 544.135107,588.986678 C580.44854,579.836301 607.116084,563.856619 632.701707,536.175933" id="Fill-191" fill="#FF9F00"></path>
                                <path d="M597.34266,187.673054 C596.054558,191.953858 592.625995,195.218661 589.235693,198.156134 C546.724065,234.989322 489.050665,253.778946 432.973577,249.06241 C376.913493,244.330996 323.1958,216.146561 287.449898,172.692368 C293.488674,156.897607 301.104633,139.542711 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C379.925442,28.0250209 404.718223,10.1451213 432.956572,3.26905439 C459.959958,-3.29456067 483.283963,0.83958159 503.21554,11.866795 C528.949953,26.0844184 549.0452,51.7458577 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075 C594.294577,163.082879 595.763353,169.682628 596.996191,175.959297 C597.776279,179.821372 598.467093,183.883247 597.34266,187.673054" id="Fill-192" fill="#965E00"></path>
                                <path d="M140.176865,436.520787 C136.880088,505.190059 110.333702,572.537255 65.8877953,624.984536 C22.7958837,604.789941 6.25035814,552.100351 2.06508837,504.699063 C-4.65174884,428.618092 5.41713023,316.313958 54.7391209,253.47287 C84.9181256,215.022159 105.46187,273.493172 117.628698,303.828636 C134.450549,345.775833 142.344958,391.357674 140.176865,436.520787" id="Fill-193" fill="#FF9F00"></path>
                                <path d="M592.553726,156.262075 C575.759507,166.039481 555.936335,171.659364 536.712577,175.540569 C490.119833,184.899247 441.208079,187.037523 395.559093,173.855029 C363.239628,164.515481 332.716279,146.797121 310.227628,122.115548 C323.323335,97.1045188 339.535144,71.9680837 358.758902,51.076318 C374.211879,66.8710795 397.971628,72.7290209 420.188205,76.4125523 C451.000633,81.5265607 482.195665,84.4449038 513.426833,85.1888368 C530.329456,85.5884351 548.065307,85.0251715 564.226102,80.6189623 C577.119879,105.194259 586.423549,132.107632 592.553726,156.262075" id="Fill-195" fill="#0A0A08"></path>
                                <path d="M390.8318,310.351866 C349.895228,326.42082 318.506767,365.940669 314.500047,409.737071 C310.4912,453.529222 335.396637,499.166326 375.40433,517.426695 C394.910791,526.328385 416.672493,528.834377 438.068595,530.230845 C460.9356,531.722962 484.391391,532.007782 506.1786,524.902159 C536.655186,514.963213 561.756177,490.322025 573.149293,460.358527 C584.540284,430.397155 582.293544,395.732 567.982005,367.048067 C540.559879,312.086293 444.923595,289.120017 390.8318,310.351866" id="Fill-196" fill="#965E00"></path>
                                <path d="M465.084735,418.30718 C464.162233,416.696033 464.38967,415.182661 464.700005,415.087013 C464.787153,416.128519 464.889181,417.119013 465.08686,417.899079 C465.08686,418.035113 465.084735,418.171146 465.084735,418.30718 L465.084735,418.30718 Z M463.698856,426.090845 C463.458665,425.54246 463.029298,424.947314 462.591428,424.377674 C462.846498,424.543464 463.031423,424.664619 463.071809,424.694377 C462.810363,424.505205 462.597805,424.318159 462.408628,424.13749 C462.187567,423.848418 461.97926,423.569975 461.828344,423.321289 C461.722065,423.14487 461.617912,422.970577 461.511633,422.796285 C461.390474,422.052351 462.570172,422.471079 463.717986,426.033456 C463.711609,426.052586 463.705233,426.071715 463.698856,426.090845 L463.698856,426.090845 Z M546.398851,381.875715 C526.452395,339.248351 461.490377,314.687933 417.760791,324.18477 C363.143977,336.047314 325.42766,397.264502 349.618902,449.898828 C354.588512,460.711364 362.559442,469.957389 371.724949,477.473238 C384.36153,487.837289 397.856847,498.783732 413.985758,503.134678 C436.91653,509.322075 466.76607,506.711933 489.435395,500.38 C537.726479,486.895682 567.525005,432.080569 547.506279,384.373205 C547.155558,383.535749 546.785707,382.702544 546.398851,381.875715 L546.398851,381.875715 Z" id="Fill-197" fill="#0A0A08"></path>
                                <path d="M324.505158,662.774209 C341.535316,697.269322 342.058209,737.363063 340.699963,775.809523 C338.414963,840.450929 331.798028,904.935046 320.904423,968.692234 C291.265316,969.204485 271.193451,939.695849 256.888288,913.73046 C226.855949,859.215046 196.526028,803.966326 181.045419,743.675866 C168.476856,694.733573 173.37207,639.512485 224.400902,614.690628 C263.815558,595.514159 306.588633,626.483029 324.505158,662.774209" id="Fill-198" fill="#965E00"></path>
                                <path d="M514.882856,662.774209 C497.852698,697.269322 497.329805,737.363063 498.688051,775.809523 C500.973051,840.450929 507.589986,904.935046 518.483591,968.692234 C548.122698,969.204485 568.194563,939.695849 582.499726,913.73046 C612.532065,859.215046 642.861986,803.966326 658.342595,743.675866 C670.909033,694.733573 666.015944,639.512485 614.989237,614.690628 C575.57033,595.514159 532.799381,626.483029 514.882856,662.774209" id="Fill-199" fill="#965E00"></path>
                                <path d="M627.625819,716.290628 C626.180423,712.50082 622.194958,710.392301 618.383791,712.532703 C608.431819,718.124954 604.350702,732.168285 600.035772,742.111481 C594.592158,754.654192 589.673563,767.315933 584.85487,780.113707 C575.381153,805.271397 566.379316,830.597004 557.311586,855.903481 C555.40494,861.22154 558.49766,868.969071 563.641567,871.460184 C564.689479,871.968184 565.80966,872.531448 566.931967,872.850276 C574.50754,874.984301 581.356163,870.204 583.877102,863.225908 C592.808795,838.518828 601.823386,813.845757 610.357595,788.998393 C614.623637,776.57046 618.832288,764.197791 622.475535,751.572184 C625.468353,741.210259 631.675051,726.918243 627.625819,716.290628" id="Fill-200" fill="#FF9F00"></path>
                                <path d="M285.893972,866.624619 C281.166679,843.445791 271.652577,820.392368 262.567842,798.629138 C253.580884,777.106092 244.194316,753.927264 228.794479,736.136636 C222.03513,728.32959 210.125498,736.740285 212.310595,745.733372 C217.605419,767.502979 228.696702,788.275715 236.616619,809.231247 C244.70233,830.626762 250.498791,852.791715 258.350688,874.216987 C264.650912,891.412469 289.59886,884.791464 285.893972,866.624619" id="Fill-201" fill="#FF9F00"></path>
                                <path d="M293.246358,753.62969 C272.239237,753.62969 272.205228,786.273473 293.246358,786.273473 C314.251353,786.273473 314.285363,753.62969 293.246358,753.62969" id="Fill-202" fill="#FF9F00"></path>
                                <path d="M551.672419,729.913105 C528.150735,729.913105 528.112474,766.467849 551.672419,766.467849 C575.196228,766.467849 575.234488,729.913105 551.672419,729.913105" id="Fill-203" fill="#FF9F00"></path>
                                <path d="M48.0881767,337.917774 C40.3128,365.868402 36.8055907,394.747883 33.7234977,423.542343 C32.2270884,437.515531 30.8539628,451.53123 30.0823767,465.566059 C29.4149442,477.745305 25.4932465,496.806996 34.6077395,506.301707 C37.6600744,509.48149 42.8146093,509.22005 45.9094558,506.301707 C54.6689767,498.041925 52.3457163,480.26405 52.9727628,469.071046 C53.7911116,454.472954 54.4755488,439.872736 55.5893535,425.291649 C57.7595721,396.811766 61.7216558,368.506176 63.4497535,339.996536 C63.9939023,331.058711 50.4114372,329.564469 48.0881767,337.917774" id="Fill-206" fill="#383937"></path>
                                <path d="M60.8969302,546.729155 C60.4845674,545.264669 59.8894047,543.90646 59.1326977,542.641774 C58.4525116,540.871213 57.4173535,539.308954 55.9847116,537.978377 C55.9273209,537.927364 55.876307,537.8721 55.8274186,537.814711 C54.7433721,536.977255 53.6593256,536.139799 52.5774047,535.302343 C50.7324,534.126929 48.7407302,533.493523 46.6023953,533.395749 C45.1782558,533.013155 43.7541163,533.013155 42.3299767,533.395749 C40.1916419,533.493523 38.2020977,534.126929 36.357093,535.302343 C36.2805721,535.338477 36.2061767,535.372485 36.133907,535.417121 C34.2251349,536.445874 32.6925907,537.850845 31.5384,539.634159 C30.5223721,540.658661 29.8188047,541.861707 29.4298233,543.249674 C28.3521535,545.806678 28.6476093,544.777925 28.2713814,547.575113 C28.0800791,548.999213 27.948293,549.194762 27.9971814,550.140619 C28.1651023,553.437305 28.8644186,555.868904 30.3331953,558.808502 C30.4054651,558.950912 30.4777349,559.086946 30.5606326,559.222979 C31.1260372,560.160335 32.5714326,561.992536 33.3302651,562.776854 C34.8245488,564.383749 36.5951581,565.520904 38.6378419,566.186192 C40.6040047,567.219197 42.7210837,567.691063 44.9933302,567.606042 C47.2634512,567.691063 49.3826558,567.219197 51.346693,566.186192 C53.3915023,565.520904 55.159986,564.383749 56.6542698,562.776854 C59.5386837,559.631079 61.6812698,555.507565 61.4857163,551.114109 C61.2880372,549.651749 61.0924837,548.191515 60.8969302,546.729155" id="Fill-207" fill="#383937"></path>
                                <path d="M556.2658,121.841356 C552.40787,110.514444 547.176814,101.143013 539.788293,91.8332218 C537.178079,88.5471632 532.223349,92.1563013 533.126721,95.7123013 C535.86447,106.486577 539.023084,116.971782 542.22846,127.635531 C545.174516,137.432067 546.352088,148.361506 551.889228,157.09528 C554.108335,160.593891 559.919674,160.204921 560.995219,155.904987 C563.803112,144.669473 559.896293,132.505105 556.2658,121.841356" id="Fill-208" fill="#515251"></path>
                                <path d="M498.906986,362.129607 C493.96926,358.027347 484.703851,363.540954 489.203707,369.615699 C498.018493,381.514377 500.558563,399.366644 497.538112,413.762812 C496.07146,420.760033 493.216805,427.383163 489.337619,433.379264 C484.859019,440.302092 477.026251,444.440485 474.813521,452.67051 C472.847358,459.988686 478.335609,465.776485 485.28626,466.418393 C496.456191,467.447146 505.445274,455.911933 511.014298,447.543749 C517.080707,438.427381 520.058647,427.765757 521.208586,416.9681 C523.531847,395.155983 515.682074,376.066661 498.906986,362.129607" id="Fill-209" fill="#515251"></path>
                                <path d="M441.473777,350.330828 C441.76073,351.032251 442.049809,351.731548 442.336763,352.432971 C446.086288,361.540837 457.220084,361.557841 460.975986,352.432971 C461.265065,351.731548 461.552019,351.032251 461.841098,350.330828 C468.781121,333.466929 434.48274,333.3479 441.473777,350.330828" id="Fill-210" fill="#515251"></path>
                                <g id="face" transform="translate(358.223256, 71.142259)">
                                    <g id="Group-+-Group" transform="translate(91.400000, 58.514644) rotate(-5.000000) translate(-91.400000, -58.514644) translate(4.251163, 7.502092)">
                                        <g id="Group" transform="translate(-0.000000, 0.000000)">
                                            <ellipse id="Oval-1" fill="#FFFFFF" cx="44.4208711" cy="43.3125444" rx="44.4208711" ry="43.3125444"></ellipse>
                                            <ellipse id="Oval-2" fill="#120AF8" cx="44.4208711" cy="43.3125444" rx="27.9686966" ry="29.4525302"></ellipse>
                                            <ellipse id="Oval-3" fill="#000000" cx="42.7756536" cy="41.5800426" rx="13.1617396" ry="13.8600142"></ellipse>
                                            <ellipse id="Oval-4" fill="#FFFFFF" cx="65.2627839" cy="29.7180576" rx="10.313076" ry="10.8602195"></ellipse>
                                            <ellipse id="Oval-5" fill="#FFFFFF" cx="42.7756536" cy="41.5800426" rx="3.29043489" ry="3.46500355"></ellipse>
                                        </g>
                                        <g id="Group" transform="translate(89.085478, 19.250020)">
                                            <ellipse id="Oval-1" fill="#FFFFFF" cx="42.2820884" cy="41.2271256" rx="42.2820884" ry="41.2271256"></ellipse>
                                            <ellipse id="Oval-2" fill="#120AF8" cx="42.2820884" cy="41.2271256" rx="26.6220556" ry="28.0344454"></ellipse>
                                            <ellipse id="Oval-3" fill="#000000" cx="40.7160851" cy="39.5780406" rx="12.5280262" ry="13.1926802"></ellipse>
                                            <ellipse id="Oval-4" fill="#FFFFFF" cx="57.9421211" cy="28.0344454" rx="7.83001637" ry="8.24542512"></ellipse>
                                            <ellipse id="Oval-5" fill="#FFFFFF" cx="40.7160851" cy="39.5780406" rx="3.13200655" ry="3.29817005"></ellipse>
                                        </g>
                                    </g>
                                    <path d="M144.169337,115.596775 C147.981869,109.702118 138.315165,104.432758 131.866167,110.828879 C119.234682,123.357758 97.2306173,133.291199 78.0384831,137.09659 C68.7106023,138.947378 59.4625247,139.482698 50.7201212,138.893199 C40.6265727,138.2127 33.1200468,133.528963 22.0094275,135.301261 C12.1302162,136.878364 6.32890283,143.968652 7.49712636,150.016227 C9.37808436,159.73365 26.6326134,162.133969 38.8778883,163.085441 C52.2178395,164.121542 66.6429777,161.918067 80.7191329,158.13847 C109.154158,150.503631 131.216495,135.623018 144.169337,115.596775" id="Fill-209" fill="#000000" transform="translate(76.190713, 135.625681) scale(-1, 1) translate(-76.190713, -135.625681) "></path>
                                </g>
                            </g>
                            <g transform="translate(949.441668, 370.372512) rotate(-59.000000) translate(-949.441668, -370.372512) translate(863.441668, 143.872512)">
                                <path d="M127.095944,342.488954 C147.488772,353.429021 163.179814,372.811665 169.635205,395.034008 C171.299535,400.768669 172.37933,406.803029 171.507842,412.709858 C169.19946,428.364335 154.477684,438.677372 141.067391,447.073188 C141.503135,416.125573 130.458614,385.14395 110.541916,361.446494 C118.48734,389.590544 121.74373,419.052418 120.134665,448.252854 C113.496474,446.131582 106.314135,448.021172 99.6249302,449.976653 C92.9378512,451.934259 85.8341581,453.968385 79.1173209,452.11918 C72.3962326,450.2721 66.7209302,442.834895 69.4161674,436.409439 C82.6436605,435.642126 92.359693,422.368234 93.8199674,409.198494 C95.2802419,396.030879 90.5720791,383.043933 85.9404372,370.630879 L127.095944,342.488954" id="Fill-183" fill="#0A0A08"></path>
                                <path d="M47.116693,320.768234 C29.0322465,324.260469 7.94435349,330.216184 1.82693023,347.590209 C-1.24453488,356.315481 0.679116279,366.61364 6.69451163,373.642745 C23.8543302,356.194326 47.3271256,345.088469 71.7011674,342.886427 L47.116693,320.768234" id="Fill-184" fill="#0A0A08"></path>
                                <path d="M89.343493,384.984536 C132.435405,364.789941 148.98093,312.100351 153.1662,264.699063 C159.885163,188.618092 149.816284,76.3139582 100.492167,13.4728703 C70.3110372,-24.977841 49.7694186,33.4931715 37.6047163,63.828636 C20.7828651,105.775833 12.8863302,151.357674 15.0544233,196.520787 C18.3512,265.190059 44.897586,332.537255 89.343493,384.984536 Z" id="Fill-194" fill="#FF9F00"></path>
                                <path d="M127.748498,233.57928 C126.898265,220.968552 125.986391,208.364201 125.044758,195.759849 C123.144488,170.344971 121.089051,145.197908 114.878102,120.450444 C113.207395,113.797556 104.345847,115.940084 103.689042,121.963816 C100.942791,147.187397 102.045967,172.234561 102.976972,197.562293 C103.419093,209.565121 103.814451,221.572201 104.188553,233.57928 C104.543526,244.914695 102.8048,260.881623 109.993516,270.37846 C113.900335,275.541356 121.203833,276.329925 124.968237,270.37846 C131.491647,260.067548 128.530712,245.165506 127.748498,233.57928" id="Fill-204" fill="#383937"></path>
                                <path d="M123.248642,310.54872 C123.244391,309.337172 122.940433,308.206393 122.332516,307.15851 C121.790493,305.494226 120.865865,304.053121 119.554381,302.837322 C117.839037,301.26231 115.843116,300.099649 113.62826,299.383347 C110.250712,298.288703 106.312009,299.143163 103.276679,300.737305 C100.273233,302.314444 97.452586,304.486728 96.0943395,307.700519 C95.6373395,308.78241 95.1824651,309.864301 94.7233395,310.946192 C94.1579349,312.752887 94.0771628,314.561707 94.4831488,316.366276 C94.4852744,317.592703 94.7956093,318.736234 95.4099023,319.79687 C95.9583023,321.480285 96.8935581,322.940519 98.2199209,324.171197 C99.9565209,325.765339 101.975823,326.940753 104.218312,327.667682 C106.025056,328.233071 107.8318,328.313841 109.638544,327.909992 C111.447414,327.827096 113.130874,327.289339 114.691051,326.29672 C117.868795,324.951264 120.087902,322.096686 121.656581,319.112452 C122.107205,318.043314 122.557828,316.974176 123.008451,315.905038 C123.569605,314.119598 123.650377,312.334159 123.248642,310.54872" id="Fill-205" fill="#383937"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg> */}
    </div>
}
