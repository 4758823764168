import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';
import containerStyles from './logo.module.css'



export default function Logo ({ children }) {
    return <div className={containerStyles.logo}>{children}
    {/* <?xml version="1.0" encoding="UTF-8"?> */}
        <svg width="35px" height="35px" viewBox="0 0 827 818" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-+-Group-+-Group" transform="translate(-194.000000, 0.000000)" strokeWidth="2">
                    <g id="Group" transform="translate(212.257453, 0.000000)" >
                        <path d="M121.677228,125.852384 C126.444387,123.642562 134.159357,123.636016 138.940639,125.852384 L256.388237,180.295354 C261.155397,182.505176 261.169519,186.081459 256.388237,188.297828 L138.940639,242.740798 C134.17348,244.95062 126.458509,244.957166 121.677228,242.740798 L4.22962933,188.297828 C-0.537529912,186.088006 -0.551652121,182.511723 4.22962933,180.295354 L121.677228,125.852384 L121.677228,125.852384 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M274.413689,188.651073 L391.861287,243.094043 C396.628447,245.303865 396.642569,248.880148 391.861287,251.096516 L274.413689,305.539486 C269.646529,307.749308 261.931559,307.755855 257.150278,305.539486 L139.702679,251.096516 C134.93552,248.886694 134.921398,245.310411 139.702679,243.094043 L257.150278,188.651073 C261.917437,186.441251 269.632407,186.434704 274.413689,188.651073 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#FF0000"></path>
                        <path d="M391.269414,250.822153 C396.036573,248.612331 403.751543,248.605784 408.532825,250.822153 L525.980424,305.265123 C530.747583,307.474945 530.761705,311.051228 525.980424,313.267597 L408.532825,367.710566 C403.765666,369.920389 396.050696,369.926935 391.269414,367.710566 L273.821815,313.267597 C269.054656,311.057775 269.040534,307.481491 273.821815,305.265123 L391.269414,250.822153 L391.269414,250.822153 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M256.980111,63.3280593 C261.74727,61.1182371 269.46224,61.1116908 274.243521,63.3280593 L391.69112,117.771029 C396.458279,119.980851 396.472402,123.557135 391.69112,125.773503 L274.243521,180.216473 C269.476362,182.426295 261.761392,182.432841 256.980111,180.216473 L139.532512,125.773503 C134.765353,123.563681 134.751231,119.987398 139.532512,117.771029 L256.980111,63.3280593 L256.980111,63.3280593 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                        <path d="M392.623328,126.205629 C397.390487,123.995807 405.105457,123.989261 409.886739,126.205629 L527.334337,180.648599 C532.101497,182.858421 532.115619,186.434704 527.334337,188.651073 L409.886739,243.094043 C405.119579,245.303865 397.404609,245.310411 392.623328,243.094043 L275.175729,188.651073 C270.40857,186.441251 270.394448,182.864967 275.175729,180.648599 L392.623328,126.205629 L392.623328,126.205629 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M544.005875,188.376709 L661.453474,242.819679 C666.220633,245.029501 666.234755,248.605784 661.453474,250.822153 L544.005875,305.265123 C539.238716,307.474945 531.523746,307.481491 526.742464,305.265123 L409.294865,250.822153 C404.527706,248.612331 404.513584,245.036048 409.294865,242.819679 L526.742464,188.376709 C531.509623,186.166887 539.224593,186.160341 544.005875,188.376709 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#0000FF"></path>
                        <path d="M392.966982,1.65982242 C397.734141,-0.549999745 405.449111,-0.556546111 410.230393,1.65982242 L527.677991,56.1027922 C532.445151,58.3126144 532.459273,61.8888976 527.677991,64.1052661 L410.230393,118.548236 C405.463233,120.758058 397.748263,120.764604 392.966982,118.548236 L275.519383,64.1052661 C270.752224,61.895444 270.738102,58.3191607 275.519383,56.1027922 L392.966982,1.65982242 L392.966982,1.65982242 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#0000FF"></path>
                        <path d="M526.428042,62.9256638 C531.195202,60.7158416 538.910172,60.7092953 543.691453,62.9256638 L661.139052,117.368634 C665.906211,119.578456 665.920333,123.154739 661.139052,125.371108 L543.691453,179.814077 C538.924294,182.023899 531.209324,182.030446 526.428042,179.814077 L408.980444,125.371108 C404.213284,123.161285 404.199162,119.585002 408.980444,117.368634 L526.428042,62.9256638 L526.428042,62.9256638 Z" id="Rectangle-1" stroke="#090909" fill="#FFFFFF"></path>
                        <path d="M660.547178,125.096744 C665.314338,122.886922 673.029308,122.880375 677.810589,125.096744 L795.258188,179.539714 C800.025347,181.749536 800.039469,185.325819 795.258188,187.542188 L677.810589,241.985157 C673.04343,244.19498 665.32846,244.201526 660.547178,241.985157 L543.09958,187.542188 C538.33242,185.332366 538.318298,181.756082 543.09958,179.539714 L660.547178,125.096744 L660.547178,125.096744 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#FF0000"></path>
                    </g>
                    <g id="Group" transform="translate(403.092850, 502.986011) rotate(-302.000000) translate(-403.092850, -502.986011) translate(32.092850, 259.986011)" >
                        <path d="M112.896397,165.534009 C117.319535,162.627424 124.477754,162.618813 128.913995,165.534009 L237.885994,237.143007 C242.309131,240.049592 242.322234,244.753488 237.885994,247.668684 L128.913995,319.277682 C124.490857,322.184267 117.332638,322.192878 112.896397,319.277682 L3.92439834,247.668684 C-0.498739094,244.762099 -0.511842174,240.058203 3.92439834,237.143007 L112.896397,165.534009 L112.896397,165.534009 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#0000FF"></path>
                        <path d="M238.593041,248.133308 C243.016179,245.226723 250.174399,245.218112 254.610639,248.133308 L363.582638,319.742306 C368.005775,322.648891 368.018878,327.352787 363.582638,330.267983 L254.610639,401.876981 C250.187502,404.783566 243.029282,404.792177 238.593041,401.876981 L129.621043,330.267983 C125.197905,327.361397 125.184802,322.657502 129.621043,319.742306 L238.593041,248.133308 L238.593041,248.133308 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M379.051075,329.907112 L488.023073,401.516109 C492.446211,404.422695 492.459314,409.126591 488.023073,412.041787 L379.051075,483.650784 C374.627937,486.55737 367.469718,486.56598 363.033477,483.650784 L254.061478,412.041787 C249.638341,409.135201 249.625238,404.431305 254.061478,401.516109 L363.033477,329.907112 C367.456614,327.000526 374.614834,326.991916 379.051075,329.907112 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                        <path d="M254.452752,83.2955818 L363.424751,154.904579 C367.847888,157.811165 367.860991,162.515061 363.424751,165.430257 L254.452752,237.039254 C250.029614,239.94584 242.871395,239.95445 238.435154,237.039254 L129.463156,165.430257 C125.040018,162.523671 125.026915,157.819775 129.463156,154.904579 L238.435154,83.2955818 C242.858292,80.3889962 250.016511,80.3803857 254.452752,83.2955818 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                        <path d="M364.289686,165.998633 C368.712823,163.092048 375.871043,163.083437 380.307283,165.998633 L489.279282,237.607631 C493.70242,240.514216 493.715523,245.218112 489.279282,248.133308 L380.307283,319.742306 C375.884146,322.648891 368.725926,322.657502 364.289686,319.742306 L255.317687,248.133308 C250.89455,245.226723 250.881446,240.522827 255.317687,237.607631 L364.289686,165.998633 L364.289686,165.998633 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#FF0000"></path>
                        <path d="M488.730121,247.772437 C493.153259,244.865851 500.311478,244.857241 504.747719,247.772437 L613.719718,319.381434 C618.142855,322.28802 618.155958,326.991916 613.719718,329.907112 L504.747719,401.516109 C500.324581,404.422695 493.166362,404.431305 488.730121,401.516109 L379.758123,329.907112 C375.334985,327.000526 375.321882,322.29663 379.758123,319.381434 L488.730121,247.772437 L488.730121,247.772437 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#0000FF"></path>
                        <path d="M364.60854,2.18316928 C369.031677,-0.723416275 376.189897,-0.732026729 380.626138,2.18316928 L489.598136,73.7921668 C494.021274,76.6987523 494.034377,81.4026482 489.598136,84.3178442 L380.626138,155.926842 C376.203,158.833427 369.04478,158.842038 364.60854,155.926842 L255.636541,84.3178442 C251.213404,81.4112586 251.200301,76.7073628 255.636541,73.7921668 L364.60854,2.18316928 L364.60854,2.18316928 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                        <path d="M488.43839,82.7663098 C492.861527,79.8597242 500.019747,79.8511138 504.455987,82.7663098 L613.427986,154.375307 C617.851124,157.281893 617.864227,161.985789 613.427986,164.900985 L504.455987,236.509982 C500.03285,239.416568 492.87463,239.425178 488.43839,236.509982 L379.466391,164.900985 C375.043254,161.994399 375.03015,157.290503 379.466391,154.375307 L488.43839,82.7663098 L488.43839,82.7663098 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                        <path d="M612.878825,164.540113 C617.301963,161.633528 624.460182,161.624917 628.896423,164.540113 L737.868422,236.149111 C742.291559,239.055697 742.304662,243.759592 737.868422,246.674788 L628.896423,318.283786 C624.473285,321.190371 617.315066,321.198982 612.878825,318.283786 L503.906827,246.674788 C499.483689,243.768203 499.470586,239.064307 503.906827,236.149111 L612.878825,164.540113 L612.878825,164.540113 Z" id="Rectangle-1" stroke="#090909" fill="#FFFFFF"></path>
                    </g>
                    <g id="Group" transform="translate(812.339647, 501.329906) scale(-1, 1) rotate(-303.000000) translate(-812.339647, -501.329906) translate(433.839647, 266.329906)" >
                        <path d="M115.154325,160.059907 C119.665925,157.249441 126.967309,157.241115 131.492275,160.059907 L242.643713,229.300842 C247.155314,232.111308 247.168679,236.659649 242.643713,239.478442 L131.492275,308.719376 C126.980674,311.529843 119.67929,311.538169 115.154325,308.719376 L4.00288631,239.478442 C-0.508713875,236.667975 -0.522079017,232.119634 4.00288631,229.300842 L115.154325,160.059907 L115.154325,160.059907 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M243.364902,239.927701 C247.876502,237.117234 255.177886,237.108908 259.702852,239.927701 L370.854291,309.168635 C375.365891,311.979102 375.379256,316.527443 370.854291,319.346235 L259.702852,388.58717 C255.191252,391.397637 247.889868,391.405962 243.364902,388.58717 L132.213464,319.346235 C127.701863,316.535769 127.688498,311.987428 132.213464,309.168635 L243.364902,239.927701 L243.364902,239.927701 Z" id="Rectangle-1" stroke="#090909" fill="#FFFFFF"></path>
                        <path d="M370.294147,318.997298 C374.805747,316.186831 382.107131,316.178506 386.632096,318.997298 L497.783535,388.238232 C502.295135,391.048699 502.3085,395.59704 497.783535,398.415833 L386.632096,467.656767 C382.120496,470.467234 374.819112,470.475559 370.294147,467.656767 L259.142708,398.415833 C254.631108,395.605366 254.617742,391.057025 259.142708,388.238232 L370.294147,318.997298 L370.294147,318.997298 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#FF0000"></path>
                        <path d="M259.541807,80.541051 L370.693246,149.781985 C375.204846,152.592452 375.218211,157.140793 370.693246,159.959586 L259.541807,229.20052 C255.030207,232.010987 247.728823,232.019312 243.203857,229.20052 L132.052419,159.959586 C127.540818,157.149119 127.527453,152.600778 132.052419,149.781985 L243.203857,80.541051 C247.715458,77.7305843 255.016842,77.7222586 259.541807,80.541051 Z" id="Rectangle-1" stroke="#090909" fill="#FFFFFF"></path>
                        <path d="M371.575479,160.509166 C376.08708,157.6987 383.388464,157.690374 387.913429,160.509166 L499.064868,229.750101 C503.576468,232.560567 503.589833,237.108908 499.064868,239.927701 L387.913429,309.168635 C383.401829,311.979102 376.100445,311.987428 371.575479,309.168635 L260.424041,239.927701 C255.912441,237.117234 255.899075,232.568893 260.424041,229.750101 L371.575479,160.509166 L371.575479,160.509166 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                        <path d="M498.504724,239.578763 C503.016324,236.768297 510.317708,236.759971 514.842673,239.578763 L625.994112,308.819698 C630.505712,311.630165 630.519077,316.178506 625.994112,318.997298 L514.842673,388.238232 C510.331073,391.048699 503.029689,391.057025 498.504724,388.238232 L387.353285,318.997298 C382.841685,316.186831 382.82832,311.63849 387.353285,308.819698 L498.504724,239.578763 L498.504724,239.578763 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                        <path d="M388.23866,2.11097329 L499.390099,71.3519078 C503.901699,74.1623744 503.915064,78.7107155 499.390099,81.5295078 L388.23866,150.770442 C383.72706,153.580909 376.425676,153.589235 371.900711,150.770442 L260.749272,81.5295078 C256.237672,78.7190412 256.224307,74.1707001 260.749272,71.3519078 L371.900711,2.11097329 C376.412311,-0.699493368 383.713695,-0.707819081 388.23866,2.11097329 Z" id="Rectangle-1" stroke="#FFFFFF" fill="#0000FF"></path>
                        <path d="M514.545107,80.0292817 L625.696546,149.270216 C630.208146,152.080683 630.221511,156.629024 625.696546,159.447816 L514.545107,228.688751 C510.033507,231.499217 502.732123,231.507543 498.207158,228.688751 L387.055719,159.447816 C382.544119,156.63735 382.530753,152.089009 387.055719,149.270216 L498.207158,80.0292817 C502.718758,77.218815 510.020142,77.2104893 514.545107,80.0292817 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                        <path d="M641.474351,159.098879 L752.62579,228.339813 C757.13739,231.15028 757.150755,235.698621 752.62579,238.517413 L641.474351,307.758348 C636.962751,310.568814 629.661367,310.57714 625.136402,307.758348 L513.984963,238.517413 C509.473363,235.706947 509.459998,231.158606 513.984963,228.339813 L625.136402,159.098879 C629.648002,156.288412 636.949386,156.280086 641.474351,159.098879 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                    </g>
                </g>
            </g>
        </svg>

    </div>
}
