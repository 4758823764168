import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep8Svg () {
    return <div> 
        <svg width="250px" height="124px" viewBox="0 0 2252 1185" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="8" transform="translate(-195.000000, 0.000000)">
                    <g id="Group">
                        <g id="Group-+-Group-+-Group-+-Group-+-Group-+-Group-+-purple_robot_legs">
                            <g id="Group-+-Group-+-Group" transform="translate(1422.000000, 363.000000)">
                                <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4" fill="#FFFF00">
                                    <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1"></path>
                                    <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1"></path>
                                    <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1"></path>
                                    <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1"></path>
                                    <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1"></path>
                                    <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1"></path>
                                    <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1"></path>
                                    <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1"></path>
                                    <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1"></path>
                                </g>
                                <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)" stroke="#090909" strokeWidth="4" fill="#FF0000">
                                    <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1"></path>
                                    <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1"></path>
                                    <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1"></path>
                                    <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1"></path>
                                    <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1"></path>
                                    <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1"></path>
                                    <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1"></path>
                                    <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1"></path>
                                    <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1"></path>
                                </g>
                                <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4" fill="#3CFD03">
                                    <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1"></path>
                                    <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1"></path>
                                    <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1"></path>
                                    <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1"></path>
                                    <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1"></path>
                                    <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1"></path>
                                    <path d="M388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 Z" id="Rectangle-1"></path>
                                    <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1"></path>
                                    <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1"></path>
                                </g>
                            </g>
                            <g id="Group-+-Group-+-Group" transform="translate(0.000000, 363.000000)">
                                <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)" stroke="#090909" strokeWidth="4">
                                    <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" fill="#0000FF"></path>
                                    <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" fill="#FF0000"></path>
                                    <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" fill="#FF0000"></path>
                                </g>
                                <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4">
                                    <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                    <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                </g>
                                <g id="Group" transform="translate(208.257453, 0.000000)">
                                    <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF9F00"></path>
                                    <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                </g>
                            </g>
                            <g id="purple_robot_legs" transform="translate(984.000000, 0.000000)">
                                <path d="M385.300444,265.384354 C365.903704,259.480366 348.089481,270.090971 338.169185,285.936446 C335.041481,285.973734 332.065185,287.769789 331.050963,291.912939 C323.009778,324.703894 319.975407,358.472632 322.368889,392.154364 C322.924741,399.970415 329.281778,404.861403 337.001481,403.289078 C343.733926,401.919767 350.854222,405.810184 357.402074,407.026198 C363.315259,408.124133 369.585185,408.372722 375.446519,406.785896 C390.122667,402.814687 394.631704,390.337593 394.718815,376.426969 C394.926222,343.633943 394.872296,310.834701 394.946963,278.039603 C394.961481,272.145973 390.836148,267.070616 385.300444,265.384354" id="Fill-1" fill="#1B1A19"></path>
                                <path d="M109.724444,701.900348 C104.04563,697.320096 97.4791111,694.169231 90.082963,694.353601 C84.4663704,694.494468 84.1158519,701.645544 88.8343704,703.570037 C93.6234074,705.523532 98.3398519,707.820908 101.992296,711.558029 C105.615704,715.264076 106.945185,721.648669 112.306667,723.059411 C114.577778,723.656025 117.413037,722.688599 118.543407,720.523804 C122.185481,713.54674 114.890963,706.068356 109.724444,701.900348" id="Fill-2" fill="#582C79"></path>
                                <path d="M100.532148,748.608141 C94.7413333,736.986607 89.8423704,725.78146 88.5004444,712.724325 C87.9881481,707.740117 80.8284444,707.551603 80.5899259,712.724325 C79.92,727.181845 82.2616296,742.287767 90,754.756575 C94.1668148,761.470548 104.012444,755.595562 100.532148,748.608141" id="Fill-3" fill="#582C79"></path>
                                <path d="M71.1653333,717.679532 C67.6539259,724.149059 65.5445926,730.43836 64.6506667,737.761376 C63.8334815,744.438061 62.8939259,753.055812 66.7454815,758.893509 C69.0684444,762.413115 75.1807407,762.234959 76.2364444,757.654708 C77.5534815,751.926804 75.7096296,746.107751 75.8485926,740.234836 C76,733.769452 77.1531852,727.12384 78.6962963,720.851112 C79.7831111,716.436587 73.4571852,713.459734 71.1653333,717.679532" id="Fill-4" fill="#582C79"></path>
                                <path d="M61.3591111,715.76954 C47.6225185,704.982851 37.8100741,743.887022 43.7917037,752.57935 C45.7579259,755.436051 50.1798519,755.887654 52.0465185,752.57935 C54.9875556,747.371411 53.8654815,740.839736 55.2654815,735.093188 C56.4663704,730.164912 59.3327407,725.990689 61.0293333,721.385579 C62.6885926,719.929262 63.4435556,717.408155 61.3591111,715.76954" id="Fill-5" fill="#582C79"></path>
                                <path d="M64.6154074,680.53827 C55.8462222,683.324538 50.3291852,691.994078 50.5677037,701.046859 C50.5946667,702.051573 50.714963,703.027284 50.8725926,703.988495 C51.0675556,708.769689 52.7537778,713.426589 56.0888889,717.0332 C65.7997037,727.529869 83.5247407,722.947546 89.8859259,711.087781 C98.5555556,694.927427 82.2574815,674.934661 64.6154074,680.53827" id="Fill-11" fill="#1B1A19"></path>
                                <path d="M68.690963,555.481452 C61.0542222,557.488808 54.8216296,563.715961 52.9528889,571.360072 C50.7211852,580.477072 55.5102222,590.385413 63.7028148,594.806153 C65.3102222,595.672072 67.0317037,596.281115 68.7946667,596.691286 C71.4805926,598.491485 74.6041481,599.562489 77.9454815,599.465125 C86.7042963,599.216536 94.1564444,593.068102 96.4234074,584.665796 C100.955259,567.875683 86.1007407,550.905344 68.690963,555.481452" id="Fill-13" fill="#1B1A19"></path>
                                <path d="M520.868148,462.515397 C497.534815,454.958292 474.164148,476.664252 480.548148,500.555722 C482.624296,508.322055 487.936,514.617571 494.747259,518.302902 C496.298667,519.759219 497.96,521.12853 499.68563,522.417049 C510.582815,530.550051 523.682667,530.052873 533.648593,520.614779 C552.396148,502.859313 545.454222,470.480601 520.868148,462.515397" id="Fill-14" fill="#1B1A19"></path>
                                <path d="M239.289778,451.900648 C229.45037,419.227773 185.079704,422.202554 171.768296,450.516836 C165.19763,464.489607 168.101333,482.874832 182.064,491.167346 C188.134815,494.771885 206.191704,498.703734 211.254519,490.87111 C214.336593,490.684669 217.435259,490.065268 220.475852,488.938331 C235.434074,483.386511 243.937778,467.329736 239.289778,451.900648" id="Fill-15" fill="#1B1A19"></path>
                                <path d="M516.269926,905.830299 C511.773333,905.602425 507.465481,906.524276 503.543407,908.276828 C499.720889,908.856869 495.881778,910.166104 492.185778,912.312256 C474.095704,922.810996 471.613037,946.344084 485.231407,961.412718 C491.988741,971.078685 503.192889,977.519211 515.27437,975.265337 C519.370667,974.500926 522.94637,972.816736 525.997333,970.48 C532.624,967.662658 538.51437,962.79653 542.639704,955.974834 C555.198222,935.20937 540.781333,907.0691 516.269926,905.830299" id="Fill-16" fill="#1B1A19"></path>
                                <path d="M240.62963,928.82892 C238.057778,912.339186 224.449778,899.874521 207.531556,899.683937 C200.116741,899.601074 192.930074,901.823873 187.097778,905.817869 C183.617481,907.218254 180.367407,909.27947 177.646222,911.945587 C165.994074,923.366178 164.986074,941.995849 173.525037,955.537732 C183.179852,970.850812 203.070222,975.38756 219.243852,968.398067 C234.679111,961.725526 243.203556,945.318654 240.62963,928.82892" id="Fill-17" fill="#1B1A19"></path>
                                <path d="M414.727407,697.724053 C415.088296,695.778845 416.538074,694.245879 417.923556,692.833065 C426.174222,684.418329 434.424889,676.003593 442.675556,667.588857 C461.844148,686.558266 570.486222,773.620333 504.854222,794.787683 C471.65037,805.495652 409.832593,724.61102 414.727407,697.724053" id="Fill-18" fill="#1B1A19"></path>
                                <path d="M232.988741,666.963241 C234.88237,666.385272 236.912889,666.963241 238.808593,667.537067 C250.091556,670.959309 261.372444,674.38155 272.655407,677.803791 C264.664,703.547249 237.524741,840.001871 188.418963,791.634746 C163.575704,767.165307 206.851259,675.046526 232.988741,666.963241" id="Fill-19" fill="#1B1A19"></path>
                                <path d="M574.930963,408.23807 C493.834667,409.157849 433.45837,341.96011 355.375704,334.452724 C277.695407,326.982626 203.966222,374.042587 130.490074,390.461888 C103.761481,396.432166 74.2017778,394.868127 51.0945185,380.226237 C27.9105185,365.536701 14.0411852,339.610945 6.92711111,313.194225 C-0.763555556,284.641712 -1.6637037,254.301429 4.32207407,225.347031 C14.96,173.874616 47.0397037,128.314474 87.8885926,95.0076973 C128.739556,61.7050634 177.963556,39.8520225 228.506667,24.6218057 C340.591704,-9.15314724 465.193778,-10.824908 572.247111,36.4277096 C681.85363,84.8072638 769.896,210.181033 703.99437,328.934049 C678.379556,375.088732 627.915259,407.637313 574.930963,408.23807" id="Fill-20" fill="#91659A"></path>
                                <path d="M301.103407,371.608487 C260.358222,386.101223 232.482667,418.007616 213.455111,451.11345 C191.420148,489.452082 178.411556,531.91729 181.418963,574.283063 C184.424296,616.650908 204.314667,658.838526 240.837037,688.683701 C254.333037,699.714836 270.104296,709.055566 287.839704,714.65089 C316.762667,723.774104 348.865185,722.446225 379.600889,719.388581 C402.687407,717.093276 426.244741,713.724896 446.301037,704.002996 C487.181037,684.186313 504.485037,642.86047 511.904,604.186243 C519.941037,562.292789 520.005333,518.679928 504.582519,478.222076 C489.161778,437.764223 457.084148,400.666464 411.854815,380.592906 L301.103407,371.608487" id="Fill-21" fill="#91659A"></path>
                                <path d="M595.190519,379.109658 C543.728593,391.963779 495.257481,367.483982 445.956741,343.111906 C406.750519,323.724039 367.015407,304.425249 324.837037,304.021292 C308.555556,303.861781 292.82163,306.532041 277.36563,310.834701 C235.539852,322.493524 195.858667,346.165407 153.678222,358.791654 C140.176,362.831225 125.900148,365.74593 112.045333,363.129532 C97.2945185,360.355693 84.3004444,351.487282 73.7517037,340.810387 C32.7700741,299.385108 27.3712593,230.652333 52.9176296,178.28707 C84.4601481,113.647726 159.885926,70.2026626 248.162667,49.8846585 C252.785778,48.8136544 257.462815,47.8130838 262.156444,46.8643027 C336.563852,32.0090409 418.722074,33.1670511 490.825185,51.4652699 C496.557926,52.9050143 502.236741,54.4690532 507.82637,56.1553149 C602.750519,84.4778834 676.805333,144.114374 686.335704,237.730904 C692.522667,298.541978 654.54637,364.289613 595.190519,379.109658" id="Fill-22" fill="#522F65"></path>
                                <path d="M408.005333,212.231892 C420.711111,213.951299 430.289185,215.751497 433.557926,216.810072 C429.87437,242.59289 426.188741,268.375708 422.503111,294.158526 C421.885037,298.498474 312.089778,287.763575 302.831111,288.627421 C296.826667,269.544076 290.820148,250.462802 284.815704,231.379456 C284.232889,229.525397 283.666667,227.375102 284.519111,225.668125 C285.280296,224.147589 286.887704,223.567548 288.347852,223.113873 C330.930667,209.859939 363.879407,206.259542 408.005333,212.231892" id="Fill-23" fill="#1B1A19"></path>
                                <path d="M554.034667,162.018994 C522.228741,171.082133 497.570074,198.739726 487.77837,230.296022 C476.696593,266.003755 485.895111,310.041288 517.143111,330.622382 C532.319111,340.61773 550.844741,344.213984 568.980444,345.438284 C577.660444,346.02454 586.616296,346.078401 594.763259,343.029043 C603.963852,339.581943 611.281185,332.507515 617.976296,325.325366 C648.010963,293.102022 672.182222,250.015342 651.630222,206.222254 C634.176889,169.029202 592.073185,151.182587 554.034667,162.018994" id="Fill-24" fill="#1B1A19"></path>
                                <path d="M140.005926,140.946937 C108.2,150.010076 83.5434074,177.667669 73.7496296,209.223965 C62.6678519,244.933769 71.8663704,288.969231 103.11437,309.550325 C118.29037,319.545673 136.816,323.141926 154.951704,324.366227 C163.633778,324.952483 172.587556,325.006344 180.734519,321.956986 C189.935111,318.511957 197.25037,311.43753 203.947556,304.253309 C233.982222,272.032037 258.153481,228.943284 237.603556,185.150196 C220.148148,147.957145 178.046519,130.11053 140.005926,140.946937" id="Fill-25" fill="#1B1A19"></path>
                                <path d="M526.824889,270.426566 C522.035852,272.947673 518.439407,272.922814 516.589333,268.071186 C493.932148,208.364262 568.445333,150.706125 618.200296,194.936315 C634.058667,209.031309 640.191704,233.741051 630.908148,252.807824 C627.069037,260.692237 618.235556,268.0629 610.225481,264.472861 C604.416,261.868892 602.254815,254.927045 598.502815,249.781254 C576.509333,219.606697 544.000296,261.386215 526.824889,270.426566" id="Fill-26" fill="#515251"></path>
                                <path d="M110.228444,254.373935 C105.439407,256.895041 101.842963,256.870182 99.9928889,252.016483 C77.3357037,192.309558 151.850963,134.653493 201.605926,178.881611 C217.464296,192.978677 223.597333,217.686348 214.313778,236.755192 C210.472593,244.639605 201.639111,252.010268 193.631111,248.420229 C187.82163,245.814188 185.65837,238.874413 181.908444,233.728622 C159.912889,203.554065 127.403852,245.333583 110.228444,254.373935" id="Fill-27" fill="#515251"></path>
                                <path d="M620.94637,209.957303 C618.932444,208.11153 616.665481,206.972164 613.944296,206.607566 C607.566519,205.749935 602.789926,209.592706 600.560296,215.333039 C599.27437,218.641344 599.821926,223.318959 600.985481,226.598262 C601.474963,227.982074 602.099259,229.287166 602.947556,230.461748 C603.101037,230.615045 603.293926,230.826346 603.449481,231.041789 C603.605037,231.257233 603.770963,231.44989 603.932741,231.657047 C603.936889,231.66119 603.938963,231.665333 603.943111,231.669476 C603.943111,231.667405 603.941037,231.667405 603.938963,231.665333 C605.940444,234.215442 608.304889,235.901703 611.353778,236.336734 C614.151704,237.822053 618.353778,237.032783 620.44237,234.762337 C621.902519,233.17344 622.622222,231.28002 622.667852,229.328597 C623.084741,227.971716 623.229926,226.552687 623.296296,225.054939 C623.346074,224.779419 623.408296,224.508043 623.447704,224.224237 C623.512,223.921787 623.586667,223.621409 623.682074,223.321031 C624.17363,221.773564 624.304296,220.186738 624.52,218.57091 C624.887111,215.832288 622.914667,211.761644 620.94637,209.957303" id="Fill-28" fill="#FFFFFF"></path>
                                <path d="M602.947556,230.461748 C602.568,230.088865 602.456,230.113724 603.938963,231.665333 L603.932741,231.657047 C603.571852,231.277949 603.246222,230.878135 602.947556,230.461748" id="Fill-29" fill="#FFFFFF"></path>
                                <path d="M204.81037,206.785722 C204.327111,205.029027 203.288,203.305476 202.439704,201.673076 C201.006519,198.911667 199.577481,196.415419 197.337481,194.165689 C192.710222,189.519147 185.208296,189.198053 180.099852,193.14026 C174.852444,197.190188 173.585185,204.23147 176.719111,210.054667 C178.202074,212.807789 180.321778,214.875221 182.524444,217.019301 C183.851852,218.314035 185.141926,219.697847 186.761778,220.644556 C188.323556,221.547763 189.989037,222.067728 191.749926,222.239669 C197.773037,224.462468 205.160889,218.614413 204.994963,212.397618 C205.357926,210.526986 205.31437,208.656354 204.81037,206.785722" id="Fill-30" fill="#FFFFFF"></path>
                                <path d="M507.82637,56.1553149 C497.964148,70.9753599 489.10163,86.4293067 481.54163,102.531656 C476.093037,114.122117 471.046815,125.903162 466.738963,137.968012 C463.381037,147.416464 462.167704,161.271155 455.205037,168.875906 C447.433481,177.356933 431.751407,175.583665 421.395556,176.180278 C406.943407,177.039982 392.509926,177.058626 378.039111,176.795536 C348.537481,176.250712 319.104296,174.179137 289.814222,170.752753 C286.526815,170.386084 282.569481,168.577599 281.988741,164.801119 C275.660741,124.763796 263.212148,87.0424928 248.162667,49.8846585 C252.785778,48.8136544 257.462815,47.8130838 262.156444,46.8643027 C279.423111,80.6848303 290.289185,118.089182 296.74163,155.284305 C322.024593,157.917276 347.411259,159.568321 372.835259,160.059284 C386.706667,160.341018 400.578074,160.077928 414.468148,160.077928 C421.376889,160.094501 428.339556,160.164935 435.266963,159.8832 C442.704593,159.584894 444.656296,158.09336 446.643259,151.12044 C456.806222,115.367133 470.92237,82.108002 490.825185,51.4652699 C496.557926,52.9050143 502.236741,54.4690532 507.82637,56.1553149" id="Fill-31" fill="#1B1A19"></path>
                                <path d="M149.675259,817.541859 C156.040593,853.378029 171.834667,891.059971 179.770074,907.866656 C183.934815,916.689493 188.32563,925.806493 195.833778,932.044004 C203.341926,938.281515 214.919407,940.771548 222.894222,935.14308 C263.672593,906.352335 235.550222,714.45409 173.108148,739.352346 C146.168,750.097603 143.531852,782.961063 149.675259,817.541859" id="Fill-32" fill="#522F65"></path>
                                <path d="M570.324444,805.885108 C563.959111,841.72335 548.162963,879.405292 540.227556,896.211978 C536.062815,905.034814 531.674074,914.151814 524.165926,920.389325 C516.655704,926.626836 505.080296,929.116869 497.105481,923.486329 C456.327111,894.697656 484.449481,702.799411 546.891556,727.697667 C578.987852,740.497926 571.311704,800.331217 570.324444,805.885108" id="Fill-33" fill="#522F65"></path>
                                <path d="M236.848593,982.041458 C261.123556,931.679407 155.524148,949.153139 138.693037,957.045838 C123.432,964.201057 109.801185,982.024885 125.620148,995.595771 C137.797037,1006.04272 180.023111,1010.00564 195.064296,1009.96628 C210.877037,1009.92485 229.98963,996.271104 236.848593,982.041458" id="Fill-34" fill="#522F65"></path>
                                <path d="M601.064296,984.139963 C603.926519,977.626933 603.184,969.560221 597.289481,959.743029 C582.953481,935.859845 518.414519,947.526953 498.113481,957.045838 C482.852444,964.201057 469.223704,982.024885 485.042667,995.595771 C485.067556,995.618558 485.092444,995.639274 485.119407,995.662061 C503.665778,1011.29209 588.288,1013.22073 601.064296,984.139963" id="Fill-35" fill="#522F65"></path>
                                <path d="M541.96563,806.069479 C537.672296,800.235924 528.679111,798.251356 522.604148,794.282219 C515.784593,789.826262 511.232,782.768407 504.472593,778.474033 C501.388444,776.512252 498.219259,779.739765 498.783407,782.842984 C500.305778,791.234933 508.653926,798.562092 515.04,803.579446 C521.005037,808.267419 531.120296,815.694014 539.059852,813.20191 C541.776889,812.350493 543.913185,808.714879 541.96563,806.069479" id="Fill-36" fill="#1B1A19"></path>
                                <path d="M519.843556,841.242744 C517.779852,835.572845 511.149037,833.267182 506.652444,829.697859 C501.861333,825.896519 497.35437,821.297624 493.380444,816.653153 C490.96,813.823382 485.772741,816.829237 487.044148,820.336413 C489.528889,827.18711 493.069333,832.910871 498.356148,837.986229 C502.771852,842.222599 510.159704,848.84128 516.736593,846.70963 C518.883259,846.013581 520.696,843.585695 519.843556,841.242744" id="Fill-37" fill="#1B1A19"></path>
                                <path d="M206.898963,769.470969 C202.188741,773.740485 199.07763,779.362738 194.802963,784.063141 C191.086222,788.152429 184.31437,792.208573 182.941333,797.692031 C182.252741,800.438939 184.218963,802.531229 186.83437,802.798462 C193.49837,803.482082 200.118815,795.52102 203.787852,790.833047 C208.197333,785.200436 212.06963,778.848988 211.822815,771.507327 C211.735704,768.955147 208.759407,767.784708 206.898963,769.470969" id="Fill-38" fill="#1B1A19"></path>
                                <path d="M223.584889,800.296 C218.920296,806.425789 212.864,812.012826 207.371852,817.432065 C202.427259,822.312695 194.313481,825.913092 192.338963,832.921229 C191.581926,835.60599 193.268148,839.309965 196.252741,839.807143 C205.333037,841.313178 212.708444,833.149102 218.163259,826.799726 C224.391704,819.553358 227.235259,811.865744 229.157926,802.643094 C229.896296,799.108988 225.64237,797.594667 223.584889,800.296" id="Fill-39" fill="#1B1A19"></path>
                                <path d="M437.48,449.261462 C431.556444,445.149387 424.859259,445.327542 417.921481,444.40362 C411.919111,443.60192 405.94163,442.532988 399.897778,442.06274 C386.67763,441.035239 373.31437,440.894372 360.063111,440.31226 C347.376,439.755006 334.659852,439.44427 321.960296,439.270258 C308.939259,439.094174 294.704889,438.130892 282.532148,443.222822 C274.227556,446.696853 270.071111,459.161517 279.784,464.224446 C291.786667,470.482673 305.178963,470.969493 318.496593,472.036354 C331.733333,473.099072 345.063407,473.76819 358.333333,474.273654 C371.030815,474.756331 383.732444,475.342587 396.434074,475.692683 C403.400889,475.883268 410.268148,475.286654 417.21837,474.924129 C424.716148,474.53053 430.799407,475.046352 437.48,471.153863 C446.201481,466.068147 445.120889,454.568836 437.48,449.261462" id="Fill-40" fill="#1B1A19"></path>
                                <path d="M391.192889,507.288339 C381.554667,506.161403 371.182222,507.849736 361.417481,507.800018 C352.084148,507.754444 342.71763,507.418849 333.488,508.980816 C327.458667,510.002102 328.931259,518.025311 333.488,519.775791 C342.77363,523.3389 353.197926,524.020448 363.072593,524.16753 C372.84563,524.314611 383.769778,525.006517 393.231704,522.323828 C402.083852,519.815151 399.383407,508.243335 391.192889,507.288339" id="Fill-41" fill="#1B1A19"></path>
                                <path d="M379.269037,573.80453 C373.716741,571.658378 366.569481,572.801888 360.656296,572.51601 C355.512593,572.269493 348.713778,570.72617 344.304296,573.951611 C342.786074,575.061975 341.309333,577.235057 342.145185,579.250699 C344.806222,585.649793 352.032296,587.694438 358.416296,589.055462 C365.885037,590.646431 374.148148,591.945309 381.197926,588.549998 C388.096296,585.225121 385.107556,576.062546 379.269037,573.80453" id="Fill-42" fill="#1B1A19"></path>
                                <path d="M179.247407,509.500781 C174.62637,520.660354 165.583407,529.305035 156.720889,537.5209 C136.347259,556.405374 115.60237,574.892106 94.4986667,592.962452 C85.2234074,586.416276 76.5019259,579.091188 68.4545185,571.086624 C66.3659259,569.012978 64.2068148,566.638953 63.9807407,563.707675 C63.8044444,561.416513 64.856,559.231002 65.8971852,557.182215 C85.6195556,518.410624 112.24237,480.167284 144.842667,451.022301 C154.10963,442.738074 172.21837,430.3542 182.785778,444.17989 C191.959407,456.182593 184.613037,496.545153 179.247407,509.500781" id="Fill-44" fill="#522F65"></path>
                                <g id="Group-48" transform="translate(29.037037, 575.969325)" fill="#522F65">
                                    <path d="M46.1540741,126.985454 C44.6980741,129.36155 42.6717037,131.967591 39.8820741,131.899229 C38.3182222,131.861941 36.9202963,130.952519 35.6717037,130.009953 C39.3013333,132.748575 -43.3173333,20.3180041 34.0788148,1.17872597 C85.3354074,-11.4972393 58.6358519,106.611517 46.1540741,126.985454" id="Fill-47"></path>
                                </g>
                                <g id="Group" transform="translate(609.679599, 515.761305) rotate(-24.000000) translate(-609.679599, -515.761305) translate(543.179599, 437.261305)">
                                    <path d="M131.711111,131.963022 C131.366815,130.019885 130.792296,128.149254 129.749037,126.3822 C128.326222,123.977102 126.523852,122.288769 124.377185,120.631509 C119.62963,116.966894 113.175111,115.442215 107.303407,117.157479 C104.920296,117.853528 102.798519,119.007395 100.989926,120.484427 C97.5303704,122.719656 95.2965926,126.324196 93.9214815,130.129679 C91.4201481,137.05081 93.3739259,145.879861 98.946963,150.793636 C100.738963,152.374247 102.406519,153.418321 104.563556,154.327742 C105.142222,154.588761 105.727111,154.831135 106.320296,155.059008 C106.851259,155.268237 106.880296,155.268237 106.438519,155.077652 C107.052444,155.342814 107.66637,155.597618 108.309333,155.788202 C111.746074,156.81156 116.060148,156.74527 119.453333,155.628691 C129.290667,152.384605 134.511111,141.825789 131.711111,131.963022" id="Fill-12" fill="#1B1A19"></path>
                                    <path d="M8.61274074,71.8252106 C13.2337778,82.9847832 22.2746667,91.6294642 31.1371852,99.8453292 C51.5108148,118.731875 72.2577778,137.218607 93.3594074,155.288953 C102.634667,148.742777 111.358222,141.415618 119.40563,133.413125 C121.492148,131.337407 123.651259,128.965454 123.877333,126.032104 C124.055704,123.743014 123.004148,121.555431 121.962963,119.508716 C102.240593,80.7350532 75.6177778,42.4917137 43.0154074,13.3467301 C33.7505185,5.06457464 15.6417778,-7.31929857 5.07437037,6.50431902 C-4.10133333,18.5070225 3.24711111,58.8695828 8.61274074,71.8252106" id="Fill-43" fill="#522F65"></path>
                                </g>
                                <g id="Group" transform="translate(738.606354, 609.291523) rotate(-31.000000) translate(-738.606354, -609.291523) translate(695.606354, 526.291523)">
                                    <path d="M76.378963,115.707454 C72.7472593,109.68953 64.6936296,113.932115 65.6767407,120.2152 C67.3235556,130.751229 69.8871111,140.877086 70.2168889,151.603699 C70.5134815,161.3401 85.842963,161.333885 85.3721481,151.603699 C84.7706667,139.207397 82.8874074,126.496215 76.378963,115.707454" id="Fill-7" fill="#582C79"></path>
                                    <path d="M64.7943704,125.028969 C63.1226667,122.329708 58.5140741,121.847031 56.9668148,125.028969 C54.7247407,129.640294 54.3534815,134.790229 53.4097778,139.79101 C52.3602963,145.344902 50.5413333,150.598415 48.4755556,155.849857 C44.7712593,165.256877 60.3641481,169.725264 63.7842963,160.065511 C67.3351111,150.024589 70.8008889,134.723939 64.7943704,125.028969" id="Fill-8" fill="#582C79"></path>
                                    <path d="M44.824,126.429924 C44.1001481,123.053258 39.7404444,123.140264 38.0438519,125.545362 C32.7300741,133.081751 28.5445926,140.280472 20.7232593,145.533986 C18.2675556,147.185031 16.4257778,150.437403 17.4897778,153.47226 C17.9274074,154.719348 18.4604444,155.56455 19.3004444,156.575479 C21.2936296,158.968147 25.2862222,159.1028 27.829037,157.673413 C38.6121481,151.609914 47.5285926,139.076888 44.824,126.429924" id="Fill-9" fill="#582C79"></path>
                                    <path d="M62.5626667,82.6279796 C52.24,80.0757996 41.152,85.5509714 36.530963,94.9849223 C34.5191111,99.0907832 33.4882963,103.66482 33.786963,108.211926 C29.978963,126.189051 51.7235556,139.867658 66.7253333,129.464211 C74.7312593,123.912391 81.218963,114.308571 80.8435556,104.298722 C80.4494815,93.8082679 72.5700741,85.1014397 62.5626667,82.6279796" id="Fill-10" fill="#1B1A19"></path>
                                    <path d="M3.50133333,122.971896 C3.9762963,124.861172 5.67703704,125.818239 7.47525926,125.994323 C13.544,126.584722 18.5072593,120.318209 24.7585185,119.895607 C27.9442963,119.680164 38.381037,122.773025 39.2355556,117.368286 C41.7161481,101.665751 -0.951703704,105.251646 3.50133333,122.971896" id="Fill-6" fill="#582C79"></path>
                                    <path d="M55.9318519,125.277558 C57.3899259,124.761736 58.4228148,123.479431 59.3042963,122.211628 C78.672,94.3903804 68.2228148,-2.71882413 21.1558519,0.746920245 C-30.8764444,4.57933333 30.538963,107.288004 48.5315556,122.588654 C50.6284444,124.37228 53.333037,126.199409 55.9318519,125.277558 Z" id="Fill-45" fill="#522F65"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
       
    </div>
}
