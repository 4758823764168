import React from 'react';

const PyramidSvg = () => {
    return <div style={{ width: '100%'}}>
        <svg viewBox="0 0 4288 1083" version="1.1" xmlns="http://www.w3.org/2000/svg">

            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"  >
                <g id="Group-+-arrow-+-1" transform="translate(0.000000, -97.000000)">
                    <g id="Group" transform="translate(0.000000, 352.174938)" strokeWidth="4">
                        <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(2153.987474, 2.243152)" stroke="#090909">
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.616398)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#FFFF00"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)" fill="#FFFF00">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.778778)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" fill="#FFFF00"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39" fill="#FFFF00"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" fill="#FFFF00">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(794.368664, 412.520037) scale(-1, 1) rotate(-1.000000) translate(-794.368664, -412.520037) translate(526.242098, 5.948652)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#3CFD03"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39" fill="#3CFD03"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" fill="#3CFD03"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.959677)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)" fill="#3CFD03">
                                        <path d="M352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L352.513721,340.392545 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.000000)" fill="#B5ADAD">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(3228.737474, 13.458915)" stroke="#090909">
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.616398)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#FFFF00"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)" fill="#FFFF00">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.778778)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" fill="#FFFF00"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39" fill="#FFFF00"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" fill="#FFFF00">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(794.368664, 412.520037) scale(-1, 1) rotate(-1.000000) translate(-794.368664, -412.520037) translate(526.242098, 5.948652)" fill="#3CFD03">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L354.878501,70.589904 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.959677)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39">
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.616398)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39" stroke="#000000" fill="#FF0000"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" stroke="#090909" fill="#FFFF00"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.778778)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                        <path d="M354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L354.878501,70.7708033 Z" id="Triangle-39" stroke="#000000" fill="#0000FF"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" stroke="#000000" fill="#FF0000"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" stroke="#090909" fill="#FFFF00"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(794.368664, 412.520037) scale(-1, 1) rotate(-1.000000) translate(-794.368664, -412.520037) translate(526.242098, 5.948652)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" stroke="#090909" fill="#FFFF00"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39" stroke="#090909" fill="#FFFF00"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" stroke="#000000" fill="#FF0000"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" stroke="#000000" fill="#0000FF"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.959677)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39" stroke="#000000" fill="#FF0000"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" stroke="#000000" fill="#0000FF"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39" stroke="#090909" fill="#3CFD03"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" stroke="#000000" fill="#0000FF"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" stroke="#090909" fill="#FFFF00"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(1603.150052, 414.422419) scale(-1, 1) translate(-1603.150052, -414.422419) translate(1068.018789, 2.243152)" stroke="#090909">
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.616398)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)" fill="#B5ADAD">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.778778)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" fill="#B5ADAD">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(794.368664, 412.520037) scale(-1, 1) rotate(-1.000000) translate(-794.368664, -412.520037) translate(526.242098, 5.948652)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#FFFF00"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" fill="#FFFF00"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.959677)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39" fill="#FFFF00"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" fill="#FFFF00"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39" fill="#B5ADAD"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39" fill="#FFFF00"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39" fill="#FFFF00"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="arrow-+-1" transform="translate(809.989040, 0.000000)">
                        <g id="arrow" transform="translate(253.152356, 569.283959) scale(-1, 1) rotate(-313.000000) translate(-253.152356, -569.283959) translate(78.140874, 387.588609)" stroke="#343A40" strokeWidth="27" strokeLinecap ="square" fill="#343A40" >
                            <path d="M349.377458,-7.67431329e-13 L20.328298,334.467555" id="Line"></path>
                            <path d="M0.181624018,362.580889 L22.424644,301.915969 L57.8126028,332.34793 L0.181624018,362.580889 L0.181624018,362.580889 Z" id="Triangle-41"></path>
                        </g>
                        <text id="I"  fontFamily="Helvetica" fontSize="392.506958" fontWeight="normal" fill="#9012FE">
                            <tspan x="198.455839" y="380">I</tspan>
                        </text>
                    </g>
                    <g id="arrow-+-1" transform="translate(1875.764092, 0.000000)">
                        <g id="arrow" transform="translate(253.152356, 569.283959) scale(-1, 1) rotate(-313.000000) translate(-253.152356, -569.283959) translate(78.140874, 387.588609)" stroke="#343A40" strokeWidth="27" strokeLinecap="square" fill="#343A40">
                            <path d="M349.377458,-7.67431329e-13 L20.328298,334.467555" id="Line"></path>
                            <path d="M0.181624018,362.580889 L22.424644,301.915969 L57.8126028,332.34793 L0.181624018,362.580889 L0.181624018,362.580889 Z" id="Triangle-41"></path>
                        </g>
                        <text id="2"  fontFamily="Helvetica" fontSize="392.506958" fontWeight="normal" fill="#9012FE">
                            <tspan x="143.834509" y="380">2</tspan>
                        </text>
                    </g>
                    <g id="arrow-+-1" transform="translate(2981.926409, 0.000000)">
                        <g id="arrow" transform="translate(253.152356, 569.283959) scale(-1, 1) rotate(-313.000000) translate(-253.152356, -569.283959) translate(78.140874, 387.588609)" stroke="#343A40" strokeWidth="27" strokeLinecap="square" fill="#343A40" >
                            <path d="M349.377458,-7.67431329e-13 L20.328298,334.467555" id="Line"></path>
                            <path d="M0.181624018,362.580889 L22.424644,301.915969 L57.8126028,332.34793 L0.181624018,362.580889 L0.181624018,362.580889 Z" id="Triangle-41"></path>
                        </g>
                        <text id="3"  fontFamily="Helvetica" fontSize="392.506958" fontWeight="normal"  fill="#9012FE">
                            <tspan x="143.834509" y="380">3</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default PyramidSvg;