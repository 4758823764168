// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-goods-amazon-js": () => import("./../../../src/pages/goods_amazon.js" /* webpackChunkName: "component---src-pages-goods-amazon-js" */),
  "component---src-pages-home-3-x-3-js": () => import("./../../../src/pages/home3x3.js" /* webpackChunkName: "component---src-pages-home-3-x-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-lessons-js": () => import("./../../../src/pages/more-lessons.js" /* webpackChunkName: "component---src-pages-more-lessons-js" */),
  "component---src-pages-page-cube-2-x-2-js": () => import("./../../../src/pages/page-cube2x2.js" /* webpackChunkName: "component---src-pages-page-cube-2-x-2-js" */),
  "component---src-pages-page-form-js": () => import("./../../../src/pages/page-form.js" /* webpackChunkName: "component---src-pages-page-form-js" */),
  "component---src-pages-page-pyramid-js": () => import("./../../../src/pages/page-pyramid.js" /* webpackChunkName: "component---src-pages-page-pyramid-js" */),
  "component---src-pages-page-students-js": () => import("./../../../src/pages/page-students.js" /* webpackChunkName: "component---src-pages-page-students-js" */),
  "component---src-pages-step-1-js": () => import("./../../../src/pages/step-1.js" /* webpackChunkName: "component---src-pages-step-1-js" */),
  "component---src-pages-step-2-js": () => import("./../../../src/pages/step-2.js" /* webpackChunkName: "component---src-pages-step-2-js" */),
  "component---src-pages-step-3-js": () => import("./../../../src/pages/step-3.js" /* webpackChunkName: "component---src-pages-step-3-js" */),
  "component---src-pages-step-4-js": () => import("./../../../src/pages/step-4.js" /* webpackChunkName: "component---src-pages-step-4-js" */),
  "component---src-pages-step-5-js": () => import("./../../../src/pages/step-5.js" /* webpackChunkName: "component---src-pages-step-5-js" */),
  "component---src-pages-step-6-js": () => import("./../../../src/pages/step-6.js" /* webpackChunkName: "component---src-pages-step-6-js" */),
  "component---src-pages-step-7-js": () => import("./../../../src/pages/step-7.js" /* webpackChunkName: "component---src-pages-step-7-js" */),
  "component---src-pages-step-8-js": () => import("./../../../src/pages/step-8.js" /* webpackChunkName: "component---src-pages-step-8-js" */)
}

