import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep6Svg () {
    return <div> 
      <svg width="200px" height="168px" viewBox="0 0 1678 1669" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="nav-step6">
                <g id="stepsGroup" transform="translate(66.000000, 68.000000)">
                    <g id="steps">
                        <g id="stepsGroup1">
                            <g id="Group">
                                <g id="Group-+-Group-+-Group-+-Line-+-Line" transform="translate(0.000000, 700.000000)">
                                    <path d="M10.5,201.5 L10.5,552.5" id="Line" stroke="#FFFFFF" strokeWidth="21" strokeLinecap="square"></path>
                                    <g transform="translate(587.000000, 0.000000)">
                                        <g id="Group-+-Group-+-Group">
                                            <g id="Group" transform="translate(406.092850, 506.986011) rotate(-302.000000) translate(-406.092850, -506.986011) translate(35.092850, 260.986011)">
                                                <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                                <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                                <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                            </g>
                                            <g id="Group" transform="translate(816.339647, 504.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -504.329906) translate(437.839647, 266.329906)" stroke="#090909" strokeWidth="4">
                                                <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                            </g>
                                            <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                                <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            </g>
                                        </g>
                                        <path d="M403.5,334.5 L403.5,685.5" id="Line" stroke="#FFFFFF" strokeWidth="21" strokeLinecap="square"></path>
                                        <path d="M822.5,334.5 L822.5,685.5" id="Line" stroke="#FFFFFF" strokeWidth="21" strokeLinecap="square"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="green_robot">
                    <path d="M54.0598206,1130.13237 C47.4733817,1115.22494 36.170084,1104.79127 33.8990038,1087.65615 C31.7413244,1071.36369 37.8710954,1055.01914 46.5968244,1041.51206 C52.6959466,1032.07426 40.693855,1020.64472 31.3704733,1026.28594 C9.19602672,1039.70723 -0.458362595,1064.25776 0.136225191,1089.71224 C0.678709924,1112.94822 12.1229924,1145.78747 38.1254809,1150.85262 C50.918313,1153.34383 59.0954275,1141.52514 54.0598206,1130.13237" id="Fill-122" fill="#0A0A08"></path>
                    <path d="M123.464153,1081.14161 C119.26526,1061.55512 108.850779,1043.44558 91.5464351,1032.83418 C80.5925344,1026.11741 67.3399695,1039.48967 73.9601221,1050.41668 C81.1442137,1062.27828 87.8869618,1074.05407 88.8891794,1088.22303 C89.8760725,1102.17442 82.5509962,1114.19536 82.9371718,1127.81888 C83.3877099,1143.58123 100.866752,1149.0907 112.108752,1139.89804 C128.012443,1126.89042 127.402531,1099.52694 123.464153,1081.14161" id="Fill-123" fill="#0A0A08"></path>
                    <path d="M612.782317,1424.29138 C595.720099,1423.45178 577.968282,1427.45365 560.850897,1428.52613 C542.369637,1429.68134 523.842405,1430.05211 505.324366,1430.03985 C469.036122,1430.01534 432.747878,1428.71305 396.471893,1427.79072 C360.882443,1426.88677 325.302187,1426.23409 289.703542,1425.75914 C270.796263,1425.50481 251.888985,1425.11565 232.981706,1425.31789 C216.419065,1425.49868 199.920786,1424.54264 186.744844,1435.50642 C181.231115,1440.09356 181.841027,1449.88987 186.744844,1454.56894 C199.077943,1466.33554 216.737813,1465.89736 232.981706,1467.11998 C250.758042,1468.45598 268.561962,1469.25574 286.365882,1470.16275 C324.177374,1472.09321 361.979672,1473.83062 399.809553,1475.30758 C436.057954,1476.72018 472.318615,1478.53114 508.594599,1478.95094 C526.018473,1479.15011 543.448477,1478.98771 560.850897,1478.13892 C579.638645,1477.22578 601.255282,1477.33303 619.1205,1471.08508 C644.387416,1462.25094 640.277405,1425.64576 612.782317,1424.29138" id="Fill-124" fill="#0A0A08"></path>
                    <path d="M596.89395,1145.27268 C585.308683,1128.39802 566.855008,1122.50859 547.451218,1118.93571 C528.543939,1115.45169 508.199229,1111.83591 489.215328,1116.17791 C475.92905,1119.21455 469.587802,1139.15037 480.72866,1148.31239 C495.498344,1160.44976 514.543542,1166.88156 532.767351,1172.18572 C551.533645,1177.64923 572.049989,1181.69094 589.94279,1171.76899 C598.616416,1166.96123 602.729492,1153.77895 596.89395,1145.27268" id="Fill-125" fill="#0A0A08"></path>
                    <path d="M345.508977,1111.77156 C332.967466,1110.45394 319.751679,1113.47527 307.458424,1115.8102 C293.746126,1118.41479 278.927405,1119.32486 269.649996,1131.10985 C257.050252,1147.1204 261.243015,1168.06128 281.293496,1175.20705 C296.486134,1180.62152 310.146328,1173.07741 324.100752,1167.54956 C337.203137,1162.36183 349.545431,1157.0638 360.637252,1148.28787 C376.89034,1135.4304 361.587366,1113.46301 345.508977,1111.77156" id="Fill-126" fill="#0A0A08"></path>
                    <path d="M425.416672,1006.61059 C406.423576,1006.61059 392.867588,1021.99297 391.868435,1040.15154 C390.029504,1073.63428 387.801332,1118.92039 413.175519,1144.82224 C422.508095,1154.3489 439.168813,1152.12121 445.801225,1140.61813 C462.164649,1112.23732 456.160538,1071.75591 458.961844,1040.15154 C460.564779,1022.0849 442.396137,1006.61059 425.416672,1006.61059" id="Fill-127" fill="#0A0A08"></path>
                    <path d="M672.421924,773.380532 C665.896782,757.890899 647.694427,752.050495 632.998302,760.091009 C605.668718,775.050532 570.747412,788.765982 549.627286,812.265486 C540.374397,822.558202 546.194615,841.596202 561.537431,841.014 C593.216088,839.809761 625.329958,822.380477 654.412656,810.877394 C669.804511,804.791853 679.425187,789.997798 672.421924,773.380532" id="Fill-128" fill="#0A0A08"></path>
                    <path d="M298.637683,821.467339 C292.875698,801.519266 271.27745,790.604514 254.751588,780.278092 C246.442683,775.084239 238.008118,769.970055 229.319168,765.447266 C215.864321,758.445523 206.718702,755.678532 191.443313,757.792844 C173.673107,760.253413 168.594592,783.29022 175.671412,796.549101 C186.006206,815.914972 211.168916,822.738991 230.394943,830.540495 C247.32537,837.410477 268.660038,848.530532 287.070805,841.835211 C295.398099,838.807761 301.288809,830.641615 298.637683,821.467339" id="Fill-129" fill="#0A0A08"></path>
                    <path d="M603.097279,10.4785688 C580.625538,45.5914679 560.017248,81.6113761 544.962531,120.456495 C540.536836,131.886037 557.065763,140.16556 564.90574,132.063761 C593.95166,102.040532 618.032466,67.5711193 640.519531,32.3509725 C655.574248,8.77179817 618.099893,-12.9596514 603.097279,10.4785688" id="Fill-131" fill="#0A0A08"></path>
                    <path d="M301.984538,124.197908 C291.471981,103.689083 279.537317,85.3834312 264.969916,67.4730642 C257.801149,58.6573028 250.782561,49.3911009 242.63916,41.4210642 C233.257546,32.2375963 226.272672,29.8505688 213.063015,31.732 C206.76774,32.6298165 201.563565,39.1688624 199.926916,44.8683119 C193.383385,67.6538532 217.448866,85.9870826 231.623962,100.814844 C247.276332,117.193101 264.124008,130.651156 282.779966,143.395248 C295.155973,151.849431 307.915092,135.765339 301.984538,124.197908" id="Fill-133" fill="#0A0A08"></path>
                    <path d="M472.692531,696.624881 C472.094878,675.046642 470.390802,653.572587 467.782584,632.14756 C466.473878,621.419725 461.288092,612.487523 450.361775,609.273156 C429.658473,603.175358 408.216534,601.165229 386.808309,604.039468 C384.160248,604.394917 381.686885,605.056789 379.379027,605.933156 C372.381893,604.62167 363.524374,609.677633 362.83784,618.159394 C358.740088,668.768055 361.161347,719.600404 361.550588,770.313248 C361.639469,782.009376 371.140615,790.953835 382.618611,791.376697 C416.482531,792.620771 463.077985,801.620385 470.967,757.106459 C474.445645,737.489321 473.241145,716.444257 472.692531,696.624881" id="Fill-134" fill="#0A0A08"></path>
                    <path d="M655.972683,703.228275 C657.354947,674.792312 193.263855,689.751835 158.339485,692.18789 C164.928989,691.728257 162.569027,586.28844 159.691099,488.408055 C157.441473,412.13655 154.906813,340.436862 156.095989,326.528367 C157.524225,309.512752 160.138573,292.166202 163.978874,274.874807 C167.485103,258.891835 172.064042,243.019174 177.709561,227.468257 C201.735198,161.348514 245.195275,101.872 310.694943,73.0193028 C347.124172,56.9597248 387.798267,53.4542569 427.59274,53.2029908 C470.685031,52.9639817 515.294439,56.6931376 553.243851,77.0947156 C613.306412,109.394661 645.579656,177.444862 659.626027,244.189706 C662.031962,255.695853 663.711519,267.407303 664.811813,279.271963 C669.507218,328.746862 664.799553,380.428 666.721237,428.989761 C667.692805,454.002991 668.523389,478.771083 668.91263,503.428862 C669.948561,570.170642 667.674416,635.968642 655.972683,703.228275" id="Fill-135" fill="#149D8F"></path>
                    <path d="M668.91263,503.428862 C579.565088,477.110275 487.220088,463.171138 394.648286,461.240679 C315.877664,465.169009 237.428855,474.266679 159.691099,488.408055 C157.441473,412.13655 154.906813,340.436862 156.095989,326.528367 C157.524225,309.512752 160.138573,292.166202 163.978874,274.874807 C166.734206,274.261963 169.461954,273.673633 172.220351,273.091431 C332.615004,239.146 505.937344,233.379138 664.811813,279.271963 C669.507218,328.746862 664.799553,380.428 666.721237,428.989761 C667.692805,454.002991 668.523389,478.771083 668.91263,503.428862" id="Fill-136" fill="#0A0A08"></path>
                    <path d="M478.705836,813.48811 C487.213958,817.974128 494.24787,824.39367 499.087324,833.540367 C493.705385,825.984 486.75116,819.169174 478.705836,813.48811 L478.705836,813.48811 Z M577.168347,826.25978 C564.767821,773.815651 515.751107,737.094037 455.657897,730.24244 C408.964366,724.916826 364.805496,738.601633 330.196809,763.982569 C316.033973,769.495101 302.772214,777.27822 291.045962,787.359505 C247.009687,825.224073 235.047439,884.590275 248.955889,935.713725 C256.121592,962.047633 271.341813,986.883138 296.207229,1003.5862 C319.944767,1019.5324 349.97758,1027.8487 379.106252,1032.84338 C442.206115,1043.6662 507.025378,1033.55121 543.604786,983.383798 C575.49492,939.642055 589.142855,876.926661 577.168347,826.25978 L577.168347,826.25978 Z" id="Fill-137" fill="#149D8F"></path>
                    <path d="M414.416798,854.652844 C414.450511,854.089028 414.475031,853.611009 414.490355,853.191211 C415.581454,850.727578 416.519309,848.214917 417.147611,845.588881 C416.880966,846.302844 419.835515,844.740092 416.635775,843.805505 C427.411912,846.949394 419.020256,855.431156 414.521004,856.227853 C414.450511,856.24011 414.386149,856.233982 414.315656,856.246239 C414.358565,855.722257 414.407603,855.201339 414.416798,854.652844 L414.416798,854.652844 Z M437.38505,763.691468 C397.955298,757.219835 355.883615,781.083982 344.491435,820.324385 C338.545557,840.802569 342.205031,863.306202 353.042466,881.293174 C356.12574,888.132514 360.153,894.680752 365.148763,900.686624 C388.135405,928.292183 427.641779,938.391853 460.298134,922.896092 C490.174637,908.721009 509.446637,874.380294 508.193099,841.663615 C506.691305,802.493688 475.610302,769.966991 437.38505,763.691468 L437.38505,763.691468 Z" id="Fill-138" fill="#0A0A08"></path>
                    <path d="M451.244462,963.975028 C449.494412,960.916936 446.083195,957.475817 442.880389,955.940642 C438.316775,953.752789 432.980809,952.35244 427.932943,953.98567 C423.844385,955.309413 420.497531,957.518716 417.858664,960.935321 C417.141481,961.860716 416.519309,962.856587 415.989084,963.898422 C412.813863,967.20778 410.447771,971.381248 409.430229,975.946936 C407.447248,984.814789 410.901374,992.542752 418.229515,997.571138 C423.283511,1001.03983 430.522771,1001.70783 436.297015,1000.01639 C442.301126,998.254459 447.352057,994.084055 450.619225,988.807468 C453.065004,984.85156 454.585187,979.737376 454.435008,975.058312 C454.306282,971.111596 453.196794,967.388569 451.244462,963.975028" id="Fill-139" fill="#0A0A08"></path>
                    <path d="M516.235359,1119.94077 C509.23516,1084.13536 476.024061,1063.45494 441.433763,1062.05765 C441.148729,1062.04539 440.86063,1062.04233 440.57866,1062.03314 C409.684615,1054.24389 372.694511,1061.92283 351.513088,1083.79217 C329.427523,1106.59303 323.929118,1142.73857 341.031179,1169.9182 C354.292939,1190.99697 379.504687,1202.32846 403.619206,1204.72161 C452.881111,1209.60905 528.418279,1182.22411 516.235359,1119.94077" id="Fill-140" fill="#149D8F"></path>
                    <path d="M292.376122,1123.19804 C280.12884,1094.26261 232.123538,1098.36253 217.908599,1123.19804 C209.060275,1138.66009 208.294053,1158.15772 206.884206,1174.7658 C205.642927,1189.43116 204.450687,1204.1149 203.641557,1218.79558 C201.962,1249.18039 201.379672,1279.62648 200.88929,1310.03273 C199.890137,1372.05868 201.719874,1433.79352 207.776088,1495.66932 C211.699141,1535.74013 292.936996,1536.75439 293.448832,1495.66932 C294.211989,1433.78739 294.052615,1371.9116 294.7085,1310.03273 C295.033378,1279.60809 296.115282,1249.20796 296.958126,1218.79558 C297.797905,1188.38013 304.776649,1152.48585 292.376122,1123.19804" id="Fill-141" fill="#149D8F"></path>
                    <path d="M616.58584,1108.81152 C605.009767,1092.97563 569.950542,1092.41488 558.585947,1108.81152 C538.336248,1138.0258 544.392462,1177.08541 543.307492,1209.08813 C542.17655,1242.52796 541.208046,1275.97086 539.923859,1309.40457 C537.355485,1376.21683 533.291447,1442.99844 530.235756,1509.8015 C529.487924,1526.20734 549.719233,1540.16793 571.464595,1540.16793 C595.781397,1540.16793 610.241527,1526.1767 612.69037,1509.8015 C622.654313,1443.12714 627.935111,1376.43745 630.675118,1309.40457 C632.045122,1275.95554 632.746981,1242.55248 632.302573,1209.08813 C631.885748,1177.30297 637.617084,1137.59068 616.58584,1108.81152" id="Fill-142" fill="#149D8F"></path>
                    <path d="M497.429221,1353.71319 C497.922668,1326.95642 498.462088,1300.19659 498.860523,1273.43982 C499.593031,1239.10523 458.220141,1221.17954 416.534634,1220.45639 C374.852191,1219.63824 332.85713,1236.02262 332.339164,1270.41543 C331.769095,1297.16301 331.336947,1323.91365 330.85576,1350.66123 C327.337271,1438.9935 327.708122,1527.48512 326.377962,1615.85417 C324.925206,1684.17402 490.821328,1687.2842 492.274084,1618.86323 C494.149794,1530.51563 497.73571,1442.10675 497.429221,1353.71319" id="Fill-143" fill="#3A3A39"></path>
                    <path d="M242.341866,742.401266 C243.288916,739.998917 243.408447,737.360624 242.326542,734.759101 C235.942385,719.401229 221.479191,713.986752 204.09516,717.250147 C184.507477,720.927211 171.46026,732.942018 157.977828,745.535963 C156.558786,746.859706 155.185718,748.247798 153.785065,749.599119 C144.795756,756.386367 136.364256,764.049982 128.778664,771.572642 C104.719313,795.439853 84.2612023,822.150661 68.6486756,851.294459 C53.4039351,879.758 41.6531641,909.949761 35.0697901,941.088367 C31.0854389,959.933321 24.7012824,985.574716 27.0520496,1007.97417 C25.5717099,1018.71119 24.419313,1029.50031 23.6898702,1040.34152 C22.0256374,1065.15251 62.4238931,1082.34279 80.4209008,1061.79413 C156.068405,975.432147 172.364401,861.587174 242.617706,772.654312 C249.624034,763.783394 248.303069,751.695046 242.341866,742.401266" id="Fill-144" fill="#149D8F"></path>
                    <g id="Fill-120-+-Fill-121-+-Fill-145" transform="translate(871.581846, 809.562684) rotate(-62.000000) translate(-871.581846, -809.562684) translate(771.581846, 590.062684)">
                        <path d="M167.722321,306.256789 C158.607351,299.104899 142.758828,308.174991 150.243279,319.742422 C160.087691,334.965468 162.888996,357.741817 159.404221,375.27222 C155.775397,393.522716 137.934698,408.696734 142.084553,427.652 C143.132744,432.447505 147.451168,436.323743 152.048496,437.610716 C182.642183,446.190532 197.497683,401.091339 199.456145,378.504972 C202.01226,349.039431 190.433122,324.084422 167.722321,306.256789" id="Fill-120" fill="#0A0A08"></path>
                        <path d="M114.255393,294.79967 C116.498889,288.35255 110.71545,283.020807 104.882973,282.49989 C81.8779427,280.452991 64.9597748,307.310881 60.2245267,326.771743 C55.023416,348.13855 56.5466641,378.43756 78.5280229,389.811945 C86.9717824,394.178459 97.6069351,388.837523 97.6345191,378.848165 C97.6774275,363.44433 91.2105191,349.606312 94.9312901,334.104422 C98.5294656,319.102 109.327057,308.956367 114.255393,294.79967" id="Fill-121" fill="#0A0A08"></path>
                        <path d="M163.425351,155.748422 C154.052931,125.115413 138.434275,95.447633 119.664916,69.5641651 C101.759855,44.8726789 71.834313,4.51077064 39.4047595,0.573247706 C27.2984618,-0.897577982 15.0174656,4.99185321 8.38811832,14.4265872 C4.17390076,17.8738349 1.25919466,23.3495963 1.01400382,30.4156881 C0.115992366,56.244 12.4307023,82.5595229 20.3534313,106.727028 C28.9994733,133.106899 38.1358969,159.281468 47.3857214,185.456037 C65.5053244,236.72044 83.5023321,288.030807 101.830347,339.224734 C108.435176,357.674404 131.023382,363.496422 146.712531,353.071945 C174.802206,334.410844 178.697676,301.081321 178.648637,270.077541 C178.58734,231.728826 174.682676,192.540514 163.425351,155.748422" id="Fill-145" fill="#149D8F"></path>
                    </g>
                    <path d="M616.43566,291.783174 C615.11776,281.855101 600.464542,281.079853 600.345011,291.783174 C599.686061,350.78167 603.020656,410.769908 607.538298,469.596807 C608.67537,484.354092 631.487313,484.69422 630.813038,469.596807 C628.186431,410.503321 624.205145,350.420092 616.43566,291.783174" id="Fill-146" fill="#515251"></path>
                    <g id="eyes" transform="translate(184.958015, 231.880734)">
                        <ellipse id="Oval-11" fill="#4A4A4A" cx="269.474163" cy="113.376147" rx="119.766295" ry="113.376147"></ellipse>
                        <ellipse id="Oval-11" fill="#4A4A4A" cx="106.666856" cy="124.527899" rx="106.666856" ry="102.224395"></ellipse>
                        <g id="Group" transform="translate(190.877532, 29.738006)">
                            <ellipse id="Oval-13" stroke="#979797" fill="#FFFFFF" cx="86.0820244" cy="85.4967664" rx="86.0820244" ry="85.4967664"></ellipse>
                            <ellipse id="Oval-15" fill="#7ED321" cx="83.5016822" cy="94.4324793" rx="59.3552518" ry="57.7424719"></ellipse>
                            <ellipse id="Oval-16" fill="#417505" cx="89.1545633" cy="94.4324793" rx="31.0908462" ry="30.2460567"></ellipse>
                            <ellipse id="Oval-17" fill="#000000" cx="89.1545633" cy="94.4324793" rx="19.7850839" ry="19.2474906"></ellipse>
                            <ellipse id="Oval-18" fill="#FFFFFF" cx="128.724731" cy="55.937498" rx="25.4379651" ry="24.7467737"></ellipse>
                            <ellipse id="Oval-19" fill="#FFFFFF" cx="87.6515423" cy="86.3940338" rx="4.34635747" ry="4.35815601"></ellipse>
                        </g>
                        <g id="Group" transform="translate(29.941574, 59.476011)">
                            <ellipse id="Oval-13" stroke="#979797" fill="#FFFFFF" cx="71.1112375" cy="70.6277636" rx="71.1112375" ry="70.6277636"></ellipse>
                            <ellipse id="Oval-15" fill="#7ED321" cx="67.5377468" cy="73.766834" rx="51.1114668" ry="49.2509319"></ellipse>
                            <ellipse id="Oval-16" fill="#417505" cx="72.4055056" cy="73.766834" rx="26.7726731" ry="25.7981072"></ellipse>
                            <ellipse id="Oval-17" fill="#000000" cx="72.4055056" cy="73.766834" rx="17.0371556" ry="16.4169773"></ellipse>
                            <ellipse id="Oval-18" fill="#FFFFFF" cx="106.479817" cy="40.9328794" rx="21.9049144" ry="21.1075422"></ellipse>
                            <ellipse id="Oval-19" fill="#FFFFFF" cx="71.1112375" cy="66.9105129" rx="3.74269671" ry="3.71725071"></ellipse>
                        </g>
                    </g>
                    <path d="M533.226242,504.01724 C530.70279,485.493012 506.882729,461.309819 505.807455,480.061707 C503.70278,516.793925 482.260183,538.925825 456.294959,541.128566 C443.677076,542.203622 429.22442,538.695177 413.924368,531.522584 C396.259844,523.241843 376.877969,502.37947 361.202484,502.13874 C347.266179,501.929025 348.296787,523.501827 359.263589,545.468583 C376.891675,580.767645 408.937638,599.277219 430.556063,609.766383 C454.106446,621.191729 474.604036,621.847562 492.170029,616.752455 C527.655217,606.460837 541.79878,566.950434 533.226242,504.01724" id="Fill-209" fill="#000000"></path>
                </g>
            </g>
        </g>
    </svg>
    </div>
}
