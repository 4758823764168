import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LanguageMenu from "./languageMenu"

import { useTranslation } from "react-i18next"

import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap"
import Logo from "./Logo/Logo"
import Neon from "./Neon/Neon"
import NavStep1Svg from "./rubiks_components_steps/navStep1Svg"
import NavStep2Svg from "./rubiks_components_steps/navStep2Svg"
import NavStep3Svg from "./rubiks_components_steps/navStep3Svg"
import NavStep4Svg from "./rubiks_components_steps/navStep4Svg"
import NavStep5Svg from "./rubiks_components_steps/navStep5Svg"
import NavStep6Svg from "./rubiks_components_steps/navStep6Svg"
import NavStep7Svg from "./rubiks_components_steps/navStep7Svg"
import NavStep8Svg from "./rubiks_components_steps/navStep8Svg"
import AllSchemesSvg2x2 from "./rubiks_components_steps/AllSchemesSvg2x2"
import Scheme1btn from "../components/PageStep1/Scheme1btn"
import Scheme2btn from "../components/PageStep2/Scheme2btn"
import Scheme3btn from "../components/PageStep3/Scheme3btn"
import Scheme4btn from "../components/PageStep4/Scheme4btn"
import Scheme5btn from "../components/PageStep5/Scheme5btn"
import Scheme6btn from "../components/PageStep6/Scheme6btn"
import Scheme7btn from "../components/PageStep7/Scheme7btn"
import Scheme8btn from "../components/PageStep8/Scheme8btn"
// import SchemeBtn from "../components/Cube2x2/Cube2x2Lesson/SchemeBtn"
import PyramidSvg from "./rubiks_components_steps/pyramidSvg"
// import SchemePyramidBtn from "./Pyramid/SchemePyramidBtn"
// import ModalBtnHelpMail from "../components/ModalBtnHelpMail"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation();

  return ( 
  <header > {/* menu React-Bootstrap START  */}
    <div className="container-fluid bg-black">
      <Navbar collapseOnSelect expand="xs" bg="dark" variant="dark" className="container">
        <Link to="/">
          <Logo />
          <Neon />
        </Link>
        <Navbar.Brand style={{ padding: '0 30px'}}>
          <LanguageMenu />
        </Navbar.Brand>
        {/* {!hideLangs && <Langs />} */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto mt-4"  >
            
          {/* CUBE 3x3 START  */}
          
            <NavDropdown title={t('nav.cube3')} id="collasible-nav-dropdown" className="mt-2"> 
              <Nav.Link href="#">
                <Link to="/home3x3/">
                  <Button variant="success" style={{ display: 'block', margin: '2rem', padding: "1rem", textAlign: 'center', letterSpacing: '3px'}}>
                    {t('nav.cube3start')}
                  </Button>
                </Link>
              </Nav.Link>
            {/* Nav STEP 1 START */}
              <NavDropdown.Item href="#">
                <Link to="/step-1/">
                  <p style={{ color: 'black' }}>
                    1. {t('nav.lesson1')}
                  </p> 
                  <NavStep1Svg />
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <Scheme1btn />
              </NavDropdown.Item>
              {/* Nav STEP 1 END */}
              <NavDropdown.Divider />

              {/* Nav STEP 2 START */}
              <NavDropdown.Item href="#">
                <Link to="/step-2/">
                  <p style={{ color: 'black' }}>
                    2. {t('nav.lesson2')}
                  </p>
                  <NavStep2Svg />
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <Scheme2btn />
              </NavDropdown.Item>
            {/* Nav STEP 2 END  */}
              <NavDropdown.Divider />

            {/* Nav STEP 3 START */}
            <NavDropdown.Item href="#">
              <Link to="/step-3/">
                <p style={{ color: 'black' }}>
                  3. {t('nav.lesson3')}
                </p>
                <NavStep3Svg />
              </Link>
            </NavDropdown.Item>
            
            <NavDropdown.Divider />
            <NavDropdown.Item href="#">
              <Scheme3btn />
            </NavDropdown.Item>
            {/* Nav STEP 3 END  */}
            <NavDropdown.Divider />

            {/* Nav STEP 4 START */}
            <NavDropdown.Item href="#">
                <Link to="/step-4/">
                  <p style={{ color: 'black' }}>
                    4. {t('nav.lesson4')}
                  </p>
                  <NavStep4Svg />
                </Link>
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">
                <Scheme4btn />
              </NavDropdown.Item>
            {/* Nav STEP 4 END  */}
            <NavDropdown.Divider />

            {/* Nav STEP 5 START */}
            <NavDropdown.Item href="#">
                <Link to="/step-5/">
                  <p style={{ color: 'black' }}>
                    5. {t('nav.lesson5')}
                  </p>
                  <NavStep5Svg />
                </Link>
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">
                <Scheme5btn />
              </NavDropdown.Item>
            {/* Nav STEP 5 END  */}
            <NavDropdown.Divider />

            {/* Nav STEP 6 START */}
            <NavDropdown.Item href="#">
                <Link to="/step-6/">
                  <p style={{ color: 'black' }}>
                    6. {t('nav.lesson6')}
                  </p> 
                  <NavStep6Svg />
                </Link>
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">
                <Scheme6btn />
              </NavDropdown.Item>
            {/* Nav STEP 6 END  */}
            <NavDropdown.Divider />

            {/* Nav STEP 7 START */}
            <NavDropdown.Item href="#">
                <Link to="/step-7/">
                  <p style={{ color: 'black' }}>
                    7. {t('nav.lesson7short')}
                  </p>
                  <NavStep7Svg />
                </Link>
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">
                <Scheme7btn />
              </NavDropdown.Item>
            {/* Nav STEP 7 END  */}
            <NavDropdown.Divider />

            {/* Nav STEP 8 START */}
            <NavDropdown.Item href="#">
                <Link to="/step-8/">
                  <p style={{ color: 'black' }}>
                    8. {t('nav.lesson8')}
                  </p>
                  <NavStep8Svg />
                </Link>
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">
                <Scheme8btn />
              </NavDropdown.Item>
            {/* Nav STEP 8 END  */}


            </NavDropdown>
          {/* CUBE 3x3 START  */}

          {/* CUBE 2x2 START  */}
            <Nav.Link href="#" className="mt-2">
              <Link to="/page-cube2x2/">
                  <span style={{ color: 'white' }}>
                    {t('nav.cube2')}
                  </span>
                  <div style={{width: '200px'}}>
                    <AllSchemesSvg2x2 />
                  </div>
              </Link>
            </Nav.Link>
            {/* <NavDropdown title={t('nav.cube2')} > 
              <NavDropdown.Item href="#">
                <Link to="/page-cube2x2/" style={{ width: '200px' }}>
                  <p style={{ color: 'black' }}>
                    {t('nav.cube2lesson')}
                  </p> 
                  <div style={{width: '200px'}}>
                    <AllSchemesSvg2x2 />
                  </div>
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <SchemeBtn />
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* CUBE 2x2 END  */}

             {/* CUBE PYRAMID START  */}
             <Nav.Link href="#" className="mt-2">
                <Link to="/page-pyramid/">
                  <span style={{ color: 'white' }}>
                    {t('nav.pyramid')}
                  </span>
                  <div style={{width: '200px'}}>
                    <PyramidSvg />
                  </div>
                </Link>
              </Nav.Link>
             {/* <NavDropdown title={t('nav.pyramid')} >
              <NavDropdown.Item href="#">
                <Link to="/page-pyramid/">
                  <p style={{ color: 'black' }}>
                    {t('nav.pyramidlesson')}
                  </p>
                  <div style={{width: '200px'}}>
                    <PyramidSvg />
                  </div>
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <SchemePyramidBtn />
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* CUBE PYRAMID END  */}

            {/* Nav more-lessons START  */}
            <Nav.Link href="#" className="mt-2">
              <Link to="/more-lessons/">
                  <span style={{ color: 'white' }}>
                    {t('nav.more-lessons')}
                  </span>
              </Link>
            </Nav.Link>
            {/* Nav more-lessonst END  */}

            {/* Nav page-students START  */}
            <Nav.Link href="#">
              <Link to="/page-students/">
                  <span style={{ color: 'white' }}>
                    <span role="img" aria-label="viva">🏆</span>
                    {t('nav.page-students')}
                  </span>
              </Link>
            </Nav.Link>
            {/* Nav page-students END  */}
            
            <Nav.Link href="#">
              <Link to="/goods_amazon/">
                <svg id="Icon" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24">
                  <defs>
                    <clipPath id="clip-path">
                      <path class="header-svg" id="Mask" d="M4.5,18.98a1.988,1.988,0,0,1-1.92-1.46L.04,8.25A.85.85,0,0,1,0,7.98a1,1,0,0,1,1-1H5.79L10.17.43A.978.978,0,0,1,11,0a.992.992,0,0,1,.83.42l4.38,6.56H21a1,1,0,0,1,1,1l-.03.27-2.54,9.27A2.01,2.01,0,0,1,17.5,18.98Z" transform="translate(1 2)" fill="#3cfd01"></path>
                    </clipPath>
                  </defs>
                  <g id="Icon_Shop" data-name="Icon/Shop">
                    <rect id="Base" width="24" height="24" fill="none"></rect>
                    <g id="Mask_Group_16" data-name="Mask Group 16" clip-path="url(#clip-path)">
                      <g id="Symbols" transform="translate(1 2)">
                        <g id="Icon_Shop-2" data-name="Icon/Shop" transform="translate(-1 -2)">
                          <g id="Mask-2" data-name="Mask">
                            <path class="header-svg" id="Path_9" data-name="Path 9" d="M17.21,8.98,12.83,2.42A.993.993,0,0,0,12,2a.977.977,0,0,0-.83.43L6.79,8.98H2a1,1,0,0,0-1,1,.842.842,0,0,0,.04.27l2.54,9.27A1.989,1.989,0,0,0,5.5,20.98h13a2.01,2.01,0,0,0,1.93-1.46l2.54-9.27L23,9.98a1,1,0,0,0-1-1ZM9,8.98l3-4.4,3,4.4Z" fill="#3cfd01"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span style={{ color: 'white' }}>&nbsp;&nbsp;{t('nav.shop')}</span>
              </Link>
            </Nav.Link>

            <Nav.Link href="#">
              <Link to="/page-form/">
                  <span role="img" aria-label="viva" style={{ fontSize: '2rem' }}>💌</span>
              </Link>
            </Nav.Link>

          </Nav>
          
          {/* <Nav>
            <Nav.Link href="#">{t('nav.start')}</Nav.Link>
            <Nav.Link eventKey={2} href="#">
              t{('nav.scheme')}
            
            </Nav.Link>
          </Nav> */}
          
        </Navbar.Collapse>
        
      </Navbar>
    </div>
    
    
      {/* GOOGLE adsense */}
    {/* <script data-ad-client="ca-pub-9653622612873701" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script> */}

  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
