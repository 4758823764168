import React from 'react';

const SchemeStep1SvgRu = () => {
    return <div style={{ width: '100%'}}>
        <svg viewBox="0 0 2950 6876" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <g id="RUS" transform="translate(-5188.000000, -12184.000000)">
                    <g id="Group" transform="translate(0.000000, 11242.000000)">
                        <g id="1_daisy" transform="translate(4877.000000, 0.000000)">
                            <g id="d_1">
                                <g id="1d" transform="translate(715.000000, 0.000000)">
                                    <g id="1flower-+-1flower" transform="translate(0.000000, 944.000000)">
                                        <g>
                                            <g>
                                                <g id="1flower" transform="translate(968.000000, 0.000000)" stroke="#090909" strokeWidth="4" >
                                                    <g id="Group" transform="translate(392.864200, 496.947767) rotate(-302.000000) translate(-392.864200, -496.947767) translate(30.864200, 260.447767)" fill="#807C7C">
                                                        <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                                                        <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                                                        <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                                                        <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                                                        <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                                                        <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                                                        <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                                                        <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                                                        <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                                                    </g>
                                                    <g id="Group" transform="translate(794.232769, 494.379537) scale(-1, 1) rotate(-303.000000) translate(-794.232769, -494.379537) translate(424.732769, 265.879537)" fill="#807C7C">
                                                        <path d="M112.480405,155.612439 C116.887244,152.880065 124.019088,152.87197 128.438982,155.612439 L237.009448,222.929426 C241.416288,225.6618 241.429343,230.08376 237.009448,232.824228 L128.438982,300.141215 C124.032142,302.87359 116.900299,302.881684 112.480405,300.141215 L3.909938,232.824228 C-0.496901376,230.091854 -0.509956175,225.669895 3.909938,222.929426 L112.480405,155.612439 L112.480405,155.612439 Z" id="Rectangle-1"></path>
                                                        <path d="M237.713891,233.261004 C242.12073,230.52863 249.252574,230.520536 253.672468,233.261004 L362.242935,300.577991 C366.649774,303.310365 366.662829,307.732325 362.242935,310.472794 L253.672468,377.789781 C249.265629,380.522155 242.133785,380.530249 237.713891,377.789781 L129.143424,310.472794 C124.736585,307.740419 124.72353,303.31846 129.143424,300.577991 L237.713891,233.261004 L237.713891,233.261004 Z" id="Rectangle-1"></path>
                                                        <path d="M361.695797,310.133552 C366.102637,307.401178 373.23448,307.393083 377.654375,310.133552 L486.224841,377.450539 C490.631681,380.182913 490.644735,384.604873 486.224841,387.345341 L377.654375,454.662328 C373.247535,457.394703 366.115692,457.402797 361.695797,454.662328 L253.125331,387.345341 C248.718491,384.612967 248.705437,380.191008 253.125331,377.450539 L361.695797,310.133552 L361.695797,310.133552 Z" id="Rectangle-1"></path>
                                                        <path d="M237.556586,78.3031153 C241.963425,75.5707411 249.095269,75.5626467 253.515163,78.3031153 L362.085629,145.620102 C366.492469,148.352477 366.505524,152.774436 362.085629,155.514905 L253.515163,222.831892 C249.108323,225.564266 241.97648,225.57236 237.556586,222.831892 L128.986119,155.514905 C124.57928,152.782531 124.566225,148.360571 128.986119,145.620102 L237.556586,78.3031153 L237.556586,78.3031153 Z" id="Rectangle-1"></path>
                                                        <path d="M362.947377,156.049215 C367.354217,153.316841 374.48606,153.308746 378.905954,156.049215 L487.476421,223.366202 C491.88326,226.098576 491.896315,230.520536 487.476421,233.261004 L378.905954,300.577991 C374.499115,303.310365 367.367272,303.31846 362.947377,300.577991 L254.376911,233.261004 C249.970071,230.52863 249.957017,226.10667 254.376911,223.366202 L362.947377,156.049215 L362.947377,156.049215 Z" id="Rectangle-1"></path>
                                                        <path d="M486.929284,232.921763 C491.336123,230.189388 498.467967,230.181294 502.887861,232.921763 L611.458328,300.238749 C615.865167,302.971124 615.878222,307.393083 611.458328,310.133552 L502.887861,377.450539 C498.481022,380.182913 491.349178,380.191008 486.929284,377.450539 L378.358817,310.133552 C373.951978,307.401178 373.938923,302.979218 378.358817,300.238749 L486.929284,232.921763 L486.929284,232.921763 Z" id="Rectangle-1"></path>
                                                        <path d="M363.265057,2.05231721 C367.671896,-0.680057055 374.80374,-0.688151427 379.223634,2.05231721 L487.7941,69.3693042 C492.20094,72.1016784 492.213995,76.5236381 487.7941,79.2641067 L379.223634,146.581094 C374.816794,149.313468 367.684951,149.321562 363.265057,146.581094 L254.69459,79.2641067 C250.287751,76.5317324 250.274696,72.1097728 254.69459,69.3693042 L363.265057,2.05231721 L363.265057,2.05231721 Z" id="Rectangle-1"></path>
                                                        <path d="M486.638627,77.8055662 C491.045467,75.0731919 498.17731,75.0650976 502.597204,77.8055662 L611.167671,145.122553 C615.57451,147.854927 615.587565,152.276887 611.167671,155.017356 L502.597204,222.334343 C498.190365,225.066717 491.058521,225.074811 486.638627,222.334343 L378.068161,155.017356 C373.661321,152.284981 373.648266,147.863022 378.068161,145.122553 L486.638627,77.8055662 L486.638627,77.8055662 Z" id="Rectangle-1"></path>
                                                        <path d="M610.620534,154.678114 C615.027373,151.94574 622.159217,151.937645 626.579111,154.678114 L735.149577,221.995101 C739.556417,224.727475 739.569472,229.149435 735.149577,231.889903 L626.579111,299.20689 C622.172271,301.939265 615.040428,301.947359 610.620534,299.20689 L502.050067,231.889903 C497.643228,229.157529 497.630173,224.73557 502.050067,221.995101 L610.620534,154.678114 L610.620534,154.678114 Z" id="Rectangle-1"></path>
                                                    </g>
                                                    <g id="Group" transform="translate(204.007864, 0.000000)">
                                                        <path d="M118.851844,126.037472 C123.508308,123.824399 131.044134,123.817843 135.714392,126.037472 L250.43482,180.560509 C255.091284,182.773581 255.105079,186.355124 250.43482,188.574752 L135.714392,243.097789 C131.057928,245.310861 123.522102,245.317417 118.851844,243.097789 L4.13141597,188.574752 C-0.525048295,186.36168 -0.538842581,182.780137 4.13141597,180.560509 L118.851844,126.037472 L118.851844,126.037472 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M268.041715,188.928516 L382.762142,243.451554 C387.418607,245.664626 387.432401,249.246168 382.762142,251.465797 L268.041715,305.988834 C263.38525,308.201906 255.849424,308.208462 251.179166,305.988834 L136.458738,251.465797 C131.802274,249.252724 131.78848,245.671182 136.458738,243.451554 L251.179166,188.928516 C255.83563,186.715444 263.371456,186.708888 268.041715,188.928516 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M382.184013,251.19103 C386.840477,248.977957 394.376303,248.971401 399.046561,251.19103 L513.766989,305.714067 C518.423453,307.927139 518.437248,311.508682 513.766989,313.72831 L399.046561,368.251347 C394.390097,370.464419 386.854271,370.470975 382.184013,368.251347 L267.463585,313.72831 C262.807121,311.515238 262.793326,307.933695 267.463585,305.714067 L382.184013,251.19103 L382.184013,251.19103 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M251.01295,63.421194 C255.669414,61.2081219 263.20524,61.2015659 267.875499,63.421194 L382.595926,117.944231 C387.252391,120.157303 387.266185,123.738846 382.595926,125.958474 L267.875499,180.481512 C263.219035,182.694584 255.683209,182.70114 251.01295,180.481512 L136.292522,125.958474 C131.636058,123.745402 131.622264,120.163859 136.292522,117.944231 L251.01295,63.421194 L251.01295,63.421194 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M383.506488,126.391236 C388.162952,124.178164 395.698778,124.171608 400.369037,126.391236 L515.089465,180.914273 C519.745929,183.127345 519.759723,186.708888 515.089465,188.928516 L400.369037,243.451554 C395.712573,245.664626 388.176747,245.671182 383.506488,243.451554 L268.78606,188.928516 C264.129596,186.715444 264.115802,183.133901 268.78606,180.914273 L383.506488,126.391236 L383.506488,126.391236 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M531.373884,188.653749 L646.094311,243.176787 C650.750776,245.389859 650.76457,248.971401 646.094311,251.19103 L531.373884,305.714067 C526.717419,307.927139 519.181593,307.933695 514.511335,305.714067 L399.790907,251.19103 C395.134443,248.977957 395.120649,245.396415 399.790907,243.176787 L514.511335,188.653749 C519.167799,186.440677 526.703625,186.434121 531.373884,188.653749 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M383.842162,1.66226347 C388.498627,-0.550808613 396.034453,-0.557364607 400.704711,1.66226347 L515.425139,56.1853009 C520.081603,58.398373 520.095397,61.9799158 515.425139,64.1995438 L400.704711,118.722581 C396.048247,120.935653 388.512421,120.942209 383.842162,118.722581 L269.121735,64.1995438 C264.46527,61.9864718 264.451476,58.404929 269.121735,56.1853009 L383.842162,1.66226347 L383.842162,1.66226347 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M514.204214,63.0182067 C518.860678,60.8051346 526.396504,60.7985786 531.066763,63.0182067 L645.78719,117.541244 C650.443655,119.754316 650.457449,123.335859 645.78719,125.555487 L531.066763,180.078524 C526.410298,182.291597 518.874472,182.298153 514.204214,180.078524 L399.483786,125.555487 C394.827322,123.342415 394.813528,119.760872 399.483786,117.541244 L514.204214,63.0182067 L514.204214,63.0182067 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M645.209061,125.28072 C649.865525,123.067648 657.401351,123.061092 662.071609,125.28072 L776.792037,179.803757 C781.448501,182.016829 781.462296,185.598372 776.792037,187.818 L662.071609,242.341038 C657.415145,244.55411 649.879319,244.560666 645.209061,242.341038 L530.488633,187.818 C525.832169,185.604928 525.818374,182.023385 530.488633,179.803757 L645.209061,125.28072 L645.209061,125.28072 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                </g>
                                                <g id="1flower" stroke="#090909" strokeWidth="4" fill="#807C7C" >
                                                    <g id="Group" transform="translate(205.007864, 0.000000)">
                                                        <path d="M118.851844,126.037472 C123.508308,123.824399 131.044134,123.817843 135.714392,126.037472 L250.43482,180.560509 C255.091284,182.773581 255.105079,186.355124 250.43482,188.574752 L135.714392,243.097789 C131.057928,245.310861 123.522102,245.317417 118.851844,243.097789 L4.13141597,188.574752 C-0.525048295,186.36168 -0.538842581,182.780137 4.13141597,180.560509 L118.851844,126.037472 L118.851844,126.037472 Z" id="Rectangle-1"></path>
                                                        <path d="M268.041715,188.928516 L382.762142,243.451554 C387.418607,245.664626 387.432401,249.246168 382.762142,251.465797 L268.041715,305.988834 C263.38525,308.201906 255.849424,308.208462 251.179166,305.988834 L136.458738,251.465797 C131.802274,249.252724 131.78848,245.671182 136.458738,243.451554 L251.179166,188.928516 C255.83563,186.715444 263.371456,186.708888 268.041715,188.928516 Z" id="Rectangle-1"></path>
                                                        <path d="M382.184013,251.19103 C386.840477,248.977957 394.376303,248.971401 399.046561,251.19103 L513.766989,305.714067 C518.423453,307.927139 518.437248,311.508682 513.766989,313.72831 L399.046561,368.251347 C394.390097,370.464419 386.854271,370.470975 382.184013,368.251347 L267.463585,313.72831 C262.807121,311.515238 262.793326,307.933695 267.463585,305.714067 L382.184013,251.19103 L382.184013,251.19103 Z" id="Rectangle-1"></path>
                                                        <path d="M251.01295,63.421194 C255.669414,61.2081219 263.20524,61.2015659 267.875499,63.421194 L382.595926,117.944231 C387.252391,120.157303 387.266185,123.738846 382.595926,125.958474 L267.875499,180.481512 C263.219035,182.694584 255.683209,182.70114 251.01295,180.481512 L136.292522,125.958474 C131.636058,123.745402 131.622264,120.163859 136.292522,117.944231 L251.01295,63.421194 L251.01295,63.421194 Z" id="Rectangle-1"></path>
                                                        <path d="M383.506488,126.391236 C388.162952,124.178164 395.698778,124.171608 400.369037,126.391236 L515.089465,180.914273 C519.745929,183.127345 519.759723,186.708888 515.089465,188.928516 L400.369037,243.451554 C395.712573,245.664626 388.176747,245.671182 383.506488,243.451554 L268.78606,188.928516 C264.129596,186.715444 264.115802,183.133901 268.78606,180.914273 L383.506488,126.391236 L383.506488,126.391236 Z" id="Rectangle-1"></path>
                                                        <path d="M531.373884,188.653749 L646.094311,243.176787 C650.750776,245.389859 650.76457,248.971401 646.094311,251.19103 L531.373884,305.714067 C526.717419,307.927139 519.181593,307.933695 514.511335,305.714067 L399.790907,251.19103 C395.134443,248.977957 395.120649,245.396415 399.790907,243.176787 L514.511335,188.653749 C519.167799,186.440677 526.703625,186.434121 531.373884,188.653749 Z" id="Rectangle-1"></path>
                                                        <path d="M383.842162,1.66226347 C388.498627,-0.550808613 396.034453,-0.557364607 400.704711,1.66226347 L515.425139,56.1853009 C520.081603,58.398373 520.095397,61.9799158 515.425139,64.1995438 L400.704711,118.722581 C396.048247,120.935653 388.512421,120.942209 383.842162,118.722581 L269.121735,64.1995438 C264.46527,61.9864718 264.451476,58.404929 269.121735,56.1853009 L383.842162,1.66226347 L383.842162,1.66226347 Z" id="Rectangle-1"></path>
                                                        <path d="M514.204214,63.0182067 C518.860678,60.8051346 526.396504,60.7985786 531.066763,63.0182067 L645.78719,117.541244 C650.443655,119.754316 650.457449,123.335859 645.78719,125.555487 L531.066763,180.078524 C526.410298,182.291597 518.874472,182.298153 514.204214,180.078524 L399.483786,125.555487 C394.827322,123.342415 394.813528,119.760872 399.483786,117.541244 L514.204214,63.0182067 L514.204214,63.0182067 Z" id="Rectangle-1"></path>
                                                        <path d="M645.209061,125.28072 C649.865525,123.067648 657.401351,123.061092 662.071609,125.28072 L776.792037,179.803757 C781.448501,182.016829 781.462296,185.598372 776.792037,187.818 L662.071609,242.341038 C657.415145,244.55411 649.879319,244.560666 645.209061,242.341038 L530.488633,187.818 C525.832169,185.604928 525.818374,182.023385 530.488633,179.803757 L645.209061,125.28072 L645.209061,125.28072 Z" id="Rectangle-1"></path>
                                                    </g>
                                                    <g id="Group" transform="translate(392.864200, 496.947767) rotate(-302.000000) translate(-392.864200, -496.947767) translate(30.864200, 260.447767)">
                                                        <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                                                        <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                                                        <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                                                        <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                                                        <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                                                        <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                                                        <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                                                        <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                                                        <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                                                    </g>
                                                    <g id="Group" transform="translate(792.232769, 495.879537) scale(-1, 1) rotate(-303.000000) translate(-792.232769, -495.879537) translate(422.732769, 267.879537)">
                                                        <path d="M112.480405,155.27193 C116.887244,152.545535 124.019088,152.537458 128.438982,155.27193 L237.009448,222.441615 C241.416288,225.168011 241.429343,229.580294 237.009448,232.314766 L128.438982,299.484451 C124.032142,302.210847 116.900299,302.218923 112.480405,299.484451 L3.909938,232.314766 C-0.496901376,229.588371 -0.509956175,225.176087 3.909938,222.441615 L112.480405,155.27193 L112.480405,155.27193 Z" id="Rectangle-1"></path>
                                                        <path d="M237.713891,232.750586 C242.12073,230.024191 249.252574,230.016114 253.672468,232.750586 L362.242935,299.920271 C366.649774,302.646667 366.662829,307.05895 362.242935,309.793422 L253.672468,376.963107 C249.265629,379.689503 242.133785,379.697579 237.713891,376.963107 L129.143424,309.793422 C124.736585,307.067027 124.72353,302.654743 129.143424,299.920271 L237.713891,232.750586 L237.713891,232.750586 Z" id="Rectangle-1"></path>
                                                        <path d="M361.695797,309.454923 C366.102637,306.728527 373.23448,306.720451 377.654375,309.454923 L486.224841,376.624608 C490.631681,379.351003 490.644735,383.763287 486.224841,386.497759 L377.654375,453.667444 C373.247535,456.393839 366.115692,456.401916 361.695797,453.667444 L253.125331,386.497759 C248.718491,383.771363 248.705437,379.35908 253.125331,376.624608 L361.695797,309.454923 L361.695797,309.454923 Z" id="Rectangle-1"></path>
                                                        <path d="M237.556586,78.1317737 C241.963425,75.4053784 249.095269,75.3973017 253.515163,78.1317737 L362.085629,145.301459 C366.492469,148.027854 366.505524,152.440138 362.085629,155.17461 L253.515163,222.344295 C249.108323,225.07069 241.97648,225.078767 237.556586,222.344295 L128.986119,155.17461 C124.57928,152.448214 124.566225,148.035931 128.986119,145.301459 L237.556586,78.1317737 L237.556586,78.1317737 Z" id="Rectangle-1"></path>
                                                        <path d="M362.947377,155.70775 C367.354217,152.981355 374.48606,152.973278 378.905954,155.70775 L487.476421,222.877435 C491.88326,225.603831 491.896315,230.016114 487.476421,232.750586 L378.905954,299.920271 C374.499115,302.646667 367.367272,302.654743 362.947377,299.920271 L254.376911,232.750586 C249.970071,230.024191 249.957017,225.611907 254.376911,222.877435 L362.947377,155.70775 L362.947377,155.70775 Z" id="Rectangle-1"></path>
                                                        <path d="M486.929284,232.412087 C491.336123,229.685692 498.467967,229.677615 502.887861,232.412087 L611.458328,299.581772 C615.865167,302.308167 615.878222,306.720451 611.458328,309.454923 L502.887861,376.624608 C498.481022,379.351003 491.349178,379.35908 486.929284,376.624608 L378.358817,309.454923 C373.951978,306.728527 373.938923,302.316244 378.358817,299.581772 L486.929284,232.412087 L486.929284,232.412087 Z" id="Rectangle-1"></path>
                                                        <path d="M363.265057,2.04782636 C367.671896,-0.678568965 374.80374,-0.686645625 379.223634,2.04782636 L487.7941,69.2175114 C492.20094,71.9439067 492.213995,76.3561903 487.7941,79.0906623 L379.223634,146.260347 C374.816794,148.986743 367.684951,148.994819 363.265057,146.260347 L254.69459,79.0906623 C250.287751,76.3642669 250.274696,71.9519834 254.69459,69.2175114 L363.265057,2.04782636 L363.265057,2.04782636 Z" id="Rectangle-1"></path>
                                                        <path d="M486.638627,77.6353133 C491.045467,74.908918 498.17731,74.9008413 502.597204,77.6353133 L611.167671,144.804998 C615.57451,147.531394 615.587565,151.943677 611.167671,154.678149 L502.597204,221.847834 C498.190365,224.57423 491.058521,224.582306 486.638627,221.847834 L378.068161,154.678149 C373.661321,151.951754 373.648266,147.53947 378.068161,144.804998 L486.638627,77.6353133 L486.638627,77.6353133 Z" id="Rectangle-1"></path>
                                                        <path d="M610.620534,154.33965 C615.027373,151.613254 622.159217,151.605178 626.579111,154.33965 L735.149577,221.509335 C739.556417,224.23573 739.569472,228.648014 735.149577,231.382486 L626.579111,298.552171 C622.172271,301.278566 615.040428,301.286643 610.620534,298.552171 L502.050067,231.382486 C497.643228,228.65609 497.630173,224.243807 502.050067,221.509335 L610.620534,154.33965 L610.620534,154.33965 Z" id="Rectangle-1"></path>
                                                    </g>
                                                </g>
                                                <g id="Line-+-Triangle-43" transform="translate(1007.000000, 381.000000)">
                                                    <path d="M0.5,31.0007106 L124,31.0007106" id="Line" stroke="#000000" strokeWidth="14" strokeLinecap="square" ></path>
                                                    <path id="Line-decoration-1" d="M124,31.0007106 L113.2,28.0007106 L113.2,34.0007106 L124,31.0007106 Z" stroke="#000000" strokeWidth="14" strokeLinecap="square"></path>
                                                    <polygon id="Triangle-43" stroke="#979797" fill="#000000"  transform="translate(124.000000, 30.000000) rotate(-270.000000) translate(-124.000000, -30.000000) " points="124 0 154 60 94 60 "></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="1" transform="translate(0.000000, 1817.000000)">
                                    <g id="Group-+-1-+-2">
                                        <g id="Group" transform="translate(0.000000, 188.000000)">
                                            <g id="Group-+-Group-+-Group" transform="translate(575.000000, 8.000000)" stroke="#090909" strokeWidth="4" >
                                                <g id="Group" transform="translate(209.257453, 0.000000)">
                                                    <path d="M122.438112,127.568558 C127.235081,125.328602 134.998295,125.321966 139.809476,127.568558 L257.991509,182.753934 C262.788479,184.993891 262.802689,188.618942 257.991509,190.865533 L139.809476,246.05091 C135.012506,248.290866 127.249292,248.297501 122.438112,246.05091 L4.25607845,190.865533 C-0.540891245,188.625577 -0.555101765,185.000526 4.25607845,182.753934 L122.438112,127.568558 L122.438112,127.568558 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M276.129679,191.223595 L394.311712,246.408971 C399.108682,248.648927 399.122892,252.273978 394.311712,254.52057 L276.129679,309.705946 C271.332709,311.945903 263.569495,311.952538 258.758315,309.705946 L140.576281,254.52057 C135.779312,252.280614 135.765101,248.655563 140.576281,246.408971 L258.758315,191.223595 C263.555284,188.983639 271.318498,188.977003 276.129679,191.223595 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M393.716137,254.242465 C398.513107,252.002509 406.276321,251.995873 411.087501,254.242465 L529.269535,309.427841 C534.066504,311.667798 534.080715,315.292849 529.269535,317.53944 L411.087501,372.724817 C406.290532,374.964773 398.527318,374.971408 393.716137,372.724817 L275.534104,317.53944 C270.737134,315.299484 270.722924,311.674433 275.534104,309.427841 L393.716137,254.242465 L393.716137,254.242465 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M258.587083,64.1916263 C263.384053,61.9516702 271.147267,61.9450345 275.958447,64.1916263 L394.140481,119.377002 C398.93745,121.616959 398.951661,125.24201 394.140481,127.488601 L275.958447,182.673978 C271.161478,184.913934 263.398264,184.920569 258.587083,182.673978 L140.40505,127.488601 C135.60808,125.248645 135.59387,121.623594 140.40505,119.377002 L258.587083,64.1916263 L258.587083,64.1916263 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M395.078518,127.92662 C399.875487,125.686664 407.638701,125.680028 412.449882,127.92662 L530.631915,183.111996 C535.428885,185.351952 535.443095,188.977003 530.631915,191.223595 L412.449882,246.408971 C407.652912,248.648927 399.889698,248.655563 395.078518,246.408971 L276.896484,191.223595 C272.099515,188.983639 272.085304,185.358588 276.896484,183.111996 L395.078518,127.92662 L395.078518,127.92662 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                    <path d="M547.407704,190.94549 L665.589738,246.130866 C670.386707,248.370823 670.400918,251.995873 665.589738,254.242465 L547.407704,309.427841 C542.610735,311.667798 534.847521,311.674433 530.03634,309.427841 L411.854307,254.242465 C407.057337,252.002509 407.043127,248.377458 411.854307,246.130866 L530.03634,190.94549 C534.83331,188.705534 542.596524,188.698898 547.407704,190.94549 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M395.424321,1.68245643 C400.22129,-0.557499764 407.984504,-0.564135399 412.795685,1.68245643 L530.977718,56.8678326 C535.774688,59.1077888 535.788898,62.7328397 530.977718,64.9794315 L412.795685,120.164808 C407.998715,122.404764 400.235501,122.411399 395.424321,120.164808 L277.242287,64.9794315 C272.445318,62.7394753 272.431107,59.1144244 277.242287,56.8678326 L395.424321,1.68245643 L395.424321,1.68245643 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M529.719952,63.7837436 C534.516922,61.5437874 542.280136,61.5371518 547.091316,63.7837436 L665.27335,118.96912 C670.070319,121.209076 670.08453,124.834127 665.27335,127.080719 L547.091316,182.266095 C542.294347,184.506051 534.531133,184.512687 529.719952,182.266095 L411.537919,127.080719 C406.740949,124.840762 406.726739,121.215712 411.537919,118.96912 L529.719952,63.7837436 L529.719952,63.7837436 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M664.677775,126.802614 C669.474745,124.562658 677.237959,124.556022 682.049139,126.802614 L800.231172,181.98799 C805.028142,184.227946 805.042353,187.852997 800.231172,190.099589 L682.049139,245.284965 C677.252169,247.524921 669.488955,247.531557 664.677775,245.284965 L546.495742,190.099589 C541.698772,187.859633 541.684562,184.234582 546.495742,181.98799 L664.677775,126.802614 L664.677775,126.802614 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                </g>
                                                <g id="Group" transform="translate(406.114028, 508.025766) rotate(-302.000000) translate(-406.114028, -508.025766) translate(35.114028, 262.025766)">
                                                    <path d="M112.896397,167.59465 C117.319535,164.651882 124.477754,164.643165 128.913995,167.59465 L237.885994,240.095068 C242.309131,243.037837 242.322234,247.800289 237.885994,250.751774 L128.913995,323.252192 C124.490857,326.19496 117.332638,326.203678 112.896397,323.252192 L3.92439834,250.751774 C-0.498739094,247.809006 -0.511842174,243.046554 3.92439834,240.095068 L112.896397,167.59465 L112.896397,167.59465 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M238.593041,251.222182 C243.016179,248.279414 250.174399,248.270696 254.610639,251.222182 L363.582638,323.7226 C368.005775,326.665368 368.018878,331.42782 363.582638,334.379306 L254.610639,406.879724 C250.187502,409.822492 243.029282,409.83121 238.593041,406.879724 L129.621043,334.379306 C125.197905,331.436538 125.184802,326.674086 129.621043,323.7226 L238.593041,251.222182 L238.593041,251.222182 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                    <path d="M363.033477,334.013942 C367.456614,331.071174 374.614834,331.062457 379.051075,334.013942 L488.023073,406.51436 C492.446211,409.457129 492.459314,414.219581 488.023073,417.171066 L379.051075,489.671484 C374.627937,492.614252 367.469718,492.62297 363.033477,489.671484 L254.061478,417.171066 C249.638341,414.228298 249.625238,409.465846 254.061478,406.51436 L363.033477,334.013942 L363.033477,334.013942 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M238.435154,84.3324822 C242.858292,81.3897142 250.016511,81.3809965 254.452752,84.3324822 L363.424751,156.8329 C367.847888,159.775668 367.860991,164.53812 363.424751,167.489606 L254.452752,239.990024 C250.029614,242.932792 242.871395,242.94151 238.435154,239.990024 L129.463156,167.489606 C125.040018,164.546838 125.026915,159.784386 129.463156,156.8329 L238.435154,84.3324822 L238.435154,84.3324822 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M364.289686,168.065058 C368.712823,165.12229 375.871043,165.113572 380.307283,168.065058 L489.279282,240.565476 C493.70242,243.508244 493.715523,248.270696 489.279282,251.222182 L380.307283,323.7226 C375.884146,326.665368 368.725926,326.674086 364.289686,323.7226 L255.317687,251.222182 C250.89455,248.279414 250.881446,243.516962 255.317687,240.565476 L364.289686,168.065058 L364.289686,168.065058 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M488.730121,250.856818 C493.153259,247.91405 500.311478,247.905333 504.747719,250.856818 L613.719718,323.357237 C618.142855,326.300005 618.155958,331.062457 613.719718,334.013942 L504.747719,406.51436 C500.324581,409.457129 493.166362,409.465846 488.730121,406.51436 L379.758123,334.013942 C375.334985,331.071174 375.321882,326.308722 379.758123,323.357237 L488.730121,250.856818 L488.730121,250.856818 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M364.60854,2.21034634 C369.031677,-0.732421682 376.189897,-0.741139323 380.626138,2.21034634 L489.598136,74.7107645 C494.021274,77.6535325 494.034377,82.4159845 489.598136,85.3674702 L380.626138,157.867888 C376.203,160.810656 369.04478,160.819374 364.60854,157.867888 L255.636541,85.3674702 C251.213404,82.4247022 251.200301,77.6622502 255.636541,74.7107645 L364.60854,2.21034634 L364.60854,2.21034634 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    <path d="M488.43839,83.7966216 C492.861527,80.8538536 500.019747,80.8451359 504.455987,83.7966216 L613.427986,156.29704 C617.851124,159.239808 617.864227,164.00226 613.427986,166.953745 L504.455987,239.454164 C500.03285,242.396932 492.87463,242.405649 488.43839,239.454164 L379.466391,166.953745 C375.043254,164.010977 375.03015,159.248525 379.466391,156.29704 L488.43839,83.7966216 L488.43839,83.7966216 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                    <path d="M612.878825,166.588382 C617.301963,163.645614 624.460182,163.636896 628.896423,166.588382 L737.868422,239.0888 C742.291559,242.031568 742.304662,246.79402 737.868422,249.745506 L628.896423,322.245924 C624.473285,325.188692 617.315066,325.19741 612.878825,322.245924 L503.906827,249.745506 C499.483689,246.802738 499.470586,242.040286 503.906827,239.0888 L612.878825,166.588382 L612.878825,166.588382 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                </g>
                                                <g id="Group" transform="translate(816.319385, 505.369466) scale(-1, 1) rotate(-303.000000) translate(-816.319385, -505.369466) translate(437.819385, 267.369466)" fill="#807C7C">
                                                    <path d="M115.154325,162.119681 C119.665925,159.273047 126.967309,159.264614 131.492275,162.119681 L242.643713,232.251661 C247.155314,235.098294 247.168679,239.705167 242.643713,242.560234 L131.492275,312.692214 C126.980674,315.538847 119.67929,315.54728 115.154325,312.692214 L4.00288631,242.560234 C-0.508713875,239.7136 -0.522079017,235.106727 4.00288631,232.251661 L115.154325,162.119681 L115.154325,162.119681 Z" id="Rectangle-1"></path>
                                                    <path d="M243.364902,243.015274 C247.876502,240.16864 255.177886,240.160207 259.702852,243.015274 L370.854291,313.147254 C375.365891,315.993888 375.379256,320.600761 370.854291,323.455827 L259.702852,393.587807 C255.191252,396.434441 247.889868,396.442874 243.364902,393.587807 L132.213464,323.455827 C127.701863,320.609193 127.688498,316.002321 132.213464,313.147254 L243.364902,243.015274 L243.364902,243.015274 Z" id="Rectangle-1"></path>
                                                    <path d="M370.294147,323.1024 C374.805747,320.255766 382.107131,320.247333 386.632096,323.1024 L497.783535,393.234379 C502.295135,396.081013 502.3085,400.687886 497.783535,403.542953 L386.632096,473.674932 C382.120496,476.521566 374.819112,476.529999 370.294147,473.674932 L259.142708,403.542953 C254.631108,400.696319 254.617742,396.089446 259.142708,393.234379 L370.294147,323.1024 L370.294147,323.1024 Z" id="Rectangle-1"></path>
                                                    <path d="M243.203857,81.5775149 C247.715458,78.730881 255.016842,78.7224481 259.541807,81.5775149 L370.693246,151.709495 C375.204846,154.556129 375.218211,159.163001 370.693246,162.018068 L259.541807,232.150048 C255.030207,234.996682 247.728823,235.005115 243.203857,232.150048 L132.052419,162.018068 C127.540818,159.171434 127.527453,154.564561 132.052419,151.709495 L243.203857,81.5775149 L243.203857,81.5775149 Z" id="Rectangle-1"></path>
                                                    <path d="M371.575479,162.574721 C376.08708,159.728087 383.388464,159.719654 387.913429,162.574721 L499.064868,232.706701 C503.576468,235.553335 503.589833,240.160207 499.064868,243.015274 L387.913429,313.147254 C383.401829,315.993888 376.100445,316.002321 371.575479,313.147254 L260.424041,243.015274 C255.912441,240.16864 255.899075,235.561768 260.424041,232.706701 L371.575479,162.574721 L371.575479,162.574721 Z" id="Rectangle-1"></path>
                                                    <path d="M498.504724,242.661846 C503.016324,239.815213 510.317708,239.80678 514.842673,242.661846 L625.994112,312.793826 C630.505712,315.64046 630.519077,320.247333 625.994112,323.1024 L514.842673,393.234379 C510.331073,396.081013 503.029689,396.089446 498.504724,393.234379 L387.353285,323.1024 C382.841685,320.255766 382.82832,315.648893 387.353285,312.793826 L498.504724,242.661846 L498.504724,242.661846 Z" id="Rectangle-1"></path>
                                                    <path d="M371.900711,2.13813891 C376.412311,-0.708494985 383.713695,-0.716927839 388.23866,2.13813891 L499.390099,72.2701188 C503.901699,75.1167527 503.915064,79.7236252 499.390099,82.578692 L388.23866,152.710672 C383.72706,155.557306 376.425676,155.565739 371.900711,152.710672 L260.749272,82.578692 C256.237672,79.7320581 256.224307,75.1251855 260.749272,72.2701188 L371.900711,2.13813891 L371.900711,2.13813891 Z" id="Rectangle-1"></path>
                                                    <path d="M498.207158,81.0591598 C502.718758,78.2125259 510.020142,78.204093 514.545107,81.0591598 L625.696546,151.19114 C630.208146,154.037773 630.221511,158.644646 625.696546,161.499713 L514.545107,231.631693 C510.033507,234.478327 502.732123,234.486759 498.207158,231.631693 L387.055719,161.499713 C382.544119,158.653079 382.530753,154.046206 387.055719,151.19114 L498.207158,81.0591598 L498.207158,81.0591598 Z" id="Rectangle-1"></path>
                                                    <path d="M625.136402,161.146285 C629.648002,158.299651 636.949386,158.291218 641.474351,161.146285 L752.62579,231.278265 C757.13739,234.124899 757.150755,238.731771 752.62579,241.586838 L641.474351,311.718818 C636.962751,314.565452 629.661367,314.573885 625.136402,311.718818 L513.984963,241.586838 C509.473363,238.740204 509.459998,234.133332 513.984963,231.278265 L625.136402,161.146285 L625.136402,161.146285 Z" id="Rectangle-1"></path>
                                                </g>
                                            </g>
                                            <text id="1.-" fontFamily="Helvetica" fontSize="400" fontWeight="normal">
                                                <tspan x="306.418945" y="472" fill="#9012FE">1</tspan>
                                                <tspan x="528.879883" y="472" fontSize="166" fill="#000000">. </tspan>
                                            </text>
                                            <g id="3.2." transform="translate(1798.000000, 0.000000)" >
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" stroke="#090909" strokeWidth="4">
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)" stroke="#090909" strokeWidth="4">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                    <g id="down" transform="translate(97.000000, 445.018678) scale(-1, -1) rotate(-270.000000) translate(-97.000000, -445.018678) translate(-123.000000, 353.018678)">
                                                        <path d="M0.5,93.5094914 L340,93.5094914" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                                        <polygon id="Triangle-2" fill="#FF0000" transform="translate(394.000000, 91.509288) rotate(-270.000000) translate(-394.000000, -91.509288) " points="394 46.5047203 485.5 136.513856 302.5 136.513856 "></polygon>
                                                    </g>
                                                    <g id="down" transform="translate(478.000000, 445.018678) scale(-1, -1) rotate(-270.000000) translate(-478.000000, -445.018678) translate(258.000000, 353.018678)">
                                                        <path d="M0.5,93.5094914 L340,93.5094914" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                                        <polygon id="Triangle-2" fill="#FF0000" transform="translate(394.000000, 91.509288) rotate(-270.000000) translate(-394.000000, -91.509288) " points="394 46.5047203 485.5 136.513856 302.5 136.513856 "></polygon>
                                                    </g>
                                                    <g id="Group" transform="translate(1.000000, 733.753426)" stroke="#090909" strokeWidth="4">
                                                        <g transform="translate(282.500000, 78.000000) scale(1, -1) translate(-282.500000, -78.000000) " id="Rectangle-1">
                                                            <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" fill="#807C7C"></path>
                                                            <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" fill="#807C7C"></path>
                                                            <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" fill="#FFFFFF"></path>
                                                            <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" fill="#807C7C"></path>
                                                            <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" fill="#807C7C"></path>
                                                            <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" fill="#807C7C"></path>
                                                            <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" fill="#FFFFFF"></path>
                                                            <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" fill="#FFFFFF"></path>
                                                            <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" fill="#807C7C"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="3.2." transform="translate(2580.000000, 0.000000)" strokeWidth="4" stroke="#090909" >
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" fill="#807C7C">
                                                        <rect id="Rectangle-1" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <text id="1" fill="#D0011B"  fontFamily="Helvetica" fontSize="155" fontWeight="normal">
                                            <tspan x="1724" y="152">1</tspan>
                                        </text>
                                        <text id="2" fill="#D0011B"  fontFamily="Helvetica" fontSize="155" fontWeight="normal">
                                            <tspan x="2317" y="150">2</tspan>
                                        </text>
                                    </g>
                                </g>
                                <g id="2" transform="translate(289.000000, 3255.000000)">
                                    <g id="_">
                                        <g id="Group-+-Group-+-Group" transform="translate(509.000000, 0.000000)" stroke="#090909" strokeWidth="4" >
                                            <g id="Group" transform="translate(209.257453, 0.000000)">
                                                <path d="M122.438112,127.568558 C127.235081,125.328602 134.998295,125.321966 139.809476,127.568558 L257.991509,182.753934 C262.788479,184.993891 262.802689,188.618942 257.991509,190.865533 L139.809476,246.05091 C135.012506,248.290866 127.249292,248.297501 122.438112,246.05091 L4.25607845,190.865533 C-0.540891245,188.625577 -0.555101765,185.000526 4.25607845,182.753934 L122.438112,127.568558 L122.438112,127.568558 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M276.129679,191.223595 L394.311712,246.408971 C399.108682,248.648927 399.122892,252.273978 394.311712,254.52057 L276.129679,309.705946 C271.332709,311.945903 263.569495,311.952538 258.758315,309.705946 L140.576281,254.52057 C135.779312,252.280614 135.765101,248.655563 140.576281,246.408971 L258.758315,191.223595 C263.555284,188.983639 271.318498,188.977003 276.129679,191.223595 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M393.716137,254.242465 C398.513107,252.002509 406.276321,251.995873 411.087501,254.242465 L529.269535,309.427841 C534.066504,311.667798 534.080715,315.292849 529.269535,317.53944 L411.087501,372.724817 C406.290532,374.964773 398.527318,374.971408 393.716137,372.724817 L275.534104,317.53944 C270.737134,315.299484 270.722924,311.674433 275.534104,309.427841 L393.716137,254.242465 L393.716137,254.242465 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M258.587083,64.1916263 C263.384053,61.9516702 271.147267,61.9450345 275.958447,64.1916263 L394.140481,119.377002 C398.93745,121.616959 398.951661,125.24201 394.140481,127.488601 L275.958447,182.673978 C271.161478,184.913934 263.398264,184.920569 258.587083,182.673978 L140.40505,127.488601 C135.60808,125.248645 135.59387,121.623594 140.40505,119.377002 L258.587083,64.1916263 L258.587083,64.1916263 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M395.078518,127.92662 C399.875487,125.686664 407.638701,125.680028 412.449882,127.92662 L530.631915,183.111996 C535.428885,185.351952 535.443095,188.977003 530.631915,191.223595 L412.449882,246.408971 C407.652912,248.648927 399.889698,248.655563 395.078518,246.408971 L276.896484,191.223595 C272.099515,188.983639 272.085304,185.358588 276.896484,183.111996 L395.078518,127.92662 L395.078518,127.92662 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M547.407704,190.94549 L665.589738,246.130866 C670.386707,248.370823 670.400918,251.995873 665.589738,254.242465 L547.407704,309.427841 C542.610735,311.667798 534.847521,311.674433 530.03634,309.427841 L411.854307,254.242465 C407.057337,252.002509 407.043127,248.377458 411.854307,246.130866 L530.03634,190.94549 C534.83331,188.705534 542.596524,188.698898 547.407704,190.94549 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M395.424321,1.68245643 C400.22129,-0.557499764 407.984504,-0.564135399 412.795685,1.68245643 L530.977718,56.8678326 C535.774688,59.1077888 535.788898,62.7328397 530.977718,64.9794315 L412.795685,120.164808 C407.998715,122.404764 400.235501,122.411399 395.424321,120.164808 L277.242287,64.9794315 C272.445318,62.7394753 272.431107,59.1144244 277.242287,56.8678326 L395.424321,1.68245643 L395.424321,1.68245643 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M529.719952,63.7837436 C534.516922,61.5437874 542.280136,61.5371518 547.091316,63.7837436 L665.27335,118.96912 C670.070319,121.209076 670.08453,124.834127 665.27335,127.080719 L547.091316,182.266095 C542.294347,184.506051 534.531133,184.512687 529.719952,182.266095 L411.537919,127.080719 C406.740949,124.840762 406.726739,121.215712 411.537919,118.96912 L529.719952,63.7837436 L529.719952,63.7837436 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M664.677775,126.802614 C669.474745,124.562658 677.237959,124.556022 682.049139,126.802614 L800.231172,181.98799 C805.028142,184.227946 805.042353,187.852997 800.231172,190.099589 L682.049139,245.284965 C677.252169,247.524921 669.488955,247.531557 664.677775,245.284965 L546.495742,190.099589 C541.698772,187.859633 541.684562,184.234582 546.495742,181.98799 L664.677775,126.802614 L664.677775,126.802614 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            </g>
                                            <g id="Group" transform="translate(406.114028, 507.025766) scale(-1, -1) rotate(-302.000000) translate(-406.114028, -507.025766) translate(35.114028, 261.025766)">
                                                <path d="M112.896397,167.59465 C117.319535,164.651882 124.477754,164.643165 128.913995,167.59465 L237.885994,240.095068 C242.309131,243.037837 242.322234,247.800289 237.885994,250.751774 L128.913995,323.252192 C124.490857,326.19496 117.332638,326.203678 112.896397,323.252192 L3.92439834,250.751774 C-0.498739094,247.809006 -0.511842174,243.046554 3.92439834,240.095068 L112.896397,167.59465 L112.896397,167.59465 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M238.593041,251.222182 C243.016179,248.279414 250.174399,248.270696 254.610639,251.222182 L363.582638,323.7226 C368.005775,326.665368 368.018878,331.42782 363.582638,334.379306 L254.610639,406.879724 C250.187502,409.822492 243.029282,409.83121 238.593041,406.879724 L129.621043,334.379306 C125.197905,331.436538 125.184802,326.674086 129.621043,323.7226 L238.593041,251.222182 L238.593041,251.222182 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M363.033477,334.013942 C367.456614,331.071174 374.614834,331.062457 379.051075,334.013942 L488.023073,406.51436 C492.446211,409.457129 492.459314,414.219581 488.023073,417.171066 L379.051075,489.671484 C374.627937,492.614252 367.469718,492.62297 363.033477,489.671484 L254.061478,417.171066 C249.638341,414.228298 249.625238,409.465846 254.061478,406.51436 L363.033477,334.013942 L363.033477,334.013942 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M238.435154,84.3324822 C242.858292,81.3897142 250.016511,81.3809965 254.452752,84.3324822 L363.424751,156.8329 C367.847888,159.775668 367.860991,164.53812 363.424751,167.489606 L254.452752,239.990024 C250.029614,242.932792 242.871395,242.94151 238.435154,239.990024 L129.463156,167.489606 C125.040018,164.546838 125.026915,159.784386 129.463156,156.8329 L238.435154,84.3324822 L238.435154,84.3324822 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                <path d="M364.289686,168.065058 C368.712823,165.12229 375.871043,165.113572 380.307283,168.065058 L489.279282,240.565476 C493.70242,243.508244 493.715523,248.270696 489.279282,251.222182 L380.307283,323.7226 C375.884146,326.665368 368.725926,326.674086 364.289686,323.7226 L255.317687,251.222182 C250.89455,248.279414 250.881446,243.516962 255.317687,240.565476 L364.289686,168.065058 L364.289686,168.065058 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M488.730121,250.856818 C493.153259,247.91405 500.311478,247.905333 504.747719,250.856818 L613.719718,323.357237 C618.142855,326.300005 618.155958,331.062457 613.719718,334.013942 L504.747719,406.51436 C500.324581,409.457129 493.166362,409.465846 488.730121,406.51436 L379.758123,334.013942 C375.334985,331.071174 375.321882,326.308722 379.758123,323.357237 L488.730121,250.856818 L488.730121,250.856818 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                <path d="M364.60854,2.21034634 C369.031677,-0.732421682 376.189897,-0.741139323 380.626138,2.21034634 L489.598136,74.7107645 C494.021274,77.6535325 494.034377,82.4159845 489.598136,85.3674702 L380.626138,157.867888 C376.203,160.810656 369.04478,160.819374 364.60854,157.867888 L255.636541,85.3674702 C251.213404,82.4247022 251.200301,77.6622502 255.636541,74.7107645 L364.60854,2.21034634 L364.60854,2.21034634 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M488.43839,83.7966216 C492.861527,80.8538536 500.019747,80.8451359 504.455987,83.7966216 L613.427986,156.29704 C617.851124,159.239808 617.864227,164.00226 613.427986,166.953745 L504.455987,239.454164 C500.03285,242.396932 492.87463,242.405649 488.43839,239.454164 L379.466391,166.953745 C375.043254,164.010977 375.03015,159.248525 379.466391,156.29704 L488.43839,83.7966216 L488.43839,83.7966216 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M612.878825,166.588382 C617.301963,163.645614 624.460182,163.636896 628.896423,166.588382 L737.868422,239.0888 C742.291559,242.031568 742.304662,246.79402 737.868422,249.745506 L628.896423,322.245924 C624.473285,325.188692 617.315066,325.19741 612.878825,322.245924 L503.906827,249.745506 C499.483689,246.802738 499.470586,242.040286 503.906827,239.0888 L612.878825,166.588382 L612.878825,166.588382 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            </g>
                                            <g id="Group" transform="translate(816.319385, 504.369466) scale(-1, 1) rotate(-303.000000) translate(-816.319385, -504.369466) translate(437.819385, 266.369466)" fill="#807C7C">
                                                <path d="M115.154325,162.119681 C119.665925,159.273047 126.967309,159.264614 131.492275,162.119681 L242.643713,232.251661 C247.155314,235.098294 247.168679,239.705167 242.643713,242.560234 L131.492275,312.692214 C126.980674,315.538847 119.67929,315.54728 115.154325,312.692214 L4.00288631,242.560234 C-0.508713875,239.7136 -0.522079017,235.106727 4.00288631,232.251661 L115.154325,162.119681 L115.154325,162.119681 Z" id="Rectangle-1"></path>
                                                <path d="M243.364902,243.015274 C247.876502,240.16864 255.177886,240.160207 259.702852,243.015274 L370.854291,313.147254 C375.365891,315.993888 375.379256,320.600761 370.854291,323.455827 L259.702852,393.587807 C255.191252,396.434441 247.889868,396.442874 243.364902,393.587807 L132.213464,323.455827 C127.701863,320.609193 127.688498,316.002321 132.213464,313.147254 L243.364902,243.015274 L243.364902,243.015274 Z" id="Rectangle-1"></path>
                                                <path d="M370.294147,323.1024 C374.805747,320.255766 382.107131,320.247333 386.632096,323.1024 L497.783535,393.234379 C502.295135,396.081013 502.3085,400.687886 497.783535,403.542953 L386.632096,473.674932 C382.120496,476.521566 374.819112,476.529999 370.294147,473.674932 L259.142708,403.542953 C254.631108,400.696319 254.617742,396.089446 259.142708,393.234379 L370.294147,323.1024 L370.294147,323.1024 Z" id="Rectangle-1"></path>
                                                <path d="M243.203857,81.5775149 C247.715458,78.730881 255.016842,78.7224481 259.541807,81.5775149 L370.693246,151.709495 C375.204846,154.556129 375.218211,159.163001 370.693246,162.018068 L259.541807,232.150048 C255.030207,234.996682 247.728823,235.005115 243.203857,232.150048 L132.052419,162.018068 C127.540818,159.171434 127.527453,154.564561 132.052419,151.709495 L243.203857,81.5775149 L243.203857,81.5775149 Z" id="Rectangle-1"></path>
                                                <path d="M371.575479,162.574721 C376.08708,159.728087 383.388464,159.719654 387.913429,162.574721 L499.064868,232.706701 C503.576468,235.553335 503.589833,240.160207 499.064868,243.015274 L387.913429,313.147254 C383.401829,315.993888 376.100445,316.002321 371.575479,313.147254 L260.424041,243.015274 C255.912441,240.16864 255.899075,235.561768 260.424041,232.706701 L371.575479,162.574721 L371.575479,162.574721 Z" id="Rectangle-1"></path>
                                                <path d="M498.504724,242.661846 C503.016324,239.815213 510.317708,239.80678 514.842673,242.661846 L625.994112,312.793826 C630.505712,315.64046 630.519077,320.247333 625.994112,323.1024 L514.842673,393.234379 C510.331073,396.081013 503.029689,396.089446 498.504724,393.234379 L387.353285,323.1024 C382.841685,320.255766 382.82832,315.648893 387.353285,312.793826 L498.504724,242.661846 L498.504724,242.661846 Z" id="Rectangle-1"></path>
                                                <path d="M371.900711,2.13813891 C376.412311,-0.708494985 383.713695,-0.716927839 388.23866,2.13813891 L499.390099,72.2701188 C503.901699,75.1167527 503.915064,79.7236252 499.390099,82.578692 L388.23866,152.710672 C383.72706,155.557306 376.425676,155.565739 371.900711,152.710672 L260.749272,82.578692 C256.237672,79.7320581 256.224307,75.1251855 260.749272,72.2701188 L371.900711,2.13813891 L371.900711,2.13813891 Z" id="Rectangle-1"></path>
                                                <path d="M498.207158,81.0591598 C502.718758,78.2125259 510.020142,78.204093 514.545107,81.0591598 L625.696546,151.19114 C630.208146,154.037773 630.221511,158.644646 625.696546,161.499713 L514.545107,231.631693 C510.033507,234.478327 502.732123,234.486759 498.207158,231.631693 L387.055719,161.499713 C382.544119,158.653079 382.530753,154.046206 387.055719,151.19114 L498.207158,81.0591598 L498.207158,81.0591598 Z" id="Rectangle-1"></path>
                                                <path d="M625.136402,161.146285 C629.648002,158.299651 636.949386,158.291218 641.474351,161.146285 L752.62579,231.278265 C757.13739,234.124899 757.150755,238.731771 752.62579,241.586838 L641.474351,311.718818 C636.962751,314.565452 629.661367,314.573885 625.136402,311.718818 L513.984963,241.586838 C509.473363,238.740204 509.459998,234.133332 513.984963,231.278265 L625.136402,161.146285 L625.136402,161.146285 Z" id="Rectangle-1"></path>
                                            </g>
                                        </g>
                                        <text id="2.-"  fontFamily="Helvetica" fontSize="400" fontWeight="normal">
                                            <tspan x="71.4189453" y="493" fill="#9012FE">2</tspan>
                                            <tspan x="293.879883" y="493" fontSize="166" fill="#000000">. </tspan>
                                        </text>
                                        <g id="3.2." transform="translate(1901.000000, 21.000000)" strokeWidth="4" stroke="#090909" >
                                            <g id="main-+-Group-+-Line">
                                                <g id="main-+-Group">
                                                    <g id="main-+-Group-+-main">
                                                        <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)">
                                                            <rect id="Rectangle-1" fill="#807C7C" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#FFFFFF" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#FFFFFF" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                            <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        </g>
                                                        <g id="Group" transform="translate(5.000000, 0.753426)">
                                                            <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                            <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="_" transform="translate(41.000000, 827.000000)">
                                        <text id="Поворот-только-"  fontFamily="Helvetica" fontSize="111" fontWeight="normal" fill="#000000">
                                            <tspan x="193.222031" y="375">Поворот только </tspan>
                                            <tspan x="94.6878512" y="508">фронтальной части </tspan>
                                            <tspan x="526.574326" y="641">ПО </tspan>
                                            <tspan x="171.027451" y="774">часовой стрелке</tspan>
                                        </text>
                                        <g id="3.2." transform="translate(2351.351348, 168.000000)"  stroke="#090909">
                                            <g id="main-+-Group">
                                                <g id="main-+-Group-+-main">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)">
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="4" fill="#FFFFFF" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="4" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" strokeWidth="4" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)" strokeWidth="4">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" strokeWidth="4">
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="3.2.-+-Type-something" transform="translate(546.000000, 0.000000)">
                                            <g id="3.2." transform="translate(745.000000, 109.000000)" >
                                                <g id="main-+-Group-+-Line">
                                                    <g id="main-+-Group" stroke="#090909">
                                                        <g id="main-+-Group-+-main">
                                                            <g id="main" transform="translate(407.500000, 445.018678) scale(-1, 1) translate(-407.500000, -445.018678) translate(120.000000, 157.018678)">
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="4" fill="#FFFFFF" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="4" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="2" fill="#D8D8D8" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" strokeWidth="4" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                            </g>
                                                            <g id="Group" transform="translate(125.000000, 0.753426)" strokeWidth="4">
                                                                <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                                <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                                <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                            </g>
                                                            <g id="main" transform="translate(407.500000, 445.018678) scale(-1, 1) rotate(-313.000000) translate(-407.500000, -445.018678) translate(120.000000, 157.018678)" strokeWidth="4">
                                                                <rect id="Rectangle-1" fill="#807C7C" x="9.37916411e-13" y="-1.02318154e-12" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="-5.68434189e-13" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#FFFFFF" x="2.55795385e-13" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="-7.10542736e-13" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                                <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Line-+-Triangle-2" transform="translate(408.000000, 208.000000)">
                                                        <path d="M-1.13686838e-13,4.1876986 C-1.13686838e-13,4.1876986 62.2803992,-5.23462325 123.916474,4.1876986 C175.010351,11.9984321 222.233122,40.6138795 237.375456,58.5124566 C249.077776,72.344861 285.354843,115.288597 284.997377,155.804325 C284.349135,229.276929 237.375449,311.608645 237.375449,311.608645" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                                        <polygon id="Triangle-2" fill="#FF0000" transform="translate(223.167004, 311.342141) rotate(-161.000000) translate(-223.167004, -311.342141) " points="223.167004 266.337573 314.667004 356.346709 131.667004 356.346709 "></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                            <text id="Type-something"  fontFamily="Helvetica" fontSize="155" fontWeight="normal" fill="#D0011B">
                                                <tspan x="0" y="150">1</tspan>
                                            </text>
                                        </g>
                                        <g id="3.2.-+-3.2." transform="translate(749.000000, 1207.000000)">
                                            <g id="3.2." transform="translate(0.000000, 286.000000)" >
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" stroke="#090909" strokeWidth="4">
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#FFFFFF" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" fill="#807C7C" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)" stroke="#090909" strokeWidth="4">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                    <g id="down" transform="translate(97.000000, 445.018678) scale(-1, -1) rotate(-270.000000) translate(-97.000000, -445.018678) translate(-123.000000, 353.018678)">
                                                        <path d="M0.5,93.5094914 L340,93.5094914" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                                        <polygon id="Triangle-2" fill="#FF0000" transform="translate(394.000000, 91.509288) rotate(-270.000000) translate(-394.000000, -91.509288) " points="394 46.5047203 485.5 136.513856 302.5 136.513856 "></polygon>
                                                    </g>
                                                    <g id="down" transform="translate(478.000000, 445.018678) scale(-1, -1) rotate(-270.000000) translate(-478.000000, -445.018678) translate(258.000000, 353.018678)">
                                                        <path d="M0.5,93.5094914 L340,93.5094914" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                                        <polygon id="Triangle-2" fill="#FF0000" transform="translate(394.000000, 91.509288) rotate(-270.000000) translate(-394.000000, -91.509288) " points="394 46.5047203 485.5 136.513856 302.5 136.513856 "></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="3.2." transform="translate(782.000000, 286.000000)" strokeWidth="4" stroke="#090909" >
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" fill="#807C7C">
                                                        <rect id="Rectangle-1" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                </g>
                                            </g>
                                            <text id="2"  fontFamily="Helvetica" fontSize="155" fontWeight="normal" fill="#D0011B">
                                                <tspan x="61" y="150">2</tspan>
                                            </text>
                                            <text id="3"  fontFamily="Helvetica" fontSize="155" fontWeight="normal" fill="#D0011B">
                                                <tspan x="488" y="150">3</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                                <g id="3" transform="translate(765.000000, 6734.000000)">
                                    <g id="Group-+-1-+-2" transform="translate(521.000000, 0.000000)">
                                        <g id="Group" transform="translate(0.000000, 188.000000)" strokeWidth="4" stroke="#090909" >
                                            <g id="3.2.">
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" fill="#807C7C">
                                                        <rect id="Rectangle-1" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                    <g id="Group" transform="translate(1.000000, 733.753426)">
                                                        <g transform="translate(282.500000, 78.000000) scale(1, -1) translate(-282.500000, -78.000000) " id="Rectangle-1">
                                                            <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" fill="#807C7C"></path>
                                                            <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" fill="#807C7C"></path>
                                                            <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" fill="#807C7C"></path>
                                                            <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" fill="#807C7C"></path>
                                                            <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" fill="#807C7C"></path>
                                                            <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" fill="#FFFFFF"></path>
                                                            <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" fill="#807C7C"></path>
                                                            <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" fill="#807C7C"></path>
                                                            <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" fill="#807C7C"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="3.2." transform="translate(831.000000, 0.000000)">
                                                <g id="main-+-Group">
                                                    <g id="main" transform="translate(287.500000, 445.018678) scale(-1, 1) translate(-287.500000, -445.018678) translate(0.000000, 157.018678)" fill="#807C7C">
                                                        <rect id="Rectangle-1" x="0" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="0" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="191.686123" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="0" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="191.666667" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                        <rect id="Rectangle-1" x="383.333333" y="383.372246" width="191.666667" height="191.686123" rx="8"></rect>
                                                    </g>
                                                    <g id="Group" transform="translate(5.000000, 0.753426)">
                                                        <path d="M367.785847,104 L519.682859,104 C522.308432,104 525.89688,105.788837 527.703411,108.002251 L563.584802,151.965184 C565.388859,154.175566 564.349409,155.967435 561.266164,155.967435 L382.8128,155.967435 C379.728196,155.967435 376.738978,154.178598 376.134345,151.965184 L364.125111,108.002251 C363.521307,105.791869 365.161431,104 367.785847,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M44.6583374,104 L196.639349,104 C199.266374,104 200.912111,105.788837 200.313373,108.002251 L188.421231,151.965184 C187.823314,154.175566 184.835503,155.967435 181.750807,155.967435 L3.21344364,155.967435 C0.127386966,155.967435 -0.919119102,154.178598 0.881516581,151.965184 L36.645816,108.002251 C38.4439849,105.791869 42.0324709,104 44.6583374,104 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M352.990958,53 L478.499618,53 C480.669062,53 483.884179,54.7888367 485.686297,57.0022514 L521.48003,100.965184 C523.279679,103.175566 522.606623,104.967435 519.979305,104.967435 L367.914294,104.967435 C365.285817,104.967435 362.669931,103.178598 362.069712,100.965184 L350.148136,57.0022514 C349.548739,54.791869 350.82247,53 352.990958,53 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M338.189931,1 L436.717156,1 C438.42022,1 441.253694,2.78883675 443.051399,5.00225141 L478.757474,48.9651838 C480.552716,51.1755662 480.254368,52.9674352 478.093224,52.9674352 L353.009649,52.9674352 C350.847552,52.9674352 348.613309,51.1785984 348.017503,48.9651838 L336.183585,5.00225141 C335.588595,2.79186899 336.487618,1 338.189931,1 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M127.492515,1.98951966e-13 L226.395189,1.98951966e-13 C228.104743,1.98951966e-13 229.00077,1.78883675 228.394666,4.00225141 L216.356213,47.9651838 C215.75094,50.1755662 213.500871,51.9674352 211.332679,51.9674352 L85.8411488,51.9674352 C83.6720008,51.9674352 83.3759383,50.1785984 85.1854115,47.9651838 L121.125241,4.00225141 C122.932235,1.79186899 125.783715,1.98951966e-13 127.492515,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M206.839767,104 L358.9227,104 C361.551486,104 364.171189,105.788837 364.775819,108.002251 L376.785017,151.965184 C377.38882,154.175566 375.37331,155.967435 372.286291,155.967435 L193.614502,155.967435 C190.526122,155.967435 188.506384,154.178598 189.105123,151.965184 L200.997301,108.002251 C201.59522,105.791869 204.212139,104 206.839767,104 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                                        <path d="M87.0545323,52 L212.46196,52 C214.629655,52 215.900649,53.7888367 215.298964,56.0022514 L203.348276,99.9651838 C202.747415,102.175566 200.129325,103.967435 197.503195,103.967435 L45.506911,103.967435 C42.8796226,103.967435 42.2085928,102.178598 44.0136472,99.9651838 L79.8657118,56.0022514 C81.6682934,53.791869 84.8877934,52 87.0545323,52 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                        <path d="M219.128042,53 L344.790323,53 C346.962422,53 349.208336,54.7888367 349.808548,57.0022514 L361.729981,100.965184 C362.329371,103.175566 360.680703,104.967435 358.050169,104.967435 L205.799032,104.967435 C203.167338,104.967435 201.521389,103.178598 202.124547,100.965184 L214.10449,57.0022514 C214.706821,54.791869 216.956899,53 219.128042,53 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                        <path d="M232.446782,1.98951966e-13 L331.349456,1.98951966e-13 C333.05901,1.98951966e-13 334.925183,1.78883675 335.519489,4.00225141 L347.323615,47.9651838 C347.917107,50.1755662 346.638829,51.9674352 344.470637,51.9674352 L218.979107,51.9674352 C216.809959,51.9674352 215.54375,50.1785984 216.152814,47.9651838 L228.250063,4.00225141 C228.858293,1.79186899 230.737981,1.98951966e-13 232.446782,1.98951966e-13 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <text id="1" fill="#D0011B"  fontFamily="Helvetica" fontSize="155" fontWeight="normal">
                                            <tspan x="653" y="152">1</tspan>
                                        </text>
                                    </g>
                                    <g id="Line-+-Triangle-2" transform="translate(408.599170, 567.367328) scale(-1, -1) rotate(-333.000000) translate(-408.599170, -567.367328) translate(142.599170, 191.367328)" >
                                        <path d="M-1.98951966e-13,8.88795406 C-1.98951966e-13,8.88795406 102.139855,-11.1099426 203.223017,8.88795406 C287.016976,25.4654223 364.46232,86.1987286 389.295747,124.186594 C408.487553,153.544431 467.981943,244.688039 467.395698,330.67845 C466.332581,486.616398 389.295737,661.35689 389.295737,661.35689" id="Line" stroke="#FF0000" strokeWidth="44" strokeLinecap="square"></path>
                                        <path d="M341.964517,751.104841 L248.138738,507.252604 L531.907773,633.702767 L341.964517,751.104841 L341.964517,751.104841 Z" id="Triangle-2" fill="#FF0000"></path>
                                    </g>
                                </g>
                            </g>
                            <text id="3.-"  fontFamily="Helvetica" fontSize="400" fontWeight="normal">
                                <tspan x="306.418945" y="7378" fill="#9012FE">3</tspan>
                                <tspan x="528.879883" y="7378" fontSize="166" fill="#000000">. </tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default SchemeStep1SvgRu;