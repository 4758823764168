import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep5Svg () {
    return <div> 
      <svg width="250px" height="105px" viewBox="0 0 2653 1290" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="nav-step5" transform="translate(-195.000000, 0.000000)">
                    <g id="Pink_big_robot" transform="translate(925.000000, 1.000000)">
                        <path d="M619.737253,1.74682664 C612.272739,-1.39800634 600.996964,0.915653277 597.360057,9.08022833 C580.695665,46.4830296 578.753257,92.9769598 590.391361,132.121137 C595.03625,147.736296 617.734911,144.408877 618.413255,128.327717 C619.159707,110.625087 619.592867,92.8189006 621.382715,75.1816744 C623.172564,57.5935011 627.596787,40.3813996 630.111293,22.8940571 C631.399875,13.9337357 628.653806,5.5048203 619.737253,1.74682664" id="Fill-49" fill="#1B1A19"></path>
                        <path d="M1077.00957,626.265964 C1071.26135,615.836782 1062.18951,607.704909 1057.20409,596.741596 C1052.16418,585.636575 1052.38212,572.790178 1052.13694,560.824006 C1051.86451,548.830584 1035.98198,544.524833 1029.71615,554.749628 C1012.74392,582.407262 1031.9773,641.445097 1070.03542,638.431072 C1076.11056,637.951444 1080.14248,631.928844 1077.00957,626.265964" id="Fill-50" fill="#1B1A19"></path>
                        <path d="M1211.34358,598.014245 C1209.16416,589.004871 1194.75274,586.334216 1190.55736,595.297262 C1184.48222,608.24449 1180.58651,622.739609 1169.14455,632.214985 C1156.177,642.949385 1140.02205,642.058258 1124.41196,644.491825 C1116.3481,645.750848 1117.32884,656.160953 1122.39599,659.957099 C1159.60959,687.900875 1221.64134,640.71203 1211.34358,598.014245" id="Fill-51" fill="#1B1A19"></path>
                        <path d="M1188.67761,550.694592 C1183.74667,536.948892 1166.12061,540.284486 1164.37707,553.986584 C1162.63354,567.405264 1161.68004,579.853789 1154.37898,591.680977 C1147.05068,603.527241 1136.53498,611.204013 1124.90233,618.45566 C1118.50028,622.461643 1121.14283,632.460249 1128.74355,632.64556 C1166.03888,633.544863 1201.4817,586.366918 1188.67761,550.694592" id="Fill-52" fill="#1B1A19"></path>
                        <path d="M151.088366,532.847529 C143.852691,529.381127 136.24379,533.607848 135.674416,541.696118 C134.941586,552.087148 141.041239,561.450793 140.65984,572.14704 C140.256647,583.622683 136.622464,594.937541 128.806519,603.470013 C123.2381,609.547116 130.405668,620.01445 137.916495,615.283575 C151.870232,606.494939 161.702142,593.544985 164.821437,577.191309 C167.542988,562.903302 166.033739,540.009245 151.088366,532.847529" id="Fill-53" fill="#1B1A19"></path>
                        <path d="M86.6156689,595.196431 C75.6504613,580.486025 72.8934948,560.796755 72.8608035,543.0914 C72.8281122,528.021273 50.2929079,524.636626 46.3999186,539.510541 C42.9455377,552.692133 45.1985133,566.484161 50.5081256,578.84003 C56.0683712,591.784533 65.1374833,598.245884 77.2278166,604.584603 C82.7417496,607.475996 91.0235462,601.110025 86.6156689,595.196431" id="Fill-54" fill="#1B1A19"></path>
                        <path d="M65.6033344,618.523789 C57.3079165,610.99145 48.0889692,602.895004 41.7087167,593.596763 C34.4839389,583.0613 33.811043,572.351427 29.732803,560.728626 C25.2431975,547.934006 6.04795454,546.405192 2.19582942,560.728626 C-6.60902798,593.490482 26.4309815,634.607674 59.7543156,632.64556 C66.4696539,632.250412 71.2262384,623.628011 65.6033344,618.523789" id="Fill-55" fill="#1B1A19"></path>
                        <path d="M287.896017,517.365903 C285.711148,505.887535 270.062911,505.781254 264.535357,514.311 C253.834404,530.817285 249.216758,552.831116 244.288544,571.700114 C239.235013,591.05419 234.601021,610.604478 230.125037,630.100262 C223.436942,659.245833 215.724519,688.336901 209.139945,717.586027 C201.569185,707.786357 191.941596,698.956844 184.362663,690.437998 C169.697889,673.948063 155.7496,657.054805 141.967492,639.826353 C122.521616,615.523389 79.5488991,648.189865 98.4526446,673.413932 C112.52625,692.193 127.803985,710.473364 144.351233,727.132258 C159.013282,741.894442 179.685082,765.725953 202.282945,765.028313 C202.88501,765.011962 203.492523,764.960184 204.097312,764.883879 C212.177512,782.472053 240.891373,786.069262 247.628505,762.352207 C259.015976,722.251499 266.347,680.472093 274.320954,639.578364 C278.328362,619.019767 282.131451,598.26496 285.035528,577.518328 C287.759803,558.055245 291.611928,536.856237 287.896017,517.365903" id="Fill-56" fill="#1B1A19"></path>
                        <path d="M1080.088,641.303389 C1066.63008,644.0776 1056.30508,655.517816 1046.14353,664.2574 C1035.57334,673.343078 1025.24834,682.723074 1015.08679,692.261129 C1001.62887,704.905865 987.2447,718.084732 975.094433,732.658879 C974.958219,731.966689 974.849248,731.277224 974.685792,730.568683 C965.55947,694.487584 957.032488,658.232074 948.750692,621.949313 C944.718764,604.249408 940.605109,586.576755 936.464211,568.898651 C932.432283,551.544841 928.563813,531.196082 917.865584,516.591958 C910.766123,506.898569 895.850716,507.628911 891.987694,519.973879 C891.350214,522.015023 890.840774,524.091594 890.418511,526.195416 C887.375496,521.6117 882.027744,518.336059 875.958059,517.649319 C851.758322,514.91326 823.720082,531.959127 802.582431,541.826926 C783.839418,550.580135 744.378292,565.663888 760.740288,593.634915 C776.453908,620.496803 810.194056,597.420161 829.184978,587.3507 C849.230195,576.717131 875.892676,565.396822 889.001888,545.781131 C889.287937,555.929622 890.797186,566.170767 892.118459,575.7606 C894.728315,594.716803 897.637841,613.580351 901.111292,632.397571 C908.039123,669.925729 916.290953,707.102342 925.376411,744.164499 C929.544552,761.210366 945.617775,765.289928 958.476354,760.529076 C958.122198,773.304619 972.806042,784.927421 985.36495,776.92363 C1007.29537,762.970818 1026.12011,743.355127 1045.73489,726.385564 C1055.67849,717.774063 1065.78555,709.366949 1076.00159,701.05249 C1086.02692,692.87974 1099.32138,685.519087 1104.85166,673.525664 C1112.97,655.92114 1099.67554,637.264704 1080.088,641.303389" id="Fill-57" fill="#1B1A19"></path>
                        <path d="M393.540684,541.788774 C384.182799,538.273319 374.626042,535.678968 365.589621,531.329615 C355.654189,526.546962 345.555301,520.657894 334.551954,518.94377 C322.23823,517.027983 306.372051,527.007514 311.896881,541.611638 C321.867728,567.963922 353.199617,585.98267 379.516116,592.662034 C393.314569,596.163863 408.131902,588.168247 411.956785,574.239962 C416.021403,559.442351 406.922324,546.816691 393.540684,541.788774" id="Fill-58" fill="#1B1A19"></path>
                        <path d="M558.947777,1279.11585 C453.709026,1292.90243 346.576904,1291.99222 241.602409,1276.44519 C238.401385,1275.97919 234.933383,1275.35786 232.732169,1273.00059 C230.552748,1270.68693 230.155004,1267.28594 229.844437,1264.12748 C229.441244,1259.79175 229.065294,1255.4533 228.711138,1251.09577 C228.376053,1246.84725 228.062761,1242.57692 227.776712,1238.30933 C226.041349,1213.11251 225.218618,1187.82849 225.330313,1162.56627 C225.330313,1157.74001 225.373901,1152.89196 225.463802,1148.0657 C225.864271,1124.53941 227.041158,1101.03219 229.021706,1077.5931 L551.635822,1071.34431 C551.123658,1095.96067 551.104588,1120.57975 551.589509,1145.19611 C551.701205,1151.53483 551.859213,1157.89535 552.033566,1164.23407 C552.570248,1182.57983 553.390255,1200.9256 554.48269,1219.27137 C554.992129,1228.25622 555.594194,1237.26286 556.237123,1246.24771 C557.059854,1257.21103 557.947968,1268.17434 558.947777,1279.11585" id="Fill-59" fill="#1B1A19"></path>
                        <path d="M1005.60631,1279.11585 C900.392083,1292.90243 793.240891,1291.99222 688.266395,1276.44519 C685.065372,1275.97919 681.59737,1275.35786 679.396155,1273.00059 C677.23853,1270.68693 676.816267,1267.28594 676.530218,1264.12748 C675.971742,1258.25749 675.484096,1252.38477 675.061834,1246.49298 C674.661365,1241.44326 674.304485,1236.39626 674.018436,1231.34927 C672.569122,1208.26445 671.904399,1185.11423 671.9943,1161.98581 C671.9943,1157.00422 672.059682,1152.04716 672.149583,1147.06557 C672.569122,1123.87174 673.748733,1100.69972 675.682968,1077.5931 L998.3325,1071.34431 C997.814888,1096.0506 997.787645,1120.77869 998.278015,1145.5095 C998.386986,1152.20249 998.577685,1158.87368 998.768384,1165.56667 C999.204268,1180.7785 999.858094,1195.96854 1000.67538,1211.15585 C1001.16575,1219.96083 1001.7106,1228.74674 1002.30994,1237.55173 C1003.26344,1251.40644 1004.38039,1265.28295 1005.60631,1279.11585" id="Fill-60" fill="#1B1A19"></path>
                        <path d="M507.807683,670.898611 C471.242461,680.403964 438.221522,703.009154 416.124926,733.661738 C396.668152,760.648983 385.855504,792.980264 379.134717,825.564985 C363.453789,901.561482 368.973171,981.822852 394.910995,1054.95521 C396.166886,1058.50064 397.594406,1062.20958 400.50938,1064.58592 C404.358781,1067.72803 409.785537,1067.78525 414.754615,1067.68987 C442.215309,1067.16664 469.673279,1066.64886 497.133973,1066.12836 C477.317595,1014.38577 460.587821,960.146941 462.143382,904.760818 C463.698943,849.374696 486.171489,792.159992 531.454391,760.253835 L507.807683,670.898611" id="Fill-61" fill="#1B1A19"></path>
                        <path d="M687.57443,670.898611 C724.142375,680.403964 757.160591,703.009154 779.262635,733.661738 C798.71396,760.648983 809.526608,792.980264 816.250119,825.564985 C831.933772,901.561482 826.408942,981.822852 800.473842,1054.95521 C799.215227,1058.50064 797.787707,1062.20958 794.872732,1064.58592 C791.023331,1067.72803 785.596575,1067.78525 780.627497,1067.68987 C753.169528,1067.16664 725.708834,1066.64886 698.24814,1066.12836 C718.067242,1014.38577 734.794291,960.146941 733.23873,904.760818 C731.685893,849.374696 709.213347,792.159992 663.930445,760.253835 L687.57443,670.898611" id="Fill-62" fill="#1B1A19"></path>
                        <path d="M517.751287,410.989338 C556.71932,486.574336 509.769161,588.007463 475.113656,657.065706 C508.81294,629.255463 555.02482,620.458651 598.575083,623.897801 C642.130795,627.339677 683.945695,641.83207 725.204843,656.201831 C688.383539,566.083562 628.754604,477.829302 679.063794,377.886837 L517.751287,410.989338" id="Fill-63" fill="#1B1A19"></path>
                        <path d="M871.877095,364.947784 C880.758232,355.728573 888.971921,345.87985 896.384674,335.382539 C947.225098,263.386575 950.930112,146.700736 871.51749,94.939074 C834.938647,71.1021121 787.552605,72.0858943 744.637098,80.073334 C701.716142,88.0580486 659.435391,102.212522 615.781606,101.882778 C548.707226,101.378624 485.626634,66.7663848 418.582222,64.7715687 C363.293057,63.1282981 304.977222,87.3903848 276.952603,135.09156 C245.855002,188.02504 257.896298,256.59003 285.542243,311.406594 C320.718084,381.154302 397.50178,429.632148 470.727573,446.702541 C547.165286,464.522353 629.560989,479.110127 705.985081,458.02285 C766.649241,441.282201 827.572207,410.93756 871.877095,364.947784" id="Fill-64" fill="#DF6F92"></path>
                        <path d="M495.082593,171.622311 C463.856951,72.5491712 331.925752,101.531233 316.511803,195.377518 C291.494784,347.678455 547.05904,336.619761 495.082593,171.622311" id="Fill-65" fill="#1B1A19"></path>
                        <path d="M332.582303,225.436017 C336.480741,242.176666 346.394378,262.87697 362.609264,270.709076 C379.916584,279.072588 401.555502,274.003793 416.454563,262.767964 C436.164694,247.899499 445.201115,223.056953 440.670645,200.789683 C440.686991,200.113844 440.76327,199.448905 440.752373,198.76489 C440.229312,166.774254 409.782813,145.022038 379.151063,149.248759 C340.997589,154.51649 324.346819,190.112512 332.582303,225.436017" id="Fill-66" fill="#515251"></path>
                        <path d="M873.8304,169.752852 C842.604758,70.6797125 710.676283,99.6617738 695.262334,193.508059 C670.242591,345.806271 925.812295,334.750302 873.8304,169.752852" id="Fill-67" fill="#1B1A19"></path>
                        <path d="M711.330109,223.566558 C715.231271,240.304482 725.142184,261.004786 741.35707,268.839617 C758.664391,277.200404 780.303308,272.134334 795.202369,260.898505 C814.9125,246.03004 823.948921,221.187495 819.421176,198.920224 C819.437521,198.244385 819.511077,197.579446 819.50018,196.895431 C818.979843,164.904795 788.530619,143.152579 757.904318,147.3793 C719.745395,152.647032 703.09735,188.243053 711.330109,223.566558" id="Fill-68" fill="#515251"></path>
                        <path d="M407.107575,557.578342 C388.410874,577.812645 374.4081,602.022953 362.315042,626.884575 C323.246212,707.181372 302.69428,796.651053 302.759662,886.169786 C302.762387,888.649681 302.87953,891.388465 304.571305,893.176169 C306.132315,894.822165 308.562368,895.165535 310.801723,895.413524 C495.657415,915.974846 682.510002,936.533442 867.090543,913.560355 C870.247978,913.167932 873.557972,912.70193 876.113342,910.783419 C881.303087,906.894617 881.1587,899.130641 880.499426,892.631137 C871.321342,802.030516 861.688305,709.658541 825.035907,626.492152 C814.863463,603.407334 802.476184,580.960203 785.149794,562.767044 C766.393159,543.075049 742.525784,529.084085 717.824781,518.00904 C648.807994,487.06759 565.867436,487.915114 493.175601,506.23908 C457.888064,515.133998 431.972034,530.667402 407.107575,557.578342" id="Fill-69" fill="#FF0000"></path>
                        <path d="M279.044847,988.924615 C258.762618,994.159645 238.300587,1001.11697 222.279125,1014.61741 C206.257662,1028.11785 195.415047,1049.44221 199.403386,1070.01171 C202.893182,1088.01683 217.067586,1102.5065 233.391443,1110.85638 C249.7153,1119.209 268.134125,1122.34293 286.261452,1125.10079 C374.081187,1138.46497 464.18114,1145.47135 551.412431,1128.67075 C562.483886,1126.53695 574.642326,1123.24223 580.638456,1113.68782 C584.460614,1107.60255 584.997296,1100.08928 585.427731,1092.91122 C594.107272,948.608619 375.004716,964.152924 279.044847,988.924615" id="Fill-70" fill="#DF6F92"></path>
                        <path d="M711.907656,988.924615 C691.625427,994.159645 671.16612,1001.11697 655.144658,1014.61741 C639.123195,1028.11785 628.277856,1049.44221 632.266195,1070.01171 C635.758716,1088.01683 649.933119,1102.5065 666.254252,1110.85638 C682.575385,1119.209 700.996933,1122.34293 719.121536,1125.10079 C806.943995,1138.46497 897.043949,1145.47135 984.27524,1128.67075 C995.335797,1126.53695 1007.51331,1123.24223 1013.50671,1113.68782 C1017.3207,1107.60255 1017.86555,1100.08928 1018.30144,1092.91122 C1026.96463,948.608619 807.867525,964.152924 711.907656,988.924615" id="Fill-71" fill="#DF6F92"></path>
                        <path d="M423.611234,787.069395 C459.849542,861.22641 551.085518,903.687106 631.143793,883.621763 C779.48875,846.431524 820.954943,619.657455 666.164351,562.876051 C535.728779,515.027717 352.810046,642.194516 423.611234,787.069395" id="Fill-72" fill="#DF6F92"></path>
                        <path d="M552.033566,1164.23407 C542.054546,1163.63181 532.11639,1163.07587 522.224547,1162.76521 C481.720024,1161.42988 441.193706,1160.62868 400.64287,1160.36434 C342.198994,1159.96374 283.776913,1161.0102 225.330313,1162.56627 C225.330313,1157.74001 225.373901,1152.89196 225.463802,1148.0657 C284.553331,1145.26424 343.730037,1144.15237 402.865878,1143.8417 C444.12775,1143.64004 485.386898,1143.97524 526.64877,1144.86364 C534.895151,1145.04077 543.234157,1145.1089 551.589509,1145.19611 C551.701205,1151.53483 551.859213,1157.89535 552.033566,1164.23407" id="Fill-73" fill="#383937"></path>
                        <path d="M556.237123,1246.24771 C542.365114,1247.20424 528.493104,1247.76018 515.111465,1248.56137 C479.319937,1250.67337 443.482097,1252.22944 407.644257,1253.25137 C347.800104,1254.96549 288.331901,1254.2515 228.711138,1251.09577 C228.376053,1246.84725 228.062761,1242.57692 227.776712,1238.30933 C287.021525,1236.26273 346.288131,1233.75014 405.445767,1230.65981 C441.305401,1228.76855 477.140517,1226.32135 512.932044,1223.36456 C526.558869,1222.23089 540.498985,1220.6285 554.48269,1219.27137 C554.992129,1228.25622 555.594194,1237.26286 556.237123,1246.24771" id="Fill-74" fill="#383937"></path>
                        <path d="M998.768384,1165.56667 C981.169566,1164.58834 963.597992,1163.32114 946.244359,1162.76521 C905.728938,1161.42988 865.20262,1160.62868 824.654508,1160.36434 C773.743254,1160.00734 722.856518,1160.76494 671.9943,1161.98581 C671.9943,1157.00422 672.059682,1152.04716 672.149583,1147.06557 C723.703767,1144.97537 775.323333,1144.10604 826.877517,1143.8417 C868.139389,1143.64004 909.395812,1143.97524 950.657684,1144.86364 C966.376752,1145.19611 982.313762,1145.17431 998.278015,1145.5095 C998.386986,1152.20249 998.577685,1158.87368 998.768384,1165.56667" id="Fill-75" fill="#383937"></path>
                        <path d="M1002.30994,1237.55173 C979.099117,1240.59846 955.207224,1241.28792 932.650225,1242.62325 C896.836904,1244.73525 861.023582,1246.29132 825.163948,1247.31325 C774.988248,1248.73851 725.035938,1248.49324 675.061834,1246.49298 C674.661365,1241.44326 674.304485,1236.39626 674.018436,1231.34927 C723.679249,1229.52341 773.364579,1227.34329 822.965458,1224.71896 C858.844162,1222.83042 894.657484,1220.40503 930.443563,1217.42644 C953.38196,1215.53518 977.164882,1212.24591 1000.67538,1211.15585 C1001.16575,1219.96083 1001.7106,1228.74674 1002.30994,1237.55173" id="Fill-76" fill="#383937"></path>
                        <path d="M448.328583,776.950882 C479.050234,839.82029 556.397855,875.816909 624.270447,858.806469 C750.028438,827.276385 785.182485,635.024624 653.956874,586.887423 C543.378544,546.323438 388.307352,654.13071 448.328583,776.950882" id="Fill-77" fill="#1B1A19"></path>
                        <path d="M553.087861,617.294742 C581.918865,607.89567 613.871889,601.954825 642.128071,612.959015 C678.450832,627.105313 686.29402,672.691765 648.543739,687.462125 C639.226718,691.108387 604.298786,673.086913 585.86634,678.580833 C563.331135,685.298349 543.144256,702.412345 536.379881,725.434484 C530.61259,745.088328 531.241898,770.124359 513.564076,780.466336 C500.446691,788.140383 482.436508,782.387573 472.95603,770.508607 C463.475553,758.629641 461.214404,742.365894 462.710031,727.238539 C465.390718,700.161364 479.374423,674.381364 500.612872,657.379099 C532.484167,631.866165 514.277836,629.950378 553.087861,617.294742" id="Fill-78" fill="#515251"></path>
                        <path d="M351.110098,510.215087 C343.779074,485.010095 320.344859,466.214677 294.287166,464.050901 C266.311585,461.729066 241.071175,480.382776 231.549833,506.151875 C217.002204,545.508615 249.543671,582.10477 284.711339,585.230526 C299.433322,588.55522 315.645484,585.751032 328.283397,577.087753 C349.154069,562.783395 358.125107,534.316389 351.110098,510.215087" id="Fill-79" fill="#DF6F92"></path>
                        <path d="M947.524768,496.78823 C939.515399,474.09856 917.407906,458.949404 893.265379,460.43189 C870.021863,461.857148 848.799759,478.845786 842.40861,501.287467 C830.359141,543.598279 877.633488,581.031057 915.609884,563.489211 C940.114739,554.139192 957.768043,525.674911 947.524768,496.78823" id="Fill-80" fill="#DF6F92"></path>
                        <path d="M989.996218,714.670108 C967.24852,695.10892 932.514012,700.117761 913.640233,722.641197 C898.357049,740.877958 897.150195,772.552476 914.280438,786.870459 C920.766937,796.135998 930.41632,803.21596 942.29416,806.458899 C965.85914,812.884822 993.210863,802.313932 1003.97175,779.962182 C1014.48745,758.201791 1008.2761,730.388822 989.996218,714.670108" id="Fill-81" fill="#DF6F92"></path>
                        <path d="M259.890468,714.670108 C237.137322,695.10892 202.400089,700.117761 183.523586,722.641197 C168.243126,740.877958 167.036272,772.552476 184.163791,786.870459 C190.65029,796.135998 200.305121,803.21596 212.185685,806.458899 C235.750665,812.884822 263.083318,802.313932 273.866,779.962182 C284.359908,758.201791 278.173079,730.388822 259.890468,714.670108" id="Fill-82" fill="#DF6F92"></path>
                        <path d="M1127.98076,593.177089 C1104.00713,576.992372 1069.51781,584.298522 1055.78746,610.296535 C1052.35488,616.836915 1050.72031,624.148516 1050.88377,631.413789 C1048.70435,649.868562 1057.3403,669.029152 1072.46003,680.112372 C1091.28477,693.882598 1120.13484,695.384161 1136.61671,676.97844 C1144.54435,668.127125 1147.92245,656.447095 1147.70451,644.617182 C1152.22681,625.541072 1145.00748,604.647281 1127.98076,593.177089" id="Fill-83" fill="#DF6F92"></path>
                        <path d="M149.630878,609.683374 C149.312138,609.059313 148.941637,608.467953 148.595654,607.862968 C144.81436,598.294937 138.16168,590.015905 128.171763,584.696395 C118.963712,579.79111 107.698835,579.281505 97.5890494,582.074793 C97.3656588,582.093869 97.139544,582.093869 96.9161534,582.118395 C71.855546,584.701846 52.5050193,603.039438 49.0806054,628.129973 C45.6507429,653.25866 58.8089921,678.286516 83.1040782,687.192334 C100.525818,693.580106 118.08922,688.595791 131.097634,677.343611 C133.857325,675.741218 136.491699,673.871759 138.908131,671.656205 C155.790464,656.169129 160.067577,629.996706 149.630878,609.683374" id="Fill-84" fill="#DF6F92"></path>
                        <path d="M747.938261,330.398224 C713.788179,337.53814 679.374475,341.388789 643.204788,340.748376 C607.450639,340.118865 573.948438,333.093406 538.641104,331.433784 C518.9723,330.509956 506.144237,348.310691 521.854258,356.148247 C584.940091,387.626554 692.528713,383.26085 764.979792,368.108968 C803.790156,359.990721 787.123951,322.203672 747.938261,330.398224" id="Fill-85" fill="#FF0000"></path>
                        <path d="M751.861875,171.055478 C742.187974,171.055478 742.171629,186.098353 751.861875,186.098353 C761.538501,186.098353 761.554847,171.055478 751.861875,171.055478" id="Fill-86" fill="#FFFFFF"></path>
                        <path d="M381.480318,172.006558 C380.21353,170.047169 378.543549,168.842649 376.339611,168.055078 C372.705428,166.757903 368.019674,168.657338 365.04749,170.785687 C363.178637,172.121015 361.432377,173.826964 360.898419,176.178776 C360.438017,178.195393 360.544263,180.138431 361.353373,182.054218 C363.031527,186.035674 367.67914,188.643651 371.997116,187.281072 C376.451306,185.872165 380.875529,183.414072 382.343913,178.636869 C382.962324,176.631152 382.624514,173.783362 381.480318,172.006558" id="Fill-87" fill="#FFFFFF"></path>
                    </g>
                    <g id="5">
                        <g id="steps">
                            <g id="Group-+-Group-+-Group">
                                <g id="Group" transform="translate(406.092850, 506.986011) rotate(-302.000000) translate(-406.092850, -506.986011) translate(35.092850, 260.986011)">
                                    <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                    <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                    <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                    <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                </g>
                                <g id="Group" transform="translate(816.339647, 504.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -504.329906) translate(437.839647, 266.329906)" stroke="#090909" strokeWidth="4">
                                    <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                </g>
                                <g id="Group" transform="translate(213.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                    <path d="M121.677228,127.553092 C126.444387,125.313408 134.159357,125.306773 138.940639,127.553092 L256.388237,182.731778 C261.155397,184.971462 261.169519,188.596074 256.388237,190.842393 L138.940639,246.021079 C134.17348,248.260763 126.458509,248.267398 121.677228,246.021079 L4.22962933,190.842393 C-0.537529912,188.602709 -0.551652121,184.978097 4.22962933,182.731778 L121.677228,127.553092 L121.677228,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M274.413689,191.200412 L391.861287,246.379097 C396.628447,248.618782 396.642569,252.243393 391.861287,254.489713 L274.413689,309.668398 C269.646529,311.908083 261.931559,311.914718 257.150278,309.668398 L139.702679,254.489713 C134.93552,252.250028 134.921398,248.625417 139.702679,246.379097 L257.150278,191.200412 C261.917437,188.960727 269.632407,188.954092 274.413689,191.200412 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M391.269414,254.211641 C396.036573,251.971957 403.751543,251.965322 408.532825,254.211641 L525.980424,309.390327 C530.747583,311.630012 530.761705,315.254623 525.980424,317.500943 L408.532825,372.679628 C403.765666,374.919313 396.050696,374.925948 391.269414,372.679628 L273.821815,317.500943 C269.054656,315.261258 269.040534,311.636647 273.821815,309.390327 L391.269414,254.211641 L391.269414,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M256.980111,64.1838439 C261.74727,61.9441593 269.46224,61.9375244 274.243521,64.1838439 L391.69112,119.362529 C396.458279,121.602214 396.472402,125.226826 391.69112,127.473145 L274.243521,182.651831 C269.476362,184.891515 261.761392,184.89815 256.980111,182.651831 L139.532512,127.473145 C134.765353,125.23346 134.751231,121.608849 139.532512,119.362529 L256.980111,64.1838439 L256.980111,64.1838439 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M392.623328,127.911111 C397.390487,125.671426 405.105457,125.664791 409.886739,127.911111 L527.334337,183.089796 C532.101497,185.329481 532.115619,188.954092 527.334337,191.200412 L409.886739,246.379097 C405.119579,248.618782 397.404609,248.625417 392.623328,246.379097 L275.175729,191.200412 C270.40857,188.960727 270.394448,185.336116 275.175729,183.089796 L392.623328,127.911111 L392.623328,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M544.005875,190.92234 L661.453474,246.101026 C666.220633,248.340711 666.234755,251.965322 661.453474,254.211641 L544.005875,309.390327 C539.238716,311.630012 531.523746,311.636647 526.742464,309.390327 L409.294865,254.211641 C404.527706,251.971957 404.513584,248.347345 409.294865,246.101026 L526.742464,190.92234 C531.509623,188.682656 539.224593,188.676021 544.005875,190.92234 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M392.966982,1.68225245 C397.734141,-0.557432174 405.449111,-0.564067005 410.230393,1.68225245 L527.677991,56.860938 C532.445151,59.1006227 532.459273,62.7252341 527.677991,64.9715535 L410.230393,120.150239 C405.463233,122.389924 397.748263,122.396559 392.966982,120.150239 L275.519383,64.9715535 C270.752224,62.7318689 270.738102,59.1072575 275.519383,56.860938 L392.966982,1.68225245 L392.966982,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M526.428042,63.7760106 C531.195202,61.536326 538.910172,61.5296912 543.691453,63.7760106 L661.139052,118.954696 C665.906211,121.194381 665.920333,124.818992 661.139052,127.065312 L543.691453,182.243997 C538.924294,184.483682 531.209324,184.490317 526.428042,182.243997 L408.980444,127.065312 C404.213284,124.825627 404.199162,121.201016 408.980444,118.954696 L526.428042,63.7760106 L526.428042,63.7760106 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M660.547178,126.787241 C665.314338,124.547556 673.029308,124.540921 677.810589,126.787241 L795.258188,181.965926 C800.025347,184.205611 800.039469,187.830222 795.258188,190.076542 L677.810589,245.255227 C673.04343,247.494912 665.32846,247.501547 660.547178,245.255227 L543.09958,190.076542 C538.33242,187.836857 538.318298,184.212246 543.09958,181.965926 L660.547178,126.787241 L660.547178,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                                </g>
                            </g>
                            <g id="Group-+-Group-+-Group" transform="translate(1823.000000, 0.000000)">
                                <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)">
                                    <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                    <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                    <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                    <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                    <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                </g>
                                <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4">
                                    <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                    <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                </g>
                                <g id="Group" transform="translate(211.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                    <path d="M122.286376,127.553092 C127.0774,125.313408 134.830994,125.306773 139.636211,127.553092 L257.671783,182.731778 C262.462808,184.971462 262.477001,188.596074 257.671783,190.842393 L139.636211,246.021079 C134.845187,248.260763 127.091593,248.267398 122.286376,246.021079 L4.25080394,190.842393 C-0.540220925,188.602709 -0.554413834,184.978097 4.25080394,182.731778 L122.286376,127.553092 L122.286376,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M275.787474,191.200412 L393.823046,246.379097 C398.614071,248.618782 398.628264,252.243393 393.823046,254.489713 L275.787474,309.668398 C270.99645,311.908083 263.242856,311.914718 258.437639,309.668398 L140.402067,254.489713 C135.611042,252.250028 135.596849,248.625417 140.402067,246.379097 L258.437639,191.200412 C263.228663,188.960727 270.982257,188.954092 275.787474,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M393.22821,254.211641 C398.019235,251.971957 405.772828,251.965322 410.578046,254.211641 L528.613617,309.390327 C533.404642,311.630012 533.418835,315.254623 528.613617,317.500943 L410.578046,372.679628 C405.787021,374.919313 398.033427,374.925948 393.22821,372.679628 L275.192638,317.500943 C270.401613,315.261258 270.38742,311.636647 275.192638,309.390327 L393.22821,254.211641 L393.22821,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M258.266619,64.1838439 C263.057644,61.9441593 270.811237,61.9375244 275.616455,64.1838439 L393.652027,119.362529 C398.443052,121.602214 398.457245,125.226826 393.652027,127.473145 L275.616455,182.651831 C270.82543,184.891515 263.071837,184.89815 258.266619,182.651831 L140.231048,127.473145 C135.440023,125.23346 135.42583,121.608849 140.231048,119.362529 L258.266619,64.1838439 L258.266619,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M394.588902,127.911111 C399.379926,125.671426 407.13352,125.664791 411.938737,127.911111 L529.974309,183.089796 C534.765334,185.329481 534.779527,188.954092 529.974309,191.200412 L411.938737,246.379097 C407.147713,248.618782 399.394119,248.625417 394.588902,246.379097 L276.55333,191.200412 C271.762305,188.960727 271.748112,185.336116 276.55333,183.089796 L394.588902,127.911111 L394.588902,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M546.729308,190.92234 L664.76488,246.101026 C669.555905,248.340711 669.570098,251.965322 664.76488,254.211641 L546.729308,309.390327 C541.938284,311.630012 534.18469,311.636647 529.379473,309.390327 L411.343901,254.211641 C406.552876,251.971957 406.538683,248.347345 411.343901,246.101026 L529.379473,190.92234 C534.170498,188.682656 541.924091,188.676021 546.729308,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M394.934276,1.68225245 C399.725301,-0.557432174 407.478894,-0.564067005 412.284112,1.68225245 L530.319683,56.860938 C535.110708,59.1006227 535.124901,62.7252341 530.319683,64.9715535 L412.284112,120.150239 C407.493087,122.389924 399.739494,122.396559 394.934276,120.150239 L276.898704,64.9715535 C272.107679,62.7318689 272.093487,59.1072575 276.898704,56.860938 L394.934276,1.68225245 L394.934276,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    <path d="M529.063477,63.7760106 C533.854502,61.536326 541.608095,61.5296912 546.413313,63.7760106 L664.448884,118.954696 C669.239909,121.194381 669.254102,124.818992 664.448884,127.065312 L546.413313,182.243997 C541.622288,184.483682 533.868695,184.490317 529.063477,182.243997 L411.027905,127.065312 C406.23688,124.825627 406.222687,121.201016 411.027905,118.954696 L529.063477,63.7760106 L529.063477,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                    <path d="M663.854048,126.787241 C668.645073,124.547556 676.398666,124.540921 681.203884,126.787241 L799.239455,181.965926 C804.03048,184.205611 804.044673,187.830222 799.239455,190.076542 L681.203884,245.255227 C676.412859,247.494912 668.659266,247.501547 663.854048,245.255227 L545.818476,190.076542 C541.027451,187.836857 541.013258,184.212246 545.818476,181.965926 L663.854048,126.787241 L663.854048,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
