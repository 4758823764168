import React from "react"
import { useState } from 'react'
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import { Button  } from 'react-bootstrap'
import { Tabs, Tab } from 'react-bootstrap'
// import Scheme1en from '../../images/schemes/scheme1en.png'
// import Scheme1ru from '../../images/schemes/scheme1ru.png'

import SchemeStep1SvgRu from "./SchemeStep1SvgRu"
import SchemeStep1SvgEn from "./SchemeStep1SvgEn"

function Scheme1btn() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation()

  return (
    <>
      <Button variant="warning" onClick={() => setShow(true)}>
        {t('step1.btnscheme')}
        <span role="img" aria-label="eye">👁</span>
        {/* &#128065; */}
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <p>{t('step1.step1')}</p>
            <p>{t('step1.h1modal')}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey={t('main.tab')} id="uncontrolled-tab-example" style={{ marginBottom: '2rem' }} >
            <Tab eventKey="home">
            <SchemeStep1SvgRu />
              {/* <img
                src={Scheme1ru} alt="scheme"> 
              </img> */}
            </Tab>
            <Tab eventKey="profile">
              {/* <img
                src={Scheme1en} alt="scheme"> 
              </img> */}
              <SchemeStep1SvgEn />
            </Tab>
            
            
          </Tabs>
          {/* <img 
            src={t('step1.step1scheme')}>
            src={Scheme1en} alt="scheme"> 

              src={t("home.videoUrl")}
            src={t('step1.step1scheme')}
          </img> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Scheme1btn;