import React from "react"
import { useState } from 'react'
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import { Button  } from 'react-bootstrap'
import { Tabs, Tab } from 'react-bootstrap'

import ImageScheme3en from './ImageScheme3en'
import ImageScheme3ru from './ImageScheme3ru'


function Scheme3btn() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation()

    return (
      <>
        <Button variant="warning" onClick={() => setShow(true)}>
          {t('step1.btnscheme')}
          <span role="img" aria-label="eye">👁</span>
          {/* &#128065; */}
        </Button>
  
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <p>{t('step3.step3')}</p>
              <p>{t('step3.h1modal')}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <ImageScheme2ru /> */}

            <Tabs defaultActiveKey={t('main.tab')} id="uncontrolled-tab-example" style={{ marginBottom: '2rem' }} >
              <Tab eventKey="home">
              <ImageScheme3ru />
              </Tab>
              <Tab eventKey="profile">
                <ImageScheme3en />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
export default Scheme3btn;