import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';
import containerStyles from './neon.module.css'
import { useTranslation } from "react-i18next"


const Neon = ({ children }) => {
  const { t } = useTranslation();
  
  return  (<div className={containerStyles.neon}>{children}
    <div >
      {t('nav.school')}
      <br/>
      {t('nav.kids_zone')}
    </div>
  </div>
  )
}
export default Neon
