import React from 'react';

const AllSchemesSvg2x2 = () => {
    return <div style={{ width: '100%'}}>
        <svg viewBox="0 0 4251 1070" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <g id="scheme_first-+-1-+-2-+-3" transform="translate(-181.000000, -106.000000)">
                    <g id="scheme_first" transform="translate(0.000000, 283.000000)" >
                        <g id="arrow" transform="translate(2026.628916, 506.516229) scale(-1, 1) rotate(-313.000000) translate(-2026.628916, -506.516229) translate(1712.373805, 179.078729)" stroke="#343A40" strokeWidth="27" strokeLinecap="square" fill="#343A40">
                            <path d="M628.510223,-7.81782047e-17 L64.8239243,578.825" id="Line"></path>
                            <path d="M30.3111358,627.477553 L68.4151248,522.491667 L129.037396,575.156805 L30.3111358,627.477553 L30.3111358,627.477553 Z" id="Triangle-41"></path>
                        </g>
                        <g id="3" transform="translate(1731.221544, 445.033333) scale(1, -1) translate(-1731.221544, -445.033333) translate(1159.784559, 0.000000)" strokeWidth="4">
                            <g id="Group" transform="translate(200.471442, 0.000000)" stroke="#090909" fill="#FFFFFF">
                                <path d="M195.05475,91.823913 L360.563755,169.533895 C367.281711,172.688118 367.301612,177.792779 360.563755,180.956345 L195.05475,258.666328 C188.336794,261.82055 177.464737,261.829894 170.726879,258.666328 L5.21787397,180.956345 C-1.50008189,177.802123 -1.51998313,172.697462 5.21787397,169.533895 L170.726879,91.823913 C177.444835,88.6696905 188.316893,88.6603464 195.05475,91.823913 Z" id="Rectangle-1"></path>
                                <path d="M359.729678,180.564728 C366.447634,177.410506 377.319691,177.401162 384.057548,180.564728 L549.566554,258.274711 C556.284509,261.428933 556.304411,266.533594 549.566554,269.697161 L384.057548,347.407143 C377.339592,350.561366 366.467535,350.57071 359.729678,347.407143 L194.220672,269.697161 C187.502717,266.542938 187.482815,261.438277 194.220672,258.274711 L359.729678,180.564728 L359.729678,180.564728 Z" id="Rectangle-1"></path>
                                <path d="M385.965505,2.69148063 L551.47451,80.4014628 C558.192466,83.5556853 558.212367,88.6603464 551.47451,91.823913 L385.965505,169.533895 C379.247549,172.688118 368.375492,172.697462 361.637635,169.533895 L196.128629,91.823913 C189.410673,88.6696905 189.390772,83.5650294 196.128629,80.4014628 L361.637635,2.69148063 C368.35559,-0.462741884 379.227648,-0.472085938 385.965505,2.69148063 Z" id="Rectangle-1"></path>
                                <path d="M574.968303,91.4322961 L740.477309,169.142278 C747.195265,172.296501 747.215166,177.401162 740.477309,180.564728 L574.968303,258.274711 C568.250347,261.428933 557.37829,261.438277 550.640433,258.274711 L385.131428,180.564728 C378.413472,177.410506 378.393571,172.305845 385.131428,169.142278 L550.640433,91.4322961 C557.358389,88.2780736 568.230446,88.2687295 574.968303,91.4322961 Z" id="Rectangle-1"></path>
                            </g>
                            <g id="Group" transform="translate(378.720816, 475.209320) rotate(-302.000000) translate(-378.720816, -475.209320) translate(32.758350, 245.650986)">
                                <path d="M181.017641,118.756191 L334.582697,220.850582 C340.815852,224.99456 340.834317,231.700999 334.582697,235.857253 L181.017641,337.951644 C174.784486,342.095622 164.69701,342.107898 158.44539,337.951644 L4.88033329,235.857253 C-1.35282163,231.713275 -1.3712867,225.006836 4.88033329,220.850582 L158.44539,118.756191 C164.678545,114.612213 174.766021,114.599937 181.017641,118.756191 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                <path d="M335.801577,236.667599 C342.034732,232.523621 352.122208,232.511345 358.373828,236.667599 L511.938885,338.76199 C518.17204,342.905968 518.190505,349.612407 511.938885,353.768661 L358.373828,455.863053 C352.140673,460.00703 342.053197,460.019306 335.801577,455.863053 L182.236521,353.768661 C176.003366,349.624683 175.984901,342.918244 182.236521,338.76199 L335.801577,236.667599 L335.801577,236.667599 Z" id="Rectangle-1" stroke="#090909" fill="#807C7C"></path>
                                <path d="M336.250912,3.11258847 C342.484067,-1.03138917 352.571542,-1.04366527 358.823162,3.11258847 L512.388219,105.20698 C518.621374,109.350958 518.639839,116.057397 512.388219,120.213651 L358.823162,222.308042 C352.590007,226.45202 342.502532,226.464296 336.250912,222.308042 L182.685855,120.213651 C176.4527,116.069673 176.434235,109.363234 182.685855,105.20698 L336.250912,3.11258847 L336.250912,3.11258847 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                <path d="M510.753886,118.001597 C516.987041,113.857619 527.074517,113.845343 533.326137,118.001597 L686.891193,220.095988 C693.124348,224.239966 693.142813,230.946405 686.891193,235.102659 L533.326137,337.197051 C527.092982,341.341028 517.005506,341.353304 510.753886,337.197051 L357.188829,235.102659 C350.955674,230.958681 350.937209,224.252242 357.188829,220.095988 L510.753886,118.001597 L510.753886,118.001597 Z" id="Rectangle-1" stroke="#090909" fill="#807C7C"></path>
                            </g>
                            <g id="Group" transform="translate(762.348631, 472.042517) scale(-1, 1) rotate(-303.000000) translate(-762.348631, -472.042517) translate(408.635479, 249.525851)" stroke="#090909">
                                <path d="M162.34709,114.876672 C168.704908,110.86807 178.994133,110.856195 185.370786,114.876672 L342.007143,213.635852 C348.364961,217.644454 348.383796,224.131808 342.007143,228.152285 L185.370786,326.911465 C179.012968,330.920068 168.723742,330.931943 162.34709,326.911465 L5.71073215,228.152285 C-0.647085875,224.143683 -0.665920243,217.656329 5.71073215,213.635852 L162.34709,114.876672 L162.34709,114.876672 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M366.274097,228.936159 L522.910454,327.695339 C529.268273,331.703941 529.287107,338.191295 522.910454,342.211772 L366.274097,440.970952 C359.916279,444.979555 349.627053,444.99143 343.250401,440.970952 L186.614043,342.211772 C180.256225,338.20317 180.237391,331.715816 186.614043,327.695339 L343.250401,228.936159 C349.608219,224.927557 359.897444,224.915682 366.274097,228.936159 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M366.732418,3.01090666 L523.368776,101.770086 C529.726594,105.778689 529.745428,112.266042 523.368776,116.28652 L366.732418,215.0457 C360.3746,219.054302 350.085374,219.066177 343.708722,215.0457 L187.072364,116.28652 C180.714546,112.277918 180.695712,105.790564 187.072364,101.770086 L343.708722,3.01090666 C350.06654,-0.997695825 360.355765,-1.00957089 366.732418,3.01090666 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M544.725451,114.146729 L701.361809,212.905909 C707.719627,216.914511 707.738462,223.401865 701.361809,227.422343 L544.725451,326.181522 C538.367633,330.190125 528.078408,330.202 521.701756,326.181522 L365.065398,227.422343 C358.70758,223.41374 358.688746,216.926387 365.065398,212.905909 L521.701756,114.146729 C528.059574,110.138127 538.348799,110.126252 544.725451,114.146729 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                        </g>
                        <g id="arrow" transform="translate(879.527299, 509.332896) scale(-1, 1) rotate(-313.000000) translate(-879.527299, -509.332896) translate(565.272187, 181.895396)" stroke="#343A40" strokeWidth="27" strokeLinecap="square" fill="#343A40">
                            <path d="M628.510223,-7.81782047e-17 L64.8239243,578.825" id="Line"></path>
                            <path d="M30.3111358,627.477553 L68.4151248,522.491667 L129.037396,575.156805 L30.3111358,627.477553 L30.3111358,627.477553 Z" id="Triangle-41"></path>
                        </g>
                        <g id="cube" transform="translate(0.000000, 114.515792)" strokeWidth="4">
                            <g id="Group" transform="translate(200.471442, 0.000000)">
                                <path d="M195.05475,91.0502608 L360.563755,167.72411 C367.281711,170.836276 367.301612,175.872875 360.563755,178.994261 L195.05475,255.66811 C188.336794,258.780276 177.464737,258.789496 170.726879,255.66811 L5.21787397,178.994261 C-1.50008189,175.882095 -1.51998313,170.845496 5.21787397,167.72411 L170.726879,91.0502608 C177.444835,87.9380946 188.316893,87.9288751 195.05475,91.0502608 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                <path d="M359.729678,178.607865 C366.447634,175.495699 377.319691,175.48648 384.057548,178.607865 L549.566554,255.281715 C556.284509,258.393881 556.304411,263.43048 549.566554,266.551865 L384.057548,343.225714 C377.339592,346.337881 366.467535,346.3471 359.729678,343.225714 L194.220672,266.551865 C187.502717,263.439699 187.482815,258.4031 194.220672,255.281715 L359.729678,178.607865 L359.729678,178.607865 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                                <path d="M361.637635,3.10626088 C368.35559,-0.00590532604 379.227648,-0.0151247918 385.965505,3.10626088 L551.47451,79.78011 C558.192466,82.8922762 558.212367,87.9288751 551.47451,91.0502608 L385.965505,167.72411 C379.247549,170.836276 368.375492,170.845496 361.637635,167.72411 L196.128629,91.0502608 C189.410673,87.9380946 189.390772,82.9014957 196.128629,79.78011 L361.637635,3.10626088 L361.637635,3.10626088 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                                <path d="M574.968303,90.6638655 L740.477309,167.337715 C747.195265,170.449881 747.215166,175.48648 740.477309,178.607865 L574.968303,255.281715 C568.250347,258.393881 557.37829,258.4031 550.640433,255.281715 L385.131428,178.607865 C378.413472,175.495699 378.393571,170.4591 385.131428,167.337715 L550.640433,90.6638655 C557.358389,87.5516993 568.230446,87.5424798 574.968303,90.6638655 Z" id="Rectangle-1" stroke="#000000" fill="#0000FF"></path>
                            </g>
                            <g id="Group" transform="translate(376.116517, 473.138956) rotate(-302.000000) translate(-376.116517, -473.138956) translate(30.154052, 246.397289)">
                                <path d="M181.017641,117.307944 L334.582697,218.157282 C340.815852,222.250724 340.834317,228.875377 334.582697,232.980945 L181.017641,333.830283 C174.784486,337.923724 164.69701,337.935851 158.44539,333.830283 L4.88033329,232.980945 C-1.35282163,228.887503 -1.3712867,222.26285 4.88033329,218.157282 L158.44539,117.307944 C164.678545,113.214503 174.766021,113.202377 181.017641,117.307944 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                                <path d="M358.373828,233.781408 L511.938885,334.630747 C518.17204,338.724188 518.190505,345.348841 511.938885,349.454409 L358.373828,450.303747 C352.140673,454.397188 342.053197,454.409315 335.801577,450.303747 L182.236521,349.454409 C176.003366,345.360968 175.984901,338.736314 182.236521,334.630747 L335.801577,233.781408 C342.034732,229.687967 352.122208,229.675841 358.373828,233.781408 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                <path d="M336.250912,3.07463007 C342.484067,-1.01881125 352.571542,-1.03093764 358.823162,3.07463007 L512.388219,103.923968 C518.621374,108.01741 518.639839,114.642063 512.388219,118.747631 L358.823162,219.596969 C352.590007,223.69041 342.502532,223.702536 336.250912,219.596969 L182.685855,118.747631 C176.4527,114.654189 176.434235,108.029536 182.685855,103.923968 L336.250912,3.07463007 L336.250912,3.07463007 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                                <path d="M510.753886,116.562553 C516.987041,112.469112 527.074517,112.456985 533.326137,116.562553 L686.891193,217.411891 C693.124348,221.505332 693.142813,228.129986 686.891193,232.235553 L533.326137,333.084892 C527.092982,337.178333 517.005506,337.190459 510.753886,333.084892 L357.188829,232.235553 C350.955674,228.142112 350.937209,221.517459 357.188829,217.411891 L510.753886,116.562553 L510.753886,116.562553 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                            </g>
                            <g id="Group" transform="translate(760.712067, 471.401217) scale(-1, 1) rotate(-303.000000) translate(-760.712067, -471.401217) translate(406.998915, 251.701217)">
                                <path d="M185.370786,113.428647 L342.007143,210.942963 C348.364961,214.901037 348.383796,221.306617 342.007143,225.276416 L185.370786,322.790732 C179.012968,326.748806 168.723742,326.760532 162.34709,322.790732 L5.71073215,225.276416 C-0.647085875,221.318342 -0.665920243,214.912762 5.71073215,210.942963 L162.34709,113.428647 C168.704908,109.470573 178.994133,109.458848 185.370786,113.428647 Z" id="Rectangle-1" stroke="#090909" fill="#FFFFFF"></path>
                                <path d="M343.250401,226.050409 C349.608219,222.092335 359.897444,222.08061 366.274097,226.050409 L522.910454,323.564725 C529.268273,327.522799 529.287107,333.928379 522.910454,337.898179 L366.274097,435.412495 C359.916279,439.370569 349.627053,439.382294 343.250401,435.412495 L186.614043,337.898179 C180.256225,333.940105 180.237391,327.534524 186.614043,323.564725 L343.250401,226.050409 L343.250401,226.050409 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                                <path d="M366.732418,2.97295405 L523.368776,100.48727 C529.726594,104.445344 529.745428,110.850924 523.368776,114.820724 L366.732418,212.33504 C360.3746,216.293113 350.085374,216.304839 343.708722,212.33504 L187.072364,114.820724 C180.714546,110.86265 180.695712,104.457069 187.072364,100.48727 L343.708722,2.97295405 C350.06654,-0.985119827 360.355765,-0.996845205 366.732418,2.97295405 Z" id="Rectangle-1" stroke="#000000" fill="#0000FF"></path>
                                <path d="M544.725451,112.707905 L701.361809,210.222221 C707.719627,214.180295 707.738462,220.585875 701.361809,224.555675 L544.725451,322.069991 C538.367633,326.028064 528.078408,326.03979 521.701756,322.069991 L365.065398,224.555675 C358.70758,220.597601 358.688746,214.19202 365.065398,210.222221 L521.701756,112.707905 C528.059574,108.749831 538.348799,108.738106 544.725451,112.707905 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                            </g>
                        </g>
                        <g id="arrow" transform="translate(3196.277985, 510.741229) scale(-1, 1) rotate(-313.000000) translate(-3196.277985, -510.741229) translate(2882.022874, 183.303729)" stroke="#343A40" strokeWidth="27" strokeLinecap="square" fill="#343A40">
                            <path d="M628.510223,-6.25355786e-13 L64.8239243,578.825" id="Line"></path>
                            <path d="M30.3111358,627.477553 L68.4151248,522.491667 L129.037396,575.156805 L30.3111358,627.477553 L30.3111358,627.477553 Z" id="Triangle-41"></path>
                        </g>
                        <g id="7" transform="translate(2315.341471, 122.525000)" strokeWidth="4">
                            <g id="шаги-+-Group-+-Group-+-Group">
                                <g id="шаги">
                                    <g id="2-этап-+-Group">
                                        <g id="Group">
                                            <g id="Group-+-Group-+-Group">
                                                <g id="Group" transform="translate(378.720816, 476.617653) rotate(-302.000000) translate(-378.720816, -476.617653) translate(32.758350, 247.059320)">
                                                    <path d="M181.017641,118.756191 L334.582697,220.850582 C340.815852,224.99456 340.834317,231.700999 334.582697,235.857253 L181.017641,337.951644 C174.784486,342.095622 164.69701,342.107898 158.44539,337.951644 L4.88033329,235.857253 C-1.35282163,231.713275 -1.3712867,225.006836 4.88033329,220.850582 L158.44539,118.756191 C164.678545,114.612213 174.766021,114.599937 181.017641,118.756191 Z" id="Rectangle-1" stroke="#000000" fill="#0000FF"></path>
                                                    <path d="M335.801577,236.667599 C342.034732,232.523621 352.122208,232.511345 358.373828,236.667599 L511.938885,338.76199 C518.17204,342.905968 518.190505,349.612407 511.938885,353.768661 L358.373828,455.863053 C352.140673,460.00703 342.053197,460.019306 335.801577,455.863053 L182.236521,353.768661 C176.003366,349.624683 175.984901,342.918244 182.236521,338.76199 L335.801577,236.667599 L335.801577,236.667599 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                                    <path d="M336.250912,3.11258847 C342.484067,-1.03138917 352.571542,-1.04366527 358.823162,3.11258847 L512.388219,105.20698 C518.621374,109.350958 518.639839,116.057397 512.388219,120.213651 L358.823162,222.308042 C352.590007,226.45202 342.502532,226.464296 336.250912,222.308042 L182.685855,120.213651 C176.4527,116.069673 176.434235,109.363234 182.685855,105.20698 L336.250912,3.11258847 L336.250912,3.11258847 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                                                    <path d="M510.753886,118.001597 C516.987041,113.857619 527.074517,113.845343 533.326137,118.001597 L686.891193,220.095988 C693.124348,224.239966 693.142813,230.946405 686.891193,235.102659 L533.326137,337.197051 C527.092982,341.341028 517.005506,341.353304 510.753886,337.197051 L357.188829,235.102659 C350.955674,230.958681 350.937209,224.252242 357.188829,220.095988 L510.753886,118.001597 L510.753886,118.001597 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                                </g>
                                                <g id="Group" transform="translate(762.348631, 473.450851) scale(-1, 1) rotate(-303.000000) translate(-762.348631, -473.450851) translate(408.635479, 250.934184)">
                                                    <path d="M185.370786,114.876672 L342.007143,213.635852 C348.364961,217.644454 348.383796,224.131808 342.007143,228.152285 L185.370786,326.911465 C179.012968,330.920068 168.723742,330.931943 162.34709,326.911465 L5.71073215,228.152285 C-0.647085875,224.143683 -0.665920243,217.656329 5.71073215,213.635852 L162.34709,114.876672 C168.704908,110.86807 178.994133,110.856195 185.370786,114.876672 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                                                    <path d="M343.250401,228.936159 C349.608219,224.927557 359.897444,224.915682 366.274097,228.936159 L522.910454,327.695339 C529.268273,331.703941 529.287107,338.191295 522.910454,342.211772 L366.274097,440.970952 C359.916279,444.979555 349.627053,444.99143 343.250401,440.970952 L186.614043,342.211772 C180.256225,338.20317 180.237391,331.715816 186.614043,327.695339 L343.250401,228.936159 L343.250401,228.936159 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                                                    <path d="M343.708722,3.01090666 C350.06654,-0.997695825 360.355765,-1.00957089 366.732418,3.01090666 L523.368776,101.770086 C529.726594,105.778689 529.745428,112.266042 523.368776,116.28652 L366.732418,215.0457 C360.3746,219.054302 350.085374,219.066177 343.708722,215.0457 L187.072364,116.28652 C180.714546,112.277918 180.695712,105.790564 187.072364,101.770086 L343.708722,3.01090666 L343.708722,3.01090666 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                                    <path d="M544.725451,114.146729 L701.361809,212.905909 C707.719627,216.914511 707.738462,223.401865 701.361809,227.422343 L544.725451,326.181522 C538.367633,330.190125 528.078408,330.202 521.701756,326.181522 L365.065398,227.422343 C358.70758,223.41374 358.688746,216.926387 365.065398,212.905909 L521.701756,114.146729 C528.059574,110.138127 538.348799,110.126252 544.725451,114.146729 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                                                </g>
                                                <g id="Group" transform="translate(193.425363, 0.000000)">
                                                    <path d="M198.485435,91.823913 L366.065892,169.533895 C372.867928,172.688118 372.888078,177.792779 366.065892,180.956345 L198.485435,258.666328 C191.683399,261.82055 180.675271,261.829894 173.853086,258.666328 L6.27262838,180.956345 C-0.529407026,177.802123 -0.549557342,172.697462 6.27262838,169.533895 L173.853086,91.823913 C180.655121,88.6696905 191.663249,88.6603464 198.485435,91.823913 Z" id="Rectangle-1" stroke="#000000" fill="#FF0000"></path>
                                                    <path d="M365.221376,180.564728 C372.023411,177.410506 383.03154,177.401162 389.853725,180.564728 L557.434183,258.274711 C564.236218,261.428933 564.256368,266.533594 557.434183,269.697161 L389.853725,347.407143 C383.05169,350.561366 372.043562,350.57071 365.221376,347.407143 L197.640919,269.697161 C190.838883,266.542938 190.818733,261.438277 197.640919,258.274711 L365.221376,180.564728 L365.221376,180.564728 Z" id="Rectangle-1" stroke="#090909" fill="#3CFD03"></path>
                                                    <path d="M367.153212,2.69148063 C373.955247,-0.462741884 384.963376,-0.472085938 391.785561,2.69148063 L559.366019,80.4014628 C566.168054,83.5556853 566.188204,88.6603464 559.366019,91.823913 L391.785561,169.533895 C384.983526,172.688118 373.975398,172.697462 367.153212,169.533895 L199.572755,91.823913 C192.770719,88.6696905 192.750569,83.5650294 199.572755,80.4014628 L367.153212,2.69148063 L367.153212,2.69148063 Z" id="Rectangle-1" stroke="#090909" fill="#FF9F00"></path>
                                                    <path d="M583.153852,91.4322961 L750.734309,169.142278 C757.536344,172.296501 757.556495,177.401162 750.734309,180.564728 L583.153852,258.274711 C576.351816,261.428933 565.343688,261.438277 558.521502,258.274711 L390.941045,180.564728 C384.13901,177.410506 384.118859,172.305845 390.941045,169.142278 L558.521502,91.4322961 C565.323538,88.2780736 576.331666,88.2687295 583.153852,91.4322961 Z" id="Rectangle-1" stroke="#090909" fill="#FFFF00"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="8" transform="translate(3475.126030, 118.300000)" strokeWidth="4">
                            <g id="2Group">
                                <g id="Group">
                                    <g id="Group-+-Group-+-Group">
                                        <g id="Group" transform="translate(193.425363, 0.000000)" stroke="#090909" fill="#FFFF00">
                                            <path d="M198.485435,91.823913 L366.065892,169.533895 C372.867928,172.688118 372.888078,177.792779 366.065892,180.956345 L198.485435,258.666328 C191.683399,261.82055 180.675271,261.829894 173.853086,258.666328 L6.27262838,180.956345 C-0.529407026,177.802123 -0.549557342,172.697462 6.27262838,169.533895 L173.853086,91.823913 C180.655121,88.6696905 191.663249,88.6603464 198.485435,91.823913 Z" id="Rectangle-1"></path>
                                            <path d="M365.221376,180.564728 C372.023411,177.410506 383.03154,177.401162 389.853725,180.564728 L557.434183,258.274711 C564.236218,261.428933 564.256368,266.533594 557.434183,269.697161 L389.853725,347.407143 C383.05169,350.561366 372.043562,350.57071 365.221376,347.407143 L197.640919,269.697161 C190.838883,266.542938 190.818733,261.438277 197.640919,258.274711 L365.221376,180.564728 L365.221376,180.564728 Z" id="Rectangle-1"></path>
                                            <path d="M367.153212,2.69148063 C373.955247,-0.462741884 384.963376,-0.472085938 391.785561,2.69148063 L559.366019,80.4014628 C566.168054,83.5556853 566.188204,88.6603464 559.366019,91.823913 L391.785561,169.533895 C384.983526,172.688118 373.975398,172.697462 367.153212,169.533895 L199.572755,91.823913 C192.770719,88.6696905 192.750569,83.5650294 199.572755,80.4014628 L367.153212,2.69148063 L367.153212,2.69148063 Z" id="Rectangle-1"></path>
                                            <path d="M583.153852,91.4322961 L750.734309,169.142278 C757.536344,172.296501 757.556495,177.401162 750.734309,180.564728 L583.153852,258.274711 C576.351816,261.428933 565.343688,261.438277 558.521502,258.274711 L390.941045,180.564728 C384.13901,177.410506 384.118859,172.305845 390.941045,169.142278 L558.521502,91.4322961 C565.323538,88.2780736 576.331666,88.2687295 583.153852,91.4322961 Z" id="Rectangle-1"></path>
                                        </g>
                                        <g id="Group" transform="translate(378.720816, 476.617653) rotate(-302.000000) translate(-378.720816, -476.617653) translate(32.758350, 247.059320)" stroke="#000000" fill="#FF0000">
                                            <path d="M181.017641,118.756191 L334.582697,220.850582 C340.815852,224.99456 340.834317,231.700999 334.582697,235.857253 L181.017641,337.951644 C174.784486,342.095622 164.69701,342.107898 158.44539,337.951644 L4.88033329,235.857253 C-1.35282163,231.713275 -1.3712867,225.006836 4.88033329,220.850582 L158.44539,118.756191 C164.678545,114.612213 174.766021,114.599937 181.017641,118.756191 Z" id="Rectangle-1"></path>
                                            <path d="M335.801577,236.667599 C342.034732,232.523621 352.122208,232.511345 358.373828,236.667599 L511.938885,338.76199 C518.17204,342.905968 518.190505,349.612407 511.938885,353.768661 L358.373828,455.863053 C352.140673,460.00703 342.053197,460.019306 335.801577,455.863053 L182.236521,353.768661 C176.003366,349.624683 175.984901,342.918244 182.236521,338.76199 L335.801577,236.667599 L335.801577,236.667599 Z" id="Rectangle-1"></path>
                                            <path d="M336.250912,3.11258847 C342.484067,-1.03138917 352.571542,-1.04366527 358.823162,3.11258847 L512.388219,105.20698 C518.621374,109.350958 518.639839,116.057397 512.388219,120.213651 L358.823162,222.308042 C352.590007,226.45202 342.502532,226.464296 336.250912,222.308042 L182.685855,120.213651 C176.4527,116.069673 176.434235,109.363234 182.685855,105.20698 L336.250912,3.11258847 L336.250912,3.11258847 Z" id="Rectangle-1"></path>
                                            <path d="M510.753886,118.001597 C516.987041,113.857619 527.074517,113.845343 533.326137,118.001597 L686.891193,220.095988 C693.124348,224.239966 693.142813,230.946405 686.891193,235.102659 L533.326137,337.197051 C527.092982,341.341028 517.005506,341.353304 510.753886,337.197051 L357.188829,235.102659 C350.955674,230.958681 350.937209,224.252242 357.188829,220.095988 L510.753886,118.001597 L510.753886,118.001597 Z" id="Rectangle-1"></path>
                                        </g>
                                        <g id="Group" transform="translate(762.348631, 473.450851) scale(-1, 1) rotate(-303.000000) translate(-762.348631, -473.450851) translate(408.635479, 250.934184)" stroke="#090909" fill="#3CFD03">
                                            <path d="M185.370786,114.876672 L342.007143,213.635852 C348.364961,217.644454 348.383796,224.131808 342.007143,228.152285 L185.370786,326.911465 C179.012968,330.920068 168.723742,330.931943 162.34709,326.911465 L5.71073215,228.152285 C-0.647085875,224.143683 -0.665920243,217.656329 5.71073215,213.635852 L162.34709,114.876672 C168.704908,110.86807 178.994133,110.856195 185.370786,114.876672 Z" id="Rectangle-1"></path>
                                            <path d="M343.250401,228.936159 C349.608219,224.927557 359.897444,224.915682 366.274097,228.936159 L522.910454,327.695339 C529.268273,331.703941 529.287107,338.191295 522.910454,342.211772 L366.274097,440.970952 C359.916279,444.979555 349.627053,444.99143 343.250401,440.970952 L186.614043,342.211772 C180.256225,338.20317 180.237391,331.715816 186.614043,327.695339 L343.250401,228.936159 L343.250401,228.936159 Z" id="Rectangle-1"></path>
                                            <path d="M366.732418,3.01090666 L523.368776,101.770086 C529.726594,105.778689 529.745428,112.266042 523.368776,116.28652 L366.732418,215.0457 C360.3746,219.054302 350.085374,219.066177 343.708722,215.0457 L187.072364,116.28652 C180.714546,112.277918 180.695712,105.790564 187.072364,101.770086 L343.708722,3.01090666 C350.06654,-0.997695825 360.355765,-1.00957089 366.732418,3.01090666 Z" id="Rectangle-1"></path>
                                            <path d="M544.725451,114.146729 L701.361809,212.905909 C707.719627,216.914511 707.738462,223.401865 701.361809,227.422343 L544.725451,326.181522 C538.367633,330.190125 528.078408,330.202 521.701756,326.181522 L365.065398,227.422343 C358.70758,223.41374 358.688746,216.926387 365.065398,212.905909 L521.701756,114.146729 C528.059574,110.138127 538.348799,110.126252 544.725451,114.146729 Z" id="Rectangle-1"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="1-+-2-+-3" transform="translate(1053.000000, 0.000000)" fill="#9012FE" fontSize="400" fontFamily="Helvetica" fontWeight="normal">
                        <text id="1">
                            <tspan x="2.26953125" y="388">1</tspan>
                        </text>
                        <text id="2">
                            <tspan x="1144.26953" y="388">2</tspan>
                        </text>
                        <text id="3">
                            <tspan x="2286.26953" y="388">3</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default AllSchemesSvg2x2;