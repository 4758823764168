/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import { withTrans } from '../i18n/withTrans'
 import Header from "./headerbig"
//  import "./layout.css"
import "./bootstrap.min.css"
import "./style.css"
 import Footer from "../components/Footer"
 import BigFooter from "../components/BigFooter"
//  import BlockYandexAdAll from "../components/YandexAdBlocks/Block_YandexAd"
 // import BlockAmazon from "../components/AmazoneAdBlocks/Block_AmazoneAd"
//  import BlockAmazonProducts from "../components/AmazoneAdBlocks/BlockAmazonProducts"
 // import YandexAdBlock from "../components/yandexAdBlock"
 
 const Layout = ({ children, t, i18n }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   const translatedTitle = t(`site.${data.site.siteMetadata.title}`)
   return (
     <>
       {/* <Header siteTitle={translatedTitle} /> */}
       <Header siteTitle={translatedTitle} />
       <div
         style={{
           margin: `0 auto`,
           // maxWidth: 1060,
           // padding: `0px .5rem`,
           // paddingTop: 0,
         }}
       >
         <main>
           {children}
           {/* <BlockAmazon /> */}
           {/* <BlockAmazonProducts /> */}
           {/* <BlockYandexAdAll />  */}
           {/* <YandexAdBlock renderTo="yandex_rtb_R-A-728307-1" style={{marginTop: 20}}/> */}
         </main>
         <BigFooter />
         <Footer />
       </div>
     </>
   )
 }
 
Layout.propTypes = {
  children: PropTypes.node.isRequired,
} 
export default withTrans(Layout)