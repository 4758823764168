import React from 'react';
// import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
// import SocialIcons from '../components/SocialIcons/SocialIcons'
import Logo from '../components/Logo/Logo'
import Neon from "./Neon/Neon"

const BigFooter = ({ siteTitle, hideLangs }) => {
  const { t } = useTranslation();
  return (
    <footer className="container-fluid bg-black">
      <div className="container">
        <div className="row pt-5 ">
          <div className="col-6 col-md-3">
            <Link to="/"> <Logo /><Neon/></Link>
          </div>
          <div className="col-6 col-md-3">
            <div>
              <Link to="/home3x3/">
                <p className="text-white">3x3</p> 
              </Link>
              <Link to="/page-cube2x2/">
                <p className="text-white">2x2</p> 
              </Link>
              <Link to="/page-pyramid/">
                <p className="text-white">{t('nav.pyramid')}</p> 
              </Link>
              <Link to="/more-lessons/">
                <p className="text-white">{t('nav.more-lessons')}</p> 
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <Link to="/page-students/">
              <p className="text-white m-0"><span className="fs-2" role="img" aria-label="viva">🏆</span>{t('nav.page-students')}</p>
            </Link>
            <Link to="/goods_amazon/">
              <svg id="Icon" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <path class="header-svg" id="Mask" d="M4.5,18.98a1.988,1.988,0,0,1-1.92-1.46L.04,8.25A.85.85,0,0,1,0,7.98a1,1,0,0,1,1-1H5.79L10.17.43A.978.978,0,0,1,11,0a.992.992,0,0,1,.83.42l4.38,6.56H21a1,1,0,0,1,1,1l-.03.27-2.54,9.27A2.01,2.01,0,0,1,17.5,18.98Z" transform="translate(1 2)" fill="#3cfd01"></path>
                  </clipPath>
                </defs>
                <g id="Icon_Shop" data-name="Icon/Shop">
                  <rect id="Base" width="24" height="24" fill="none"></rect>
                  <g id="Mask_Group_16" data-name="Mask Group 16" clip-path="url(#clip-path)">
                    <g id="Symbols" transform="translate(1 2)">
                      <g id="Icon_Shop-2" data-name="Icon/Shop" transform="translate(-1 -2)">
                        <g id="Mask-2" data-name="Mask">
                          <path class="header-svg" id="Path_9" data-name="Path 9" d="M17.21,8.98,12.83,2.42A.993.993,0,0,0,12,2a.977.977,0,0,0-.83.43L6.79,8.98H2a1,1,0,0,0-1,1,.842.842,0,0,0,.04.27l2.54,9.27A1.989,1.989,0,0,0,5.5,20.98h13a2.01,2.01,0,0,0,1.93-1.46l2.54-9.27L23,9.98a1,1,0,0,0-1-1ZM9,8.98l3-4.4,3,4.4Z" fill="#3cfd01"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span style={{ color: 'white' }}>&nbsp;&nbsp;{t('nav.shop')}</span>
            </Link>
            <Link to="/page-form/">
              <span role="img" aria-label="viva" className="text-white fs-1"><br /> 💌</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
};
export default BigFooter;
