import React from 'react';
// import PropTypes from 'prop-types';
// import Link from '../Link';

export default function NavStep2Svg () {
    return <div> 
        <svg width="200px" height="162px" viewBox="0 0 1412 1169" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>nav-step2</title>
            {/* <desc>Created with Sketch.</desc> */}
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="nav-step2">
                    <g id="flower+color_down" transform="translate(454.000000, 402.000000)" >
                        <g transform="translate(0.889297, 0.454958)" id="Group">
                            <g transform="translate(195.652108, 0.000000)" stroke="#090909" strokeWidth="4">
                                <path d="M114.679893,118.865056 C119.172905,116.777924 126.444208,116.771741 130.950531,118.865056 L241.644029,170.285351 C246.137042,172.372484 246.150352,175.750212 241.644029,177.843527 L130.950531,229.263822 C126.457518,231.350955 119.186216,231.357138 114.679893,229.263822 L3.98639455,177.843527 C-0.50661799,175.756395 -0.519928069,172.378667 3.98639455,170.285351 L114.679893,118.865056 L114.679893,118.865056 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M258.632884,178.17716 L369.326383,229.597455 C373.819395,231.684588 373.832705,235.062315 369.326383,237.155631 L258.632884,288.575926 C254.139872,290.663059 246.868569,290.669241 242.362247,288.575926 L131.668748,237.155631 C127.175736,235.068498 127.162426,231.69077 131.668748,229.597455 L242.362247,178.17716 C246.855259,176.090027 254.126562,176.083844 258.632884,178.17716 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M368.768546,236.8965 C373.261559,234.809367 380.532862,234.803185 385.039184,236.8965 L495.732683,288.316795 C500.225695,290.403928 500.239005,293.781656 495.732683,295.874971 L385.039184,347.295266 C380.546172,349.382399 373.274869,349.388582 368.768546,347.295266 L258.075048,295.874971 C253.582036,293.787838 253.568725,290.410111 258.075048,288.316795 L368.768546,236.8965 L368.768546,236.8965 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M242.201865,59.8120837 C246.694878,57.724951 253.96618,57.7187681 258.472503,59.8120837 L369.166001,111.232378 C373.659014,113.319511 373.672324,116.697239 369.166001,118.790555 L258.472503,170.210849 C253.97949,172.297982 246.708188,172.304165 242.201865,170.210849 L131.508367,118.790555 C127.015354,116.703422 127.002044,113.325694 131.508367,111.232378 L242.201865,59.8120837 L242.201865,59.8120837 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M370.0446,119.198689 C374.537613,117.111556 381.808915,117.105373 386.315238,119.198689 L497.008736,170.618984 C501.501749,172.706117 501.515059,176.083844 497.008736,178.17716 L386.315238,229.597455 C381.822225,231.684588 374.550923,231.69077 370.0446,229.597455 L259.351102,178.17716 C254.858089,176.090027 254.844779,172.712299 259.351102,170.618984 L370.0446,119.198689 L370.0446,119.198689 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M512.721538,177.918029 L623.415036,229.338324 C627.908049,231.425457 627.921359,234.803185 623.415036,236.8965 L512.721538,288.316795 C508.228525,290.403928 500.957223,290.410111 496.4509,288.316795 L385.757402,236.8965 C381.264389,234.809367 381.251079,231.43164 385.757402,229.338324 L496.4509,177.918029 C500.943913,175.830896 508.215215,175.824714 512.721538,177.918029 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M370.368492,1.56766903 C374.861504,-0.519463744 382.132807,-0.525646656 386.639129,1.56766903 L497.332628,52.9879637 C501.82564,55.0750964 501.83895,58.4528244 497.332628,60.54614 L386.639129,111.966435 C382.146117,114.053567 374.874814,114.05975 370.368492,111.966435 L259.674993,60.54614 C255.181981,58.4590073 255.168671,55.0812794 259.674993,52.9879637 L370.368492,1.56766903 L370.368492,1.56766903 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M496.15456,59.4320292 C500.647572,57.3448965 507.918875,57.3387136 512.425198,59.4320292 L623.118696,110.852324 C627.611709,112.939457 627.625019,116.317185 623.118696,118.4105 L512.425198,169.830795 C507.932185,171.917928 500.660883,171.924111 496.15456,169.830795 L385.461062,118.4105 C380.968049,116.323367 380.954739,112.94564 385.461062,110.852324 L496.15456,59.4320292 L496.15456,59.4320292 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M622.56086,118.151369 C627.053872,116.064237 634.325175,116.058054 638.831497,118.151369 L749.524996,169.571664 C754.018008,171.658797 754.031318,175.036525 749.524996,177.12984 L638.831497,228.550135 C634.338485,230.637268 627.067182,230.643451 622.56086,228.550135 L511.867361,177.12984 C507.374349,175.042708 507.361039,171.66498 511.867361,169.571664 L622.56086,118.151369 L622.56086,118.151369 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                            <g transform="translate(378.658283, 473.410522) rotate(-302.000000) translate(-378.658283, -473.410522) translate(31.658283, 243.910522)" stroke="#090909" strokeWidth="4">
                                <path d="M105.556184,156.051819 C109.691741,153.31173 116.384553,153.303612 120.532361,156.051819 L222.4193,223.558879 C226.554857,226.298968 226.567108,230.733414 222.4193,233.48162 L120.532361,300.98868 C116.396804,303.728769 109.703992,303.736887 105.556184,300.98868 L3.66924474,233.48162 C-0.466312448,230.741531 -0.478563602,226.307086 3.66924474,223.558879 L105.556184,156.051819 L105.556184,156.051819 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M223.080377,233.919629 C227.215935,231.17954 233.908746,231.171423 238.056555,233.919629 L339.943494,301.426689 C344.079051,304.166779 344.091302,308.601224 339.943494,311.34943 L238.056555,378.85649 C233.920998,381.59658 227.228186,381.604697 223.080377,378.85649 L121.193439,311.34943 C117.057881,308.609341 117.04563,304.174896 121.193439,301.426689 L223.080377,233.919629 L223.080377,233.919629 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M339.430038,311.009231 C343.565595,308.269141 350.258407,308.261024 354.406215,311.009231 L456.293154,378.516291 C460.428711,381.25638 460.440962,385.690825 456.293154,388.439032 L354.406215,455.946092 C350.270658,458.686181 343.577846,458.694298 339.430038,455.946092 L237.543099,388.439032 C233.407542,385.698942 233.395291,381.264497 237.543099,378.516291 L339.430038,311.009231 L339.430038,311.009231 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M222.932756,78.5242084 C227.068313,75.7841191 233.761125,75.7760019 237.908933,78.5242084 L339.795872,146.031268 C343.931429,148.771358 343.94368,153.205803 339.795872,155.954009 L237.908933,223.46107 C233.773376,226.201159 227.080564,226.209276 222.932756,223.46107 L121.045817,155.954009 C116.91026,153.21392 116.898008,148.779475 121.045817,146.031268 L222.932756,78.5242084 L222.932756,78.5242084 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M340.604571,156.489828 C344.740128,153.749739 351.43294,153.741622 355.580749,156.489828 L457.467687,223.996888 C461.603245,226.736977 461.615496,231.171423 457.467687,233.919629 L355.580749,301.426689 C351.445191,304.166779 344.752379,304.174896 340.604571,301.426689 L238.717632,233.919629 C234.582075,231.17954 234.569824,226.745095 238.717632,223.996888 L340.604571,156.489828 L340.604571,156.489828 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M456.954231,233.57943 C461.089789,230.83934 467.7826,230.831223 471.930409,233.57943 L573.817348,301.08649 C577.952905,303.826579 577.965156,308.261024 573.817348,311.009231 L471.930409,378.516291 C467.794852,381.25638 461.10204,381.264497 456.954231,378.516291 L355.067293,311.009231 C350.931735,308.269141 350.919484,303.834696 355.067293,301.08649 L456.954231,233.57943 L456.954231,233.57943 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M340.902694,2.05811204 C345.038252,-0.681977232 351.731063,-0.690094459 355.878872,2.05811204 L457.76581,69.5651721 C461.901368,72.3052614 461.913619,76.7397066 457.76581,79.4879131 L355.878872,146.994973 C351.743315,149.735062 345.050503,149.74318 340.902694,146.994973 L239.015756,79.4879131 C234.880198,76.7478239 234.867947,72.3133786 239.015756,69.5651721 L340.902694,2.05811204 L340.902694,2.05811204 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M456.681467,78.0252544 C460.817025,75.2851651 467.509836,75.2770479 471.657645,78.0252544 L573.544584,145.532314 C577.680141,148.272404 577.692392,152.706849 573.544584,155.455055 L471.657645,222.962116 C467.522088,225.702205 460.829276,225.710322 456.681467,222.962116 L354.794529,155.455055 C350.658971,152.714966 350.64672,148.280521 354.794529,145.532314 L456.681467,78.0252544 L456.681467,78.0252544 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M573.031128,155.114856 C577.166685,152.374767 583.859497,152.366649 588.007305,155.114856 L689.894244,222.621916 C694.029801,225.362005 694.042052,229.79645 689.894244,232.544657 L588.007305,300.051717 C583.871748,302.791806 577.178936,302.799924 573.031128,300.051717 L471.144189,232.544657 C467.008632,229.804568 466.996381,225.370122 471.144189,222.621916 L573.031128,155.114856 L573.031128,155.114856 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                            <g transform="translate(763.047754, 470.485332) scale(-1, 1) rotate(-303.000000) translate(-763.047754, -470.485332) translate(409.547754, 248.485332)">
                                <path d="M107.667307,150.95393 C111.885576,148.303353 118.712244,148.295501 122.943008,150.95393 L226.867686,216.255674 C231.085954,218.90625 231.09845,223.195831 226.867686,225.85426 L122.943008,291.156004 C118.72474,293.80658 111.898072,293.814432 107.667307,291.156004 L3.74262964,225.85426 C-0.475638697,223.203683 -0.488134874,218.914102 3.74262964,216.255674 L107.667307,150.95393 L107.667307,150.95393 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M227.541985,226.27796 C231.760253,223.627384 238.586921,223.619532 242.817686,226.27796 L346.742363,291.579704 C350.960632,294.230281 350.973128,298.519862 346.742363,301.17829 L242.817686,366.480034 C238.599417,369.130611 231.772749,369.138463 227.541985,366.480034 L123.617307,301.17829 C119.399039,298.527714 119.386543,294.238133 123.617307,291.579704 L227.541985,226.27796 L227.541985,226.27796 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M346.218638,300.849204 C350.436907,298.198628 357.263575,298.190776 361.494339,300.849204 L465.419017,366.150948 C469.637285,368.801525 469.649781,373.091106 465.419017,375.749534 L361.494339,441.051279 C357.276071,443.701855 350.449403,443.709707 346.218638,441.051279 L242.293961,375.749534 C238.075692,373.098958 238.063196,368.809377 242.293961,366.150948 L346.218638,300.849204 L346.218638,300.849204 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M227.391411,75.9589853 C231.609679,73.3084091 238.436347,73.300557 242.667112,75.9589853 L346.591789,141.26073 C350.810058,143.911306 350.822554,148.200887 346.591789,150.859315 L242.667112,216.16106 C238.448843,218.811636 231.622175,218.819488 227.391411,216.16106 L123.466733,150.859315 C119.248465,148.208739 119.235969,143.919158 123.466733,141.26073 L227.391411,75.9589853 L227.391411,75.9589853 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M347.416663,151.37763 C351.634931,148.727054 358.461599,148.719202 362.692363,151.37763 L466.617041,216.679374 C470.835309,219.329951 470.847806,223.619532 466.617041,226.27796 L362.692363,291.579704 C358.474095,294.230281 351.647427,294.238133 347.416663,291.579704 L243.491985,226.27796 C239.273717,223.627384 239.26122,219.337803 243.491985,216.679374 L347.416663,151.37763 L347.416663,151.37763 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M466.093316,225.948874 C470.311584,223.298298 477.138252,223.290446 481.369017,225.948874 L585.293695,291.250618 C589.511963,293.901195 589.524459,298.190776 585.293695,300.849204 L481.369017,366.150948 C477.150749,368.801525 470.324081,368.809377 466.093316,366.150948 L362.168638,300.849204 C357.95037,298.198628 357.937874,293.909047 362.168638,291.250618 L466.093316,225.948874 L466.093316,225.948874 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M347.720748,1.99087779 C351.939017,-0.65969845 358.765685,-0.667550504 362.996449,1.99087779 L466.921127,67.2926222 C471.139395,69.9431984 471.151891,74.2327796 466.921127,76.8912079 L362.996449,142.192952 C358.778181,144.843529 351.951513,144.851381 347.720748,142.192952 L243.796071,76.8912079 C239.577802,74.2406316 239.565306,69.9510505 243.796071,67.2926222 L347.720748,1.99087779 L347.720748,1.99087779 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M465.815097,75.4763311 C470.033365,72.8257549 476.860033,72.8179028 481.090798,75.4763311 L585.015475,140.778076 C589.233744,143.428652 589.24624,147.718233 585.015475,150.376661 L481.090798,215.678406 C476.872529,218.328982 470.045861,218.336834 465.815097,215.678406 L361.890419,150.376661 C357.672151,147.726085 357.659655,143.436504 361.890419,140.778076 L465.815097,75.4763311 L465.815097,75.4763311 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M584.49175,150.047575 C588.710019,147.396999 595.536687,147.389147 599.767451,150.047575 L703.692129,215.34932 C707.910397,217.999896 707.922893,222.289477 703.692129,224.947905 L599.767451,290.24965 C595.549183,292.900226 588.722515,292.908078 584.49175,290.24965 L480.567073,224.947905 C476.348804,222.297329 476.336308,218.007748 480.567073,215.34932 L584.49175,150.047575 L584.49175,150.047575 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            </g>
                        </g>
                    </g>
                    <g id="purple_robot_legs">
                        <path d="M385.300444,265.384354 C365.903704,259.480366 348.089481,270.090971 338.169185,285.936446 C335.041481,285.973734 332.065185,287.769789 331.050963,291.912939 C323.009778,324.703894 319.975407,358.472632 322.368889,392.154364 C322.924741,399.970415 329.281778,404.861403 337.001481,403.289078 C343.733926,401.919767 350.854222,405.810184 357.402074,407.026198 C363.315259,408.124133 369.585185,408.372722 375.446519,406.785896 C390.122667,402.814687 394.631704,390.337593 394.718815,376.426969 C394.926222,343.633943 394.872296,310.834701 394.946963,278.039603 C394.961481,272.145973 390.836148,267.070616 385.300444,265.384354" id="Fill-1" fill="#1B1A19"></path>
                        <path d="M109.724444,701.900348 C104.04563,697.320096 97.4791111,694.169231 90.082963,694.353601 C84.4663704,694.494468 84.1158519,701.645544 88.8343704,703.570037 C93.6234074,705.523532 98.3398519,707.820908 101.992296,711.558029 C105.615704,715.264076 106.945185,721.648669 112.306667,723.059411 C114.577778,723.656025 117.413037,722.688599 118.543407,720.523804 C122.185481,713.54674 114.890963,706.068356 109.724444,701.900348" id="Fill-2" fill="#582C79"></path>
                        <path d="M100.532148,748.608141 C94.7413333,736.986607 89.8423704,725.78146 88.5004444,712.724325 C87.9881481,707.740117 80.8284444,707.551603 80.5899259,712.724325 C79.92,727.181845 82.2616296,742.287767 90,754.756575 C94.1668148,761.470548 104.012444,755.595562 100.532148,748.608141" id="Fill-3" fill="#582C79"></path>
                        <path d="M71.1653333,717.679532 C67.6539259,724.149059 65.5445926,730.43836 64.6506667,737.761376 C63.8334815,744.438061 62.8939259,753.055812 66.7454815,758.893509 C69.0684444,762.413115 75.1807407,762.234959 76.2364444,757.654708 C77.5534815,751.926804 75.7096296,746.107751 75.8485926,740.234836 C76,733.769452 77.1531852,727.12384 78.6962963,720.851112 C79.7831111,716.436587 73.4571852,713.459734 71.1653333,717.679532" id="Fill-4" fill="#582C79"></path>
                        <path d="M61.3591111,715.76954 C47.6225185,704.982851 37.8100741,743.887022 43.7917037,752.57935 C45.7579259,755.436051 50.1798519,755.887654 52.0465185,752.57935 C54.9875556,747.371411 53.8654815,740.839736 55.2654815,735.093188 C56.4663704,730.164912 59.3327407,725.990689 61.0293333,721.385579 C62.6885926,719.929262 63.4435556,717.408155 61.3591111,715.76954" id="Fill-5" fill="#582C79"></path>
                        <path d="M64.6154074,680.53827 C55.8462222,683.324538 50.3291852,691.994078 50.5677037,701.046859 C50.5946667,702.051573 50.714963,703.027284 50.8725926,703.988495 C51.0675556,708.769689 52.7537778,713.426589 56.0888889,717.0332 C65.7997037,727.529869 83.5247407,722.947546 89.8859259,711.087781 C98.5555556,694.927427 82.2574815,674.934661 64.6154074,680.53827" id="Fill-11" fill="#1B1A19"></path>
                        <path d="M68.690963,555.481452 C61.0542222,557.488808 54.8216296,563.715961 52.9528889,571.360072 C50.7211852,580.477072 55.5102222,590.385413 63.7028148,594.806153 C65.3102222,595.672072 67.0317037,596.281115 68.7946667,596.691286 C71.4805926,598.491485 74.6041481,599.562489 77.9454815,599.465125 C86.7042963,599.216536 94.1564444,593.068102 96.4234074,584.665796 C100.955259,567.875683 86.1007407,550.905344 68.690963,555.481452" id="Fill-13" fill="#1B1A19"></path>
                        <path d="M520.868148,462.515397 C497.534815,454.958292 474.164148,476.664252 480.548148,500.555722 C482.624296,508.322055 487.936,514.617571 494.747259,518.302902 C496.298667,519.759219 497.96,521.12853 499.68563,522.417049 C510.582815,530.550051 523.682667,530.052873 533.648593,520.614779 C552.396148,502.859313 545.454222,470.480601 520.868148,462.515397" id="Fill-14" fill="#1B1A19"></path>
                        <path d="M239.289778,451.900648 C229.45037,419.227773 185.079704,422.202554 171.768296,450.516836 C165.19763,464.489607 168.101333,482.874832 182.064,491.167346 C188.134815,494.771885 206.191704,498.703734 211.254519,490.87111 C214.336593,490.684669 217.435259,490.065268 220.475852,488.938331 C235.434074,483.386511 243.937778,467.329736 239.289778,451.900648" id="Fill-15" fill="#1B1A19"></path>
                        <path d="M516.269926,905.830299 C511.773333,905.602425 507.465481,906.524276 503.543407,908.276828 C499.720889,908.856869 495.881778,910.166104 492.185778,912.312256 C474.095704,922.810996 471.613037,946.344084 485.231407,961.412718 C491.988741,971.078685 503.192889,977.519211 515.27437,975.265337 C519.370667,974.500926 522.94637,972.816736 525.997333,970.48 C532.624,967.662658 538.51437,962.79653 542.639704,955.974834 C555.198222,935.20937 540.781333,907.0691 516.269926,905.830299" id="Fill-16" fill="#1B1A19"></path>
                        <path d="M240.62963,928.82892 C238.057778,912.339186 224.449778,899.874521 207.531556,899.683937 C200.116741,899.601074 192.930074,901.823873 187.097778,905.817869 C183.617481,907.218254 180.367407,909.27947 177.646222,911.945587 C165.994074,923.366178 164.986074,941.995849 173.525037,955.537732 C183.179852,970.850812 203.070222,975.38756 219.243852,968.398067 C234.679111,961.725526 243.203556,945.318654 240.62963,928.82892" id="Fill-17" fill="#1B1A19"></path>
                        <path d="M414.727407,697.724053 C415.088296,695.778845 416.538074,694.245879 417.923556,692.833065 C426.174222,684.418329 434.424889,676.003593 442.675556,667.588857 C461.844148,686.558266 570.486222,773.620333 504.854222,794.787683 C471.65037,805.495652 409.832593,724.61102 414.727407,697.724053" id="Fill-18" fill="#1B1A19"></path>
                        <path d="M232.988741,666.963241 C234.88237,666.385272 236.912889,666.963241 238.808593,667.537067 C250.091556,670.959309 261.372444,674.38155 272.655407,677.803791 C264.664,703.547249 237.524741,840.001871 188.418963,791.634746 C163.575704,767.165307 206.851259,675.046526 232.988741,666.963241" id="Fill-19" fill="#1B1A19"></path>
                        <path d="M574.930963,408.23807 C493.834667,409.157849 433.45837,341.96011 355.375704,334.452724 C277.695407,326.982626 203.966222,374.042587 130.490074,390.461888 C103.761481,396.432166 74.2017778,394.868127 51.0945185,380.226237 C27.9105185,365.536701 14.0411852,339.610945 6.92711111,313.194225 C-0.763555556,284.641712 -1.6637037,254.301429 4.32207407,225.347031 C14.96,173.874616 47.0397037,128.314474 87.8885926,95.0076973 C128.739556,61.7050634 177.963556,39.8520225 228.506667,24.6218057 C340.591704,-9.15314724 465.193778,-10.824908 572.247111,36.4277096 C681.85363,84.8072638 769.896,210.181033 703.99437,328.934049 C678.379556,375.088732 627.915259,407.637313 574.930963,408.23807" id="Fill-20" fill="#91659A"></path>
                        <path d="M301.103407,371.608487 C260.358222,386.101223 232.482667,418.007616 213.455111,451.11345 C191.420148,489.452082 178.411556,531.91729 181.418963,574.283063 C184.424296,616.650908 204.314667,658.838526 240.837037,688.683701 C254.333037,699.714836 270.104296,709.055566 287.839704,714.65089 C316.762667,723.774104 348.865185,722.446225 379.600889,719.388581 C402.687407,717.093276 426.244741,713.724896 446.301037,704.002996 C487.181037,684.186313 504.485037,642.86047 511.904,604.186243 C519.941037,562.292789 520.005333,518.679928 504.582519,478.222076 C489.161778,437.764223 457.084148,400.666464 411.854815,380.592906 L301.103407,371.608487" id="Fill-21" fill="#91659A"></path>
                        <path d="M595.190519,379.109658 C543.728593,391.963779 495.257481,367.483982 445.956741,343.111906 C406.750519,323.724039 367.015407,304.425249 324.837037,304.021292 C308.555556,303.861781 292.82163,306.532041 277.36563,310.834701 C235.539852,322.493524 195.858667,346.165407 153.678222,358.791654 C140.176,362.831225 125.900148,365.74593 112.045333,363.129532 C97.2945185,360.355693 84.3004444,351.487282 73.7517037,340.810387 C32.7700741,299.385108 27.3712593,230.652333 52.9176296,178.28707 C84.4601481,113.647726 159.885926,70.2026626 248.162667,49.8846585 C252.785778,48.8136544 257.462815,47.8130838 262.156444,46.8643027 C336.563852,32.0090409 418.722074,33.1670511 490.825185,51.4652699 C496.557926,52.9050143 502.236741,54.4690532 507.82637,56.1553149 C602.750519,84.4778834 676.805333,144.114374 686.335704,237.730904 C692.522667,298.541978 654.54637,364.289613 595.190519,379.109658" id="Fill-22" fill="#522F65"></path>
                        <path d="M408.005333,212.231892 C420.711111,213.951299 430.289185,215.751497 433.557926,216.810072 C429.87437,242.59289 426.188741,268.375708 422.503111,294.158526 C421.885037,298.498474 312.089778,287.763575 302.831111,288.627421 C296.826667,269.544076 290.820148,250.462802 284.815704,231.379456 C284.232889,229.525397 283.666667,227.375102 284.519111,225.668125 C285.280296,224.147589 286.887704,223.567548 288.347852,223.113873 C330.930667,209.859939 363.879407,206.259542 408.005333,212.231892" id="Fill-23" fill="#1B1A19"></path>
                        <path d="M554.034667,162.018994 C522.228741,171.082133 497.570074,198.739726 487.77837,230.296022 C476.696593,266.003755 485.895111,310.041288 517.143111,330.622382 C532.319111,340.61773 550.844741,344.213984 568.980444,345.438284 C577.660444,346.02454 586.616296,346.078401 594.763259,343.029043 C603.963852,339.581943 611.281185,332.507515 617.976296,325.325366 C648.010963,293.102022 672.182222,250.015342 651.630222,206.222254 C634.176889,169.029202 592.073185,151.182587 554.034667,162.018994" id="Fill-24" fill="#1B1A19"></path>
                        <path d="M140.005926,140.946937 C108.2,150.010076 83.5434074,177.667669 73.7496296,209.223965 C62.6678519,244.933769 71.8663704,288.969231 103.11437,309.550325 C118.29037,319.545673 136.816,323.141926 154.951704,324.366227 C163.633778,324.952483 172.587556,325.006344 180.734519,321.956986 C189.935111,318.511957 197.25037,311.43753 203.947556,304.253309 C233.982222,272.032037 258.153481,228.943284 237.603556,185.150196 C220.148148,147.957145 178.046519,130.11053 140.005926,140.946937" id="Fill-25" fill="#1B1A19"></path>
                        <path d="M526.824889,270.426566 C522.035852,272.947673 518.439407,272.922814 516.589333,268.071186 C493.932148,208.364262 568.445333,150.706125 618.200296,194.936315 C634.058667,209.031309 640.191704,233.741051 630.908148,252.807824 C627.069037,260.692237 618.235556,268.0629 610.225481,264.472861 C604.416,261.868892 602.254815,254.927045 598.502815,249.781254 C576.509333,219.606697 544.000296,261.386215 526.824889,270.426566" id="Fill-26" fill="#515251"></path>
                        <path d="M110.228444,254.373935 C105.439407,256.895041 101.842963,256.870182 99.9928889,252.016483 C77.3357037,192.309558 151.850963,134.653493 201.605926,178.881611 C217.464296,192.978677 223.597333,217.686348 214.313778,236.755192 C210.472593,244.639605 201.639111,252.010268 193.631111,248.420229 C187.82163,245.814188 185.65837,238.874413 181.908444,233.728622 C159.912889,203.554065 127.403852,245.333583 110.228444,254.373935" id="Fill-27" fill="#515251"></path>
                        <path d="M620.94637,209.957303 C618.932444,208.11153 616.665481,206.972164 613.944296,206.607566 C607.566519,205.749935 602.789926,209.592706 600.560296,215.333039 C599.27437,218.641344 599.821926,223.318959 600.985481,226.598262 C601.474963,227.982074 602.099259,229.287166 602.947556,230.461748 C603.101037,230.615045 603.293926,230.826346 603.449481,231.041789 C603.605037,231.257233 603.770963,231.44989 603.932741,231.657047 C603.936889,231.66119 603.938963,231.665333 603.943111,231.669476 C603.943111,231.667405 603.941037,231.667405 603.938963,231.665333 C605.940444,234.215442 608.304889,235.901703 611.353778,236.336734 C614.151704,237.822053 618.353778,237.032783 620.44237,234.762337 C621.902519,233.17344 622.622222,231.28002 622.667852,229.328597 C623.084741,227.971716 623.229926,226.552687 623.296296,225.054939 C623.346074,224.779419 623.408296,224.508043 623.447704,224.224237 C623.512,223.921787 623.586667,223.621409 623.682074,223.321031 C624.17363,221.773564 624.304296,220.186738 624.52,218.57091 C624.887111,215.832288 622.914667,211.761644 620.94637,209.957303" id="Fill-28" fill="#FFFFFF"></path>
                        <path d="M602.947556,230.461748 C602.568,230.088865 602.456,230.113724 603.938963,231.665333 L603.932741,231.657047 C603.571852,231.277949 603.246222,230.878135 602.947556,230.461748" id="Fill-29" fill="#FFFFFF"></path>
                        <path d="M204.81037,206.785722 C204.327111,205.029027 203.288,203.305476 202.439704,201.673076 C201.006519,198.911667 199.577481,196.415419 197.337481,194.165689 C192.710222,189.519147 185.208296,189.198053 180.099852,193.14026 C174.852444,197.190188 173.585185,204.23147 176.719111,210.054667 C178.202074,212.807789 180.321778,214.875221 182.524444,217.019301 C183.851852,218.314035 185.141926,219.697847 186.761778,220.644556 C188.323556,221.547763 189.989037,222.067728 191.749926,222.239669 C197.773037,224.462468 205.160889,218.614413 204.994963,212.397618 C205.357926,210.526986 205.31437,208.656354 204.81037,206.785722" id="Fill-30" fill="#FFFFFF"></path>
                        <path d="M507.82637,56.1553149 C497.964148,70.9753599 489.10163,86.4293067 481.54163,102.531656 C476.093037,114.122117 471.046815,125.903162 466.738963,137.968012 C463.381037,147.416464 462.167704,161.271155 455.205037,168.875906 C447.433481,177.356933 431.751407,175.583665 421.395556,176.180278 C406.943407,177.039982 392.509926,177.058626 378.039111,176.795536 C348.537481,176.250712 319.104296,174.179137 289.814222,170.752753 C286.526815,170.386084 282.569481,168.577599 281.988741,164.801119 C275.660741,124.763796 263.212148,87.0424928 248.162667,49.8846585 C252.785778,48.8136544 257.462815,47.8130838 262.156444,46.8643027 C279.423111,80.6848303 290.289185,118.089182 296.74163,155.284305 C322.024593,157.917276 347.411259,159.568321 372.835259,160.059284 C386.706667,160.341018 400.578074,160.077928 414.468148,160.077928 C421.376889,160.094501 428.339556,160.164935 435.266963,159.8832 C442.704593,159.584894 444.656296,158.09336 446.643259,151.12044 C456.806222,115.367133 470.92237,82.108002 490.825185,51.4652699 C496.557926,52.9050143 502.236741,54.4690532 507.82637,56.1553149" id="Fill-31" fill="#1B1A19"></path>
                        <path d="M149.675259,817.541859 C156.040593,853.378029 171.834667,891.059971 179.770074,907.866656 C183.934815,916.689493 188.32563,925.806493 195.833778,932.044004 C203.341926,938.281515 214.919407,940.771548 222.894222,935.14308 C263.672593,906.352335 235.550222,714.45409 173.108148,739.352346 C146.168,750.097603 143.531852,782.961063 149.675259,817.541859" id="Fill-32" fill="#522F65"></path>
                        <path d="M570.324444,805.885108 C563.959111,841.72335 548.162963,879.405292 540.227556,896.211978 C536.062815,905.034814 531.674074,914.151814 524.165926,920.389325 C516.655704,926.626836 505.080296,929.116869 497.105481,923.486329 C456.327111,894.697656 484.449481,702.799411 546.891556,727.697667 C578.987852,740.497926 571.311704,800.331217 570.324444,805.885108" id="Fill-33" fill="#522F65"></path>
                        <path d="M236.848593,982.041458 C261.123556,931.679407 155.524148,949.153139 138.693037,957.045838 C123.432,964.201057 109.801185,982.024885 125.620148,995.595771 C137.797037,1006.04272 180.023111,1010.00564 195.064296,1009.96628 C210.877037,1009.92485 229.98963,996.271104 236.848593,982.041458" id="Fill-34" fill="#522F65"></path>
                        <path d="M601.064296,984.139963 C603.926519,977.626933 603.184,969.560221 597.289481,959.743029 C582.953481,935.859845 518.414519,947.526953 498.113481,957.045838 C482.852444,964.201057 469.223704,982.024885 485.042667,995.595771 C485.067556,995.618558 485.092444,995.639274 485.119407,995.662061 C503.665778,1011.29209 588.288,1013.22073 601.064296,984.139963" id="Fill-35" fill="#522F65"></path>
                        <path d="M541.96563,806.069479 C537.672296,800.235924 528.679111,798.251356 522.604148,794.282219 C515.784593,789.826262 511.232,782.768407 504.472593,778.474033 C501.388444,776.512252 498.219259,779.739765 498.783407,782.842984 C500.305778,791.234933 508.653926,798.562092 515.04,803.579446 C521.005037,808.267419 531.120296,815.694014 539.059852,813.20191 C541.776889,812.350493 543.913185,808.714879 541.96563,806.069479" id="Fill-36" fill="#1B1A19"></path>
                        <path d="M519.843556,841.242744 C517.779852,835.572845 511.149037,833.267182 506.652444,829.697859 C501.861333,825.896519 497.35437,821.297624 493.380444,816.653153 C490.96,813.823382 485.772741,816.829237 487.044148,820.336413 C489.528889,827.18711 493.069333,832.910871 498.356148,837.986229 C502.771852,842.222599 510.159704,848.84128 516.736593,846.70963 C518.883259,846.013581 520.696,843.585695 519.843556,841.242744" id="Fill-37" fill="#1B1A19"></path>
                        <path d="M206.898963,769.470969 C202.188741,773.740485 199.07763,779.362738 194.802963,784.063141 C191.086222,788.152429 184.31437,792.208573 182.941333,797.692031 C182.252741,800.438939 184.218963,802.531229 186.83437,802.798462 C193.49837,803.482082 200.118815,795.52102 203.787852,790.833047 C208.197333,785.200436 212.06963,778.848988 211.822815,771.507327 C211.735704,768.955147 208.759407,767.784708 206.898963,769.470969" id="Fill-38" fill="#1B1A19"></path>
                        <path d="M223.584889,800.296 C218.920296,806.425789 212.864,812.012826 207.371852,817.432065 C202.427259,822.312695 194.313481,825.913092 192.338963,832.921229 C191.581926,835.60599 193.268148,839.309965 196.252741,839.807143 C205.333037,841.313178 212.708444,833.149102 218.163259,826.799726 C224.391704,819.553358 227.235259,811.865744 229.157926,802.643094 C229.896296,799.108988 225.64237,797.594667 223.584889,800.296" id="Fill-39" fill="#1B1A19"></path>
                        <path d="M437.48,449.261462 C431.556444,445.149387 424.859259,445.327542 417.921481,444.40362 C411.919111,443.60192 405.94163,442.532988 399.897778,442.06274 C386.67763,441.035239 373.31437,440.894372 360.063111,440.31226 C347.376,439.755006 334.659852,439.44427 321.960296,439.270258 C308.939259,439.094174 294.704889,438.130892 282.532148,443.222822 C274.227556,446.696853 270.071111,459.161517 279.784,464.224446 C291.786667,470.482673 305.178963,470.969493 318.496593,472.036354 C331.733333,473.099072 345.063407,473.76819 358.333333,474.273654 C371.030815,474.756331 383.732444,475.342587 396.434074,475.692683 C403.400889,475.883268 410.268148,475.286654 417.21837,474.924129 C424.716148,474.53053 430.799407,475.046352 437.48,471.153863 C446.201481,466.068147 445.120889,454.568836 437.48,449.261462" id="Fill-40" fill="#1B1A19"></path>
                        <path d="M391.192889,507.288339 C381.554667,506.161403 371.182222,507.849736 361.417481,507.800018 C352.084148,507.754444 342.71763,507.418849 333.488,508.980816 C327.458667,510.002102 328.931259,518.025311 333.488,519.775791 C342.77363,523.3389 353.197926,524.020448 363.072593,524.16753 C372.84563,524.314611 383.769778,525.006517 393.231704,522.323828 C402.083852,519.815151 399.383407,508.243335 391.192889,507.288339" id="Fill-41" fill="#1B1A19"></path>
                        <path d="M379.269037,573.80453 C373.716741,571.658378 366.569481,572.801888 360.656296,572.51601 C355.512593,572.269493 348.713778,570.72617 344.304296,573.951611 C342.786074,575.061975 341.309333,577.235057 342.145185,579.250699 C344.806222,585.649793 352.032296,587.694438 358.416296,589.055462 C365.885037,590.646431 374.148148,591.945309 381.197926,588.549998 C388.096296,585.225121 385.107556,576.062546 379.269037,573.80453" id="Fill-42" fill="#1B1A19"></path>
                        <path d="M179.247407,509.500781 C174.62637,520.660354 165.583407,529.305035 156.720889,537.5209 C136.347259,556.405374 115.60237,574.892106 94.4986667,592.962452 C85.2234074,586.416276 76.5019259,579.091188 68.4545185,571.086624 C66.3659259,569.012978 64.2068148,566.638953 63.9807407,563.707675 C63.8044444,561.416513 64.856,559.231002 65.8971852,557.182215 C85.6195556,518.410624 112.24237,480.167284 144.842667,451.022301 C154.10963,442.738074 172.21837,430.3542 182.785778,444.17989 C191.959407,456.182593 184.613037,496.545153 179.247407,509.500781" id="Fill-44" fill="#522F65"></path>
                        <g id="Group-48" transform="translate(29.037037, 575.969325)" fill="#522F65">
                            <path d="M46.1540741,126.985454 C44.6980741,129.36155 42.6717037,131.967591 39.8820741,131.899229 C38.3182222,131.861941 36.9202963,130.952519 35.6717037,130.009953 C39.3013333,132.748575 -43.3173333,20.3180041 34.0788148,1.17872597 C85.3354074,-11.4972393 58.6358519,106.611517 46.1540741,126.985454" id="Fill-47"></path>
                        </g>
                        <g id="Group" transform="translate(609.679599, 515.761305) rotate(-24.000000) translate(-609.679599, -515.761305) translate(543.179599, 437.261305)">
                            <path d="M131.711111,131.963022 C131.366815,130.019885 130.792296,128.149254 129.749037,126.3822 C128.326222,123.977102 126.523852,122.288769 124.377185,120.631509 C119.62963,116.966894 113.175111,115.442215 107.303407,117.157479 C104.920296,117.853528 102.798519,119.007395 100.989926,120.484427 C97.5303704,122.719656 95.2965926,126.324196 93.9214815,130.129679 C91.4201481,137.05081 93.3739259,145.879861 98.946963,150.793636 C100.738963,152.374247 102.406519,153.418321 104.563556,154.327742 C105.142222,154.588761 105.727111,154.831135 106.320296,155.059008 C106.851259,155.268237 106.880296,155.268237 106.438519,155.077652 C107.052444,155.342814 107.66637,155.597618 108.309333,155.788202 C111.746074,156.81156 116.060148,156.74527 119.453333,155.628691 C129.290667,152.384605 134.511111,141.825789 131.711111,131.963022" id="Fill-12" fill="#1B1A19"></path>
                            <path d="M8.61274074,71.8252106 C13.2337778,82.9847832 22.2746667,91.6294642 31.1371852,99.8453292 C51.5108148,118.731875 72.2577778,137.218607 93.3594074,155.288953 C102.634667,148.742777 111.358222,141.415618 119.40563,133.413125 C121.492148,131.337407 123.651259,128.965454 123.877333,126.032104 C124.055704,123.743014 123.004148,121.555431 121.962963,119.508716 C102.240593,80.7350532 75.6177778,42.4917137 43.0154074,13.3467301 C33.7505185,5.06457464 15.6417778,-7.31929857 5.07437037,6.50431902 C-4.10133333,18.5070225 3.24711111,58.8695828 8.61274074,71.8252106" id="Fill-43" fill="#522F65"></path>
                        </g>
                        <g id="Group" transform="translate(738.606354, 609.291523) rotate(-31.000000) translate(-738.606354, -609.291523) translate(695.606354, 526.291523)">
                            <path d="M76.378963,115.707454 C72.7472593,109.68953 64.6936296,113.932115 65.6767407,120.2152 C67.3235556,130.751229 69.8871111,140.877086 70.2168889,151.603699 C70.5134815,161.3401 85.842963,161.333885 85.3721481,151.603699 C84.7706667,139.207397 82.8874074,126.496215 76.378963,115.707454" id="Fill-7" fill="#582C79"></path>
                            <path d="M64.7943704,125.028969 C63.1226667,122.329708 58.5140741,121.847031 56.9668148,125.028969 C54.7247407,129.640294 54.3534815,134.790229 53.4097778,139.79101 C52.3602963,145.344902 50.5413333,150.598415 48.4755556,155.849857 C44.7712593,165.256877 60.3641481,169.725264 63.7842963,160.065511 C67.3351111,150.024589 70.8008889,134.723939 64.7943704,125.028969" id="Fill-8" fill="#582C79"></path>
                            <path d="M44.824,126.429924 C44.1001481,123.053258 39.7404444,123.140264 38.0438519,125.545362 C32.7300741,133.081751 28.5445926,140.280472 20.7232593,145.533986 C18.2675556,147.185031 16.4257778,150.437403 17.4897778,153.47226 C17.9274074,154.719348 18.4604444,155.56455 19.3004444,156.575479 C21.2936296,158.968147 25.2862222,159.1028 27.829037,157.673413 C38.6121481,151.609914 47.5285926,139.076888 44.824,126.429924" id="Fill-9" fill="#582C79"></path>
                            <path d="M62.5626667,82.6279796 C52.24,80.0757996 41.152,85.5509714 36.530963,94.9849223 C34.5191111,99.0907832 33.4882963,103.66482 33.786963,108.211926 C29.978963,126.189051 51.7235556,139.867658 66.7253333,129.464211 C74.7312593,123.912391 81.218963,114.308571 80.8435556,104.298722 C80.4494815,93.8082679 72.5700741,85.1014397 62.5626667,82.6279796" id="Fill-10" fill="#1B1A19"></path>
                            <path d="M3.50133333,122.971896 C3.9762963,124.861172 5.67703704,125.818239 7.47525926,125.994323 C13.544,126.584722 18.5072593,120.318209 24.7585185,119.895607 C27.9442963,119.680164 38.381037,122.773025 39.2355556,117.368286 C41.7161481,101.665751 -0.951703704,105.251646 3.50133333,122.971896" id="Fill-6" fill="#582C79"></path>
                            <path d="M55.9318519,125.277558 C57.3899259,124.761736 58.4228148,123.479431 59.3042963,122.211628 C78.672,94.3903804 68.2228148,-2.71882413 21.1558519,0.746920245 C-30.8764444,4.57933333 30.538963,107.288004 48.5315556,122.588654 C50.6284444,124.37228 53.333037,126.199409 55.9318519,125.277558 Z" id="Fill-45" fill="#522F65"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
